import React from 'react';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

import { PicklistButton } from '@common/components';

import PickerHeader from './PickerHeader';

type MultiSelectProps = {
  list: Array<string>;
  onCancelPress: () => void;
  onItemPress: (item: string, index: number) => void;
  onMultiSelectionDone: () => void;
  title?: string;
  value: Array<string>;
};

const MultiSelectWrap = styled.View`
  height: 100%;
`;

const ButtonListWrap = styled.View`
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px;
`;

const MultiSelect = ({ onCancelPress, onItemPress, onMultiSelectionDone, list, title, value = [] }: MultiSelectProps) => (
  <MultiSelectWrap>
    <PickerHeader onCancelPress={onCancelPress} onDonePress={onMultiSelectionDone} title={title} />
    <ScrollView>
      <ButtonListWrap>
        {list.map((item, index) => {
          const isSelected = value.includes(item);

          return (
            <PicklistButton key={item} isSelected={isSelected} onPress={() => onItemPress(item, index)}>
              {item}
            </PicklistButton>
          );
        })}
      </ButtonListWrap>
    </ScrollView>
  </MultiSelectWrap>
);

export default MultiSelect;
