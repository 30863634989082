import React, { Fragment } from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { isWeb, env } from '@common/utils';
import { TouchableWithEvent } from '@common/components';

import { WALKTHROUGH_LINK } from './HelpSection';

type FooterProps = {
  onUrlPress: (link: string) => void;
};

export const ABOUT_LINK = 'about_us';
export const PRIVACY_LINK = 'privacy_link';
export const TERMS_LINK = 'privacy_policy_link';
export const STORYBOOK_LINK = 'storybook_link';

const FooterWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const FooterOuterWrapper = styled.View`
  flex-direction: column;
  justify-content: center;
`;

const FooterItem = styled.Text`
  color: ${colors.primary};
  font-family: ${fonts.regular};
`;

const BetaVersionText = styled.Text`
  color: ${colors.grey4};
  font-family: ${fonts.regular};
  font-size: 8px;
  font-style: italic;
  align-self: center;
  margin-top: 10px;
`;

const Divider = styled.Text`
  color: ${colors.grey7};
`;

const devWebLinks = [
  {
    label: 'Walkthrough',
    link: WALKTHROUGH_LINK
  }
];

const devNativeLinks = [
  {
    label: 'Storybook',
    link: STORYBOOK_LINK
  }
];

const devFooterLinks = [...(isWeb ? devWebLinks : devNativeLinks)];

const footerLinks = [
  {
    label: 'About Us',
    link: ABOUT_LINK
  },
  {
    label: 'Privacy Policy',
    link: PRIVACY_LINK
  },
  {
    label: 'Terms',
    link: TERMS_LINK
  },
  ...(__DEV__ ? devFooterLinks : [])
];

const lastFooterLinkIndex = footerLinks.length - 1;

const Footer = ({ onUrlPress }: FooterProps) => (
  <FooterOuterWrapper>
    <FooterWrapper>
      {footerLinks.map(({ label, link }, index) => {
        const isLast = lastFooterLinkIndex === index;

        return (
          <Fragment key={label}>
            <TouchableWithEvent onPress={() => onUrlPress(link)} trackEventId="profile-footer-click">
              <FooterItem>{label}</FooterItem>
            </TouchableWithEvent>
            {!isLast && <Divider> | </Divider>}
          </Fragment>
        );
      })}
    </FooterWrapper>
    {env !== 'production' && <BetaVersionText>Legacy Internal Version</BetaVersionText>}
  </FooterOuterWrapper>
);

export default Footer;
