import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { Icon, TouchableWithEvent } from '@common/components';

import UserAvatar from './UserAvatar';

type AvatarHeaderProps = {
  image?: ProfileImageType;
  initials?: string;
  name: string;
  email: string;
  onLogoutPress: () => void;
};

const HeaderWrap = styled.View`
  background: ${colors.primary};
  padding: 20px 20px 18px;
  align-items: center;
`;

const Name = styled.Text`
  font-family: ${fonts.medium};
  color: ${colors.white};
  font-size: 18px;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 10px;
`;

const Email = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.white};
  font-size: 12px;
  text-align: center;
`;

const HeaderButton = styled.TouchableOpacity.attrs({ activeOpacity: 0.7 })`
  position: absolute;
  top: 0;
  padding: 15px 10px 10px;
`;

const LogoutButton = styled(HeaderButton)`
  right: 0;
`;

const AvatarHeader = ({ image, initials, name, email, onLogoutPress }: AvatarHeaderProps) => (
  <HeaderWrap>
    <LogoutButton onPress={onLogoutPress} testID="logout" as={TouchableWithEvent} trackEventId="profile_logout_click">
      <Icon color={colors.white} size={30} name="exit-to-app" />
    </LogoutButton>
    <UserAvatar image={image} letter={initials} />
    <Name>{name}</Name>
    <Email>{email}</Email>
  </HeaderWrap>
);

export default AvatarHeader;
