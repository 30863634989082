import { StatusBar } from 'react-native';

import Toast from '@common/utils/toast';
import { isIos } from '@common/utils';
import { setAuthToken, logout } from '@common/store/reducers/auth';
import commonStore from '@common/store';

export const apiInterceptor =
  (apiRequest: Function) =>
  async (...args: Array<any>) => {
    try {
      if (isIos) {
        StatusBar.setNetworkActivityIndicatorVisible(true);
      }

      const response = await apiRequest(...args);
      if (response instanceof Blob) {
        return Promise.resolve(response);
      }

      const { authToken, ...resp } = response;
      if (typeof authToken === 'string' && authToken.length) {
        await commonStore.dispatch(setAuthToken(authToken));
      }

      return Promise.resolve(resp);
    } catch (error: any) {
      switch (error.status) {
        case 401: {
          if (error.message && error.message.length) {
            Toast(error.message);
          }
          commonStore.dispatch(logout());
          break;
        }
        case 404:
          break;
        default: {
          const message = { code: error.status ?? 500, message: error.message ?? error, stack: error.stack ?? '' };
          console.error('Error in interceptor', message);
          break;
        }
      }

      throw error;
    } finally {
      if (isIos) {
        StatusBar.setNetworkActivityIndicatorVisible(false);
      }
    }
  };
