import React, { useEffect, useState } from 'react';
import { Animated, Platform, TouchableWithoutFeedback } from 'react-native';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { rgba } from 'polished';
import styled, { css } from 'styled-components/native';

import { iphoneXHomeBarHeight, isAndroid, isWeb } from '@common/utils';
import { colors } from '@common/theme';
import { Handle } from '@common/components';

import TabButton from './TabButton';
import { Route } from '@react-navigation/native';

export type TabBarInternalProps = {
  isMinimized: boolean;
  onHandlePress: () => void;
  onHandleTabPress: (routeName: string) => void;
  tabIcons: any;
  shouldHideTabs?: boolean;
};

type TabBarProps = TabBarInternalProps & BottomTabBarProps;

const TAB_BAR_INNER_HEIGHT = 54;
const TAB_BAR_HANDLE_HEIGHT = 4;
const TAB_BAR_HANDLE_MARGIN = 12;

export const TAB_BAR_HEIGHT = TAB_BAR_INNER_HEIGHT + TAB_BAR_HANDLE_HEIGHT + TAB_BAR_HANDLE_MARGIN + iphoneXHomeBarHeight;

export const TAB_BAR_MINIMIZED_HEIGHT = TAB_BAR_HANDLE_HEIGHT + TAB_BAR_HANDLE_MARGIN + iphoneXHomeBarHeight;

const TabBarWrap = styled.View`
  background: ${colors.white};
  padding-bottom: ${iphoneXHomeBarHeight}px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -5px 10px ${rgba(colors.grey4, 0.1)};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  ${isAndroid &&
  css`
    elevation: 5;
    shadow-color: ${colors.black};
  `}
`;

const TabBarHandleFull = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const TabBarInner = styled.View`
  height: ${TAB_BAR_INNER_HEIGHT}px;
  flex-direction: row;
  justify-content: space-around;
  margin-left: -28px;
`;

const tabAccessibilityProps = Platform.select<any>({
  web: { accessibilityRole: 'navigation' },
  default: {}
});

const TabBar = ({ isMinimized, onHandlePress, shouldHideTabs, tabIcons, onHandleTabPress, ...props }: TabBarProps) => {
  const {
    state: { index, routes },
    navigation,
    descriptors
  } = props;
  const [tabsVisibility] = useState(new Animated.Value(0));

  const onTabPress = (route: Route<any>, isActive: boolean) => {
    if (onHandleTabPress) {
      onHandleTabPress(route.name);
    }

    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true
    });

    if (!isActive && !event.defaultPrevented) {
      navigation.navigate(route.name);
    }
  };

  useEffect(() => {
    if (shouldHideTabs) {
      Animated.timing(tabsVisibility, {
        toValue: -1,
        duration: 300,
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(tabsVisibility, {
        toValue: isMinimized ? 0 : 1,
        duration: 300,
        useNativeDriver: true
      }).start();
    }
  }, [isMinimized, shouldHideTabs, tabsVisibility]);

  return (
    <TabBarWrap
      {...tabAccessibilityProps}
      testID="tab-bar"
      as={Animated.View}
      style={{
        transform: [
          {
            translateY: tabsVisibility.interpolate({
              inputRange: [-1, 0, 1],
              outputRange: [TAB_BAR_HEIGHT, TAB_BAR_INNER_HEIGHT, 0]
            })
          }
        ]
      }}
    >
      <Handle disabled={isMinimized && !isWeb} onPress={onHandlePress} />

      <TabBarInner
        as={Animated.View}
        style={
          !isWeb
            ? {
                opacity: tabsVisibility.interpolate({
                  inputRange: [-1, 0, 1],
                  outputRange: [0, 0, 1]
                })
              }
            : {}
        }
      >
        {routes.map((route, routeIndex) => {
          const isActive = index === routeIndex;
          const iconName = tabIcons[route.name];
          const options = descriptors[route.key]?.options;
          const label = options.tabBarLabel ?? route.name;
          const showBadge = !!options.tabBarBadge;
          const badgeLabel = showBadge ? Number(options.tabBarBadge) : undefined;

          return (
            <TabButton
              key={route.key}
              testID={`tab-bar-${route.name}`}
              badgeLabel={badgeLabel}
              disabled={isMinimized}
              isActive={isActive}
              icon={iconName}
              label={label}
              onPress={() => onTabPress(route, isActive)}
              showBadge={showBadge}
            />
          );
        })}
      </TabBarInner>

      {isMinimized && !isWeb && (
        <TouchableWithoutFeedback onPress={onHandlePress}>
          <TabBarHandleFull />
        </TouchableWithoutFeedback>
      )}
    </TabBarWrap>
  );
};

export default TabBar;
