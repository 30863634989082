import React, { useState } from 'react';
import RichTextEditor from 'react-rte';

import { colors } from '@common/theme';

const RichText = ({ value: initValue, onChange }: any) => {
  const [value, setValue] = useState(RichTextEditor.createValueFromString(initValue, 'html'));

  const swapSoftNewLineBehavior = (event: any) => {
    const isSoftKeyPressed = (e: any) => {
      return e.key === 13 && (e.getModifierState('Shift') || e.getModifierState('Alt') || e.getModifierState('Control'));
    };

    if (!isSoftKeyPressed(event)) {
      event.getModifierState = (_: any) => {
        return true;
      };
    } else {
      event.getModifierState = (_: any) => {
        return false;
      };
    }

    return isSoftKeyPressed(event);
  };

  const richTextHandle = (text: any) => {
    if (text) {
      const transformedValue = text.toString('html').replaceAll('<p>', '<div>').replaceAll('</p>', '</div>').replaceAll('\n', '');
      onChange(transformedValue);
      setValue(text);
    } else {
      onChange('');
      setValue(RichTextEditor.createEmptyValue());
    }
  };

  const toolbarConfig: any = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      { label: 'Bold', style: 'BOLD' },
      { label: 'Italic', style: 'ITALIC' },
      { label: 'Underline', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' }
    ]
  };

  return (
    <RichTextEditor
      editorStyle={{ color: `${colors.grey4}` }}
      handleReturn={swapSoftNewLineBehavior}
      toolbarConfig={toolbarConfig}
      value={value}
      onChange={richTextHandle}
    />
  );
};

export default RichText;
