import React from 'react';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { TouchableWithEvent } from '@common/components';

type PickerHeaderProps = {
  onCancelPress?: () => void;
  onDonePress?: () => void;
  title?: string;
};

const PickerHeaderWrap = styled.View`
  flex-direction: row;
  height: 48px;
  background: ${colors.grey6};
  justify-content: space-between;
  align-items: center;
`;

const HeaderButton = styled.TouchableOpacity.attrs({
  accessibilityRole: 'button'
})`
  padding: 0 10px;
`;

const ButtonLabel = styled.Text`
  font-size: 15px;
  font-family: ${fonts.medium};
  color: ${colors.primary};
`;

const HeaderTitle = styled.Text`
  flex: 1;
  text-align: center;
  font-family: ${fonts.medium};
  font-size: 18px;
  padding-right: 4px;
  color: ${colors.grey4};
`;

const PickerHeader = ({ onCancelPress, onDonePress, title }: PickerHeaderProps) => (
  <PickerHeaderWrap>
    <HeaderButton onPress={onCancelPress} as={TouchableWithEvent} trackEventId="chat-picker-cancel-click">
      <ButtonLabel>Cancel</ButtonLabel>
    </HeaderButton>

    <HeaderTitle accessibilityRole="header" aria-level="2">
      {title}
    </HeaderTitle>

    <HeaderButton onPress={onDonePress} as={TouchableWithEvent} trackEventId="chat-picker-done-click">
      <ButtonLabel>Done</ButtonLabel>
    </HeaderButton>
  </PickerHeaderWrap>
);

export default PickerHeader;
