import React from 'react';
import { Animated, ViewProps } from 'react-native';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { Button } from '@common/components';

export type EmptyStateProps = {
  buttonLabel?: string;
  displayButton?: boolean;
  onButtonPress?: () => void;
  title?: string;
} & Animated.AnimatedProps<ViewProps>;

const Background = styled.View`
  flex: 1;
  background: ${colors.white};
  align-items: center;
  justify-content: center;
`;

const Label = styled.Text`
  color: ${colors.grey5};
  font-family: ${fonts.regular};
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  margin-top: 34px;
`;

const EmptyState = ({ buttonLabel = 'Refine search', displayButton = true, onButtonPress, title = 'No results found', ...props }: EmptyStateProps) => (
  <Background as={Animated.View} {...props}>
    <Label>{title}</Label>

    {displayButton && (
      <StyledButton variant="grey" onPress={onButtonPress} trackEventId="chat-refine-search-click">
        {buttonLabel}
      </StyledButton>
    )}
  </Background>
);

export default EmptyState;
