import { useCallback, useEffect, useRef } from 'react';

import { auth, database, DataSnapshot, Persistence, setPersistence } from '@common/utils/firebase';
import Toast from '@common/utils/toast';

type FirebaseListener = () => void;

export const useFirebaseListener = (loginWith: any) => {
  const firebaseListener = useRef<FirebaseListener>();

  const login = useCallback(
    (username: string) => {
      firebaseListener.current = auth().onAuthStateChanged(async user => {
        if (user) {
          const firebaseIdToken = await user.getIdToken();

          try {
            await loginWith(username, true, firebaseIdToken);
          } catch (error: any) {
            if (error && error.message) {
              Toast(error.message);
            }
          }

          const tokenRef = database().ref(`users/${user.uid}/token`);

          const onTokenRefChange = async (snapshot: DataSnapshot) => {
            const value = snapshot.val();

            if (value) {
              tokenRef.remove();
              tokenRef.off('value', onTokenRefChange);

              await user.delete();

              auth().signOut();

              window.parent.postMessage(
                {
                  type: 'ROLLIO/AUTH_TOKEN',
                  payload: value
                },
                document.referrer || '*'
              );
            }
          };

          tokenRef.on('value', onTokenRefChange);
        }
      });

      setPersistence(Persistence.NONE).then(() => auth().signInAnonymously());
    },
    [loginWith]
  );

  // Unsubscribe firebase listener
  useEffect(
    () => () => {
      if (firebaseListener && firebaseListener.current) {
        firebaseListener.current();
      }
    },
    []
  );

  return { login };
};

export default useFirebaseListener;
