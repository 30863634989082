import { is } from 'ramda';

export const createLookupsFromParents = (fields: FieldType[], values?: Record<string, string>) =>
  fields.reduce((acc: FieldType[], field: FieldType) => {
    if (field.type === 'Lookup') {
      const parents = field.parent && !is(Array, field.parent) ? [field.parent] : field.parent;
      if (parents?.length) {
        return [...acc, ...parents.map(parent => ({ ...field, parent }))];
      }

      return [...acc, field];
    }

    const value = values ? values[field.name] : field.value;
    return [
      ...acc,
      {
        ...field,
        value: Array.isArray(value) ? value.join(';') : value
      }
    ];
  }, []);
