import { set, lensPath } from '@common/utils';
import { Action } from '..';

// State
export type EditionState = {
  editingLookup: boolean;
  editingContextObject?: string;
  isSearching: boolean;
  lookupField?: FieldType;
};

const initialState: EditionState = {
  editingLookup: false,
  editingContextObject: undefined,
  isSearching: false,
  lookupField: undefined
};

// Reducer
const edition = (state: EditionState = initialState, action: Action) => {
  switch (action.type) {
    case 'Edition/LOOKUP':
      return set(lensPath(['editingLookup']), action.payload, state);

    case 'Edition/CONTEXTOBJECT':
      return set(lensPath(['editingContextObject']), action.payload, state);

    case 'Edition/SEARCH':
      return set(lensPath(['isSearching']), action.payload, state);

    case 'Edition/LOOKUP_FIELD':
      return set(lensPath(['lookupField']), action.payload, state);

    case 'Edition/UPDATING_CONTEXT':
      return set(lensPath(['wasContextUpdated']), action.payload, state);

    case 'Edition/RESET':
      return { ...state, ...initialState };

    default:
      return state;
  }
};

export default edition;
