import edition, { EditionState } from './edition';
import { EditionActions } from './edition/actions';
import messages, { MessageState } from './messages';
import { MessageActions } from './messages/actions';
import picklist, { PicklistState } from './picklist';
import { PicklistActions } from './picklist/actions';
import search, { SearchState } from './search';
import { SearchActions } from './search/actions';

export type Action = MessageActions | PicklistActions | SearchActions | EditionActions;

export type State = {
  messages: MessageState;
  picklist: PicklistState;
  search: SearchState;
  edition: EditionState;
};

export { messages, picklist, search, edition };
