import { type Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

import { Action } from '@legacy/ducks';

import { preparePicklistData } from './actions';

export const populatePicklistOnMessagesAppend = (action$: Observable<Action>): Observable<any> =>
  action$.pipe(
    filter(action => action.type === 'Messages/PREPEND'),
    mergeMap(() => of(preparePicklistData()))
  );
