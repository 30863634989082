import React, { ReactNode, useMemo } from 'react';
import { Linking, Text } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledTextProps } from 'styled-components/types';

import { isChromeExtension, isString, isWeb } from '@common/utils';
import { colors, fonts } from '@common/theme';

type BubbleMessageProps = {
  isBot?: boolean;
  color?: string;
  children: string | ReactNode;
};

type BubbleTextProps = { color: string };

const BubbleText = styled.Text.attrs<StyledTextProps>({
  selectable: false
})<BubbleTextProps>(
  ({ color }) => css`
    color: ${color};
    font-family: ${fonts.regular};
    font-size: 15px;
    line-height: 20px;
    text-align: left;
    ${isWeb &&
    css`
      word-break: break-word;
    `}
  `
);

const Link = styled.Text`
  color: ${colors.primary};
  text-decoration: underline;
  font-family: ${fonts.bold};
`;

const createLinkByEnv = (match: string[]) => {
  const splitted = match[1].split(',');
  const web = splitted.find(link => link.includes('https://')) ?? match[1];
  // Create link depending on env
  let ret: any = { accessibilityRole: 'link', href: web, target: '_blank' };
  if (!isWeb) {
    const sforce = splitted.find(link => link.includes('salesforce1://')) ?? web;
    ret = { onPress: () => Linking.openURL(sforce) };
  } else if (isChromeExtension) {
    ret = { accessibilityRole: 'button', onPress: () => window.open(web, '_blank') };
  }

  return ret;
};

const renderLink = (link: string, index: number) => {
  const key = `link_${index}`;
  const match = link.match(/href="(.*)".*>(.*)</);

  return match ? (
    <Link key={key} {...createLinkByEnv(match)}>
      {match[2]}
    </Link>
  ) : (
    <Text key={key}>{link}</Text>
  );
};

const renderParts = (str: string) => {
  const regex = /<a.*?<\/a>/g;
  const links = str.match(regex);
  return str.split(regex).reduce((acc: JSX.Element[], part, index) => {
    const link = links && links[index];
    const key = `text_${index}`;
    return [...acc, <Text key={key}>{part}</Text>, ...(link ? [renderLink(link, index)] : [])];
  }, []);
};

const BubbleMessage = ({ isBot = false, color = colors.white, children }: BubbleMessageProps) => {
  const node = useMemo(() => {
    if (children && isString(children)) {
      const text = isBot ? renderParts(String(children)) : children;
      return (
        <BubbleText testID="text-message" color={color}>
          {text}
        </BubbleText>
      );
    }

    return <>{children}</>;
  }, [children, color, isBot]);

  return node;
};

export default BubbleMessage;
