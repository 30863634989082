import React, { MouseEvent, useEffect, useRef } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import styled from 'styled-components';

import { colors, fonts } from '@common/theme';
import { toDataTestIdFriendly } from '@common/utils';
import { Button } from '@common/components';

type ButtonType = {
  text: string;
  style?: 'cancel';
  onPress?: () => null;
};
type OptionsType = {
  cancelable?: boolean;
};

type WebAlertProps = {
  title: string;
  message?: string;
  buttons?: ButtonType[];
  options?: OptionsType;
};

const removeElementAlert = () => {
  const target = document.getElementById('rollio-alert');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode?.removeChild(target);
  }
};

const AlertOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.5s 0.2s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const AlertBody = styled.div`
  font-family: ${fonts.regular};
  width: 400px;
  max-width: 70%;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: ${colors.grey4};
`;

const AlertTitle = styled.div`
  font-family: ${fonts.bold};
  color: ${colors.grey4};
  font-size: 26px;
  margin-bottom: 10px;
`;

const AlertButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const WebAlert = ({ title, message, buttons = [], options }: WebAlertProps) => {
  const overlay = useRef<HTMLDivElement>(null);

  const close = () => {
    removeElementAlert();
  };

  const handleClickButton = (button: ButtonType) => {
    if (button.onPress) {
      button.onPress();
    }
    close();
  };

  const handleClickOverlay = (e: MouseEvent<HTMLElement>) => {
    const isClickOutside = e.target === overlay.current;
    if (isClickOutside && options?.cancelable) {
      close();
    }
  };

  const keyboardClose = (event: KeyboardEvent) => {
    const isKeyCodeEscape = event.keyCode === 27;
    if (isKeyCodeEscape && options?.cancelable) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyboardClose, false);
    return () => {
      document.removeEventListener('keydown', keyboardClose, false);
    };
  }, []);

  return (
    <AlertOverlay ref={overlay} onClick={handleClickOverlay}>
      <AlertBody>
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
        <AlertButtonGroup>
          {buttons.map(button => {
            const friendlyLabel = toDataTestIdFriendly(button.text);

            return (
              <Button
                key={button.text}
                testID={`alert-button-${button.text}`}
                onPress={() => handleClickButton(button)}
                variant={button.style !== 'cancel' ? 'primary' : 'grey'}
                trackEventId={`alert_${friendlyLabel}_click`}
              >
                {button.text}
              </Button>
            );
          })}
        </AlertButtonGroup>
      </AlertBody>
    </AlertOverlay>
  );
};

export default WebAlert;
