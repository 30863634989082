import React from 'react';
import { Image, ImageResizeMode } from 'react-native';
import styled from 'styled-components/native';

import { colors, fonts } from '@common/theme';

type UserAvatarProps = {
  image?: ProfileImageType;
  letter?: string;
};

const AvatarCircle = styled.View`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background: white;
  justify-content: center;
`;

const AvatarLetter = styled.Text`
  color: ${colors.primary};
  font-size: 30px;
  font-family: ${fonts.bold};
  text-align: center;
`;

const UserAvatar = ({ image, letter = '' }: UserAvatarProps) => {
  if (!image) {
    const ucLetter = letter.toUpperCase();
    return (
      <AvatarCircle>
        <AvatarLetter>{ucLetter}</AvatarLetter>
      </AvatarCircle>
    );
  }

  const imageAvatarProps = {
    as: Image,
    source: { uri: image },
    resizeMode: 'cover' as ImageResizeMode
  };

  return <AvatarCircle {...imageAvatarProps} />;
};

export default UserAvatar;
