import { useEffect, useState } from 'react';
import { Keyboard, KeyboardEvent } from 'react-native';

const useKeyboard = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    const kbDidShow = Keyboard.addListener('keyboardDidShow', (e: KeyboardEvent) => {
      setKeyboardHeight(e.endCoordinates.height);
    });
    const kbDidHide = Keyboard.addListener('keyboardDidHide', () => setKeyboardHeight(0));

    return () => {
      kbDidShow.remove();
      kbDidHide.remove();
    };
  }, []);

  return { keyboardHeight };
};

export default useKeyboard;
