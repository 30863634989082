import { Dispatch } from 'redux';

import { get, isEqual, isEmpty, lensPath, messageTypes, pathOr, view } from '@common/utils';
import commonStore from '@common/store';
import { getToken } from '@common/store/reducers/auth';
import { apiInterceptor } from '@common/store/interceptors';
import { Action, State } from '@legacy/ducks';
import { getLatestMessage, getLatestMessageType } from '@legacy/ducks/messages/selectors';
import { getDataSearchPagination } from '@legacy/webService';

import { getTabNames, getActiveFiltersByDefault, getSearchResults, getSelectedSearchResults, getFilters, getTextSearch, getCurrentPage } from './selectors';
import { getEditionContextObject, getIsEdition } from '../edition/selectors';

const mapFilterToPills = (filter: FilterShape, element: TableRow): FilterShape => {
  const { label, value, id } = filter;
  const { metaData }: { metaData: any } = element;

  return {
    label: metaData[label],
    value: value && metaData[value],
    id: metaData[id],
    paramId: filter.id
  };
};

// Action types
type SearchRequest = {
  type: 'Search/REQUEST';
};

type SearchToggleUnselected = {
  type: 'Search/TOGGLE_UNSELECTED_RESULT';
  payload: TableRow;
};

type SearchSuccess = {
  type: 'Search/SUCCESS';
};

type SearchError = {
  type: 'Search/ERROR';
};

type SearchIncrementPage = {
  type: 'Search/INCREMENT_PAGE';
};

type SearchResetPagination = {
  type: 'Search/RESET_PAGINATION';
};

type SearchFinishedPagination = {
  type: 'Search/FINISHED_PAGINATION';
};

type SearchPrepopulateResults = {
  type: 'Search/PREPOPULATE_RESULTS';
  payload: ResultsShape[];
};

type SearchAppendResults = {
  type: 'Search/APPEND_RESULTS';
  payload: { results: ResultsShape; isEditing: boolean };
};

type SearchClearResults = {
  type: 'Search/CLEAR_RESULTS';
};

type SearchClearUnselectedResults = {
  type: 'Search/CLEAR_UNSELECTED_RESULTS';
};

type SearchUpdateResult = {
  type: 'Search/UPDATE_RESULT';
  payload: {
    rowIndex: number;
    row: TableRow;
  };
};

type SearchDefineTabs = {
  type: 'Search/DEFINE_TABS';
  payload: TabsShape;
};

type SearchChangeTab = {
  type: 'Search/CHANGE_TAB';
  payload: number;
};

type SearchChangeTextSearch = {
  type: 'Search/CHANGE_TEXT_SEARCH';
  payload: string;
};

type SearchClearTextSearch = {
  type: 'Search/CLEAR_TEXT_SEARCH';
};

type SearchSetFilters = {
  type: 'Search/SET_FILTERS';
  payload: FilterGroupShape;
};

type SearchResetFilters = {
  type: 'Search/RESET_FILTERS';
  payload: FilterGroupShape;
};

type SearchRemoveFilter = {
  type: 'Search/REMOVE_FILTER';
  payload: number;
};

type SearchResetAll = {
  type: 'Search/RESET_ALL';
};

type SearchSetIntialResults = {
  type: 'Search/INITIAL_RESULTS';
  payload: ResultsShape[];
};

type SearchPanelIsExpanded = {
  type: 'Search/IS_EXPANDED';
  payload: boolean;
};

export type SearchActions =
  | SearchRequest
  | SearchToggleUnselected
  | SearchSuccess
  | SearchError
  | SearchIncrementPage
  | SearchResetPagination
  | SearchFinishedPagination
  | SearchPrepopulateResults
  | SearchAppendResults
  | SearchClearResults
  | SearchClearUnselectedResults
  | SearchUpdateResult
  | SearchDefineTabs
  | SearchChangeTab
  | SearchChangeTextSearch
  | SearchClearTextSearch
  | SearchSetFilters
  | SearchResetFilters
  | SearchRemoveFilter
  | SearchResetAll
  | SearchSetIntialResults
  | SearchPanelIsExpanded;

// Action creators
const searchRequest: () => Action = (): SearchRequest => ({
  type: 'Search/REQUEST'
});

const searchSuccess: () => Action = (): SearchSuccess => ({
  type: 'Search/SUCCESS'
});

const searchError: () => Action = (): SearchError => ({
  type: 'Search/ERROR'
});

export const toggleUnselected: (payload: TableRow) => Action = (result): SearchToggleUnselected => ({
  type: 'Search/TOGGLE_UNSELECTED_RESULT',
  payload: result
});

export const incrementPage: () => Action = (): SearchIncrementPage => ({
  type: 'Search/INCREMENT_PAGE'
});

export const resetPagination: () => Action = (): SearchResetPagination => ({
  type: 'Search/RESET_PAGINATION'
});

export const finishedPagination: () => Action = (): SearchFinishedPagination => ({
  type: 'Search/FINISHED_PAGINATION'
});

export const setInitialResults: (payload: ResultsShape[]) => Action = (results): SearchSetIntialResults => ({
  type: 'Search/INITIAL_RESULTS',
  payload: results
});

const prepopulateResults: (payload: ResultsShape[]) => Action = (results): SearchPrepopulateResults => ({
  type: 'Search/PREPOPULATE_RESULTS',
  payload: results
});

export const appendResults: (payload: ResultsShape, isEditingMulti?: boolean) => Action = (results, isEditing = false): SearchAppendResults => ({
  type: 'Search/APPEND_RESULTS',
  payload: {
    results,
    isEditing
  }
});

const updateResult: (number: number, TableRow: TableRow) => Action = (rowIndex, row): SearchUpdateResult => ({
  type: 'Search/UPDATE_RESULT',
  payload: {
    rowIndex,
    row
  }
});

export const clearResults: () => Action = (): SearchClearResults => ({
  type: 'Search/CLEAR_RESULTS'
});

export const clearUnselectedResults: () => Action = (): SearchClearUnselectedResults => ({
  type: 'Search/CLEAR_UNSELECTED_RESULTS'
});

const defineTabs: (payload: TabsShape) => Action = (results): SearchDefineTabs => ({
  type: 'Search/DEFINE_TABS',
  payload: results
});

export const changeTab: (payload: number) => Action = (tab): SearchChangeTab => ({
  type: 'Search/CHANGE_TAB',
  payload: tab
});

export const changeTextSearch: (payload: string) => Action = (searchTerm): SearchChangeTextSearch => ({
  type: 'Search/CHANGE_TEXT_SEARCH',
  payload: searchTerm
});

const clearTextSearch: () => Action = (): SearchClearTextSearch => ({
  type: 'Search/CLEAR_TEXT_SEARCH'
});

const setFilters: (payload: any) => Action = (filters): SearchSetFilters => ({
  type: 'Search/SET_FILTERS',
  payload: filters
});

export const resetFilters: () => Action = (): SearchResetFilters => ({
  type: 'Search/RESET_FILTERS',
  payload: []
});

export const removeFilter: (payload: number) => Action = (filterIndex): SearchRemoveFilter => ({
  type: 'Search/REMOVE_FILTER',
  payload: filterIndex
});

export const resetSearch: () => Action = (): SearchResetAll => ({
  type: 'Search/RESET_ALL'
});

// Thunks
export const prepareSearchScreen = () => (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const latestMessageType = getLatestMessageType(state);

  if (latestMessageType === messageTypes.SEARCH) {
    const latestMessage = getLatestMessage(state);
    const tabs = pathOr<TabsShape>([], ['tabs'], latestMessage);
    const tableData = pathOr<ResultsShape[]>([], ['rows'], latestMessage);

    const firstTabWithResults = tableData.findIndex(table => table.length);
    const selectedTab = firstTabWithResults >= 0 ? firstTabWithResults : 0;

    dispatch(defineTabs(tabs));
    dispatch(changeTab(selectedTab));
    dispatch(setInitialResults(tableData));
    dispatch(prepopulateResults(tableData));
  }
};

export const prepareSearch = (tabs: TabsShape, selectedTab: any, tableData: ResultsShape[]) => (dispatch: Dispatch) => {
  dispatch(defineTabs(tabs));
  dispatch(changeTab(selectedTab));
  dispatch(setInitialResults(tableData));
  dispatch(prepopulateResults(tableData));
};

export const search = () => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const {
    search: { selectedTab, initialResults },
    edition: { lookupField }
  } = state;
  const tabNames = getTabNames(state);
  const filters = getFilters(state);
  const textSearch = getTextSearch(state);
  const currentPage = getCurrentPage(state);
  const isEdition = getIsEdition(state);
  const latestMessage = getLatestMessage(state);
  const isEditingMulti = isEdition && lookupField?.type === 'MultiLookup';

  let contextObject;
  if (isEdition) {
    contextObject = getEditionContextObject(state);
  } else {
    contextObject = view(lensPath(['params', 'contextObject']), latestMessage);
  }

  const filterOptions =
    filters &&
    filters.reduce(
      (acc, { paramId, id }) => ({
        ...acc,
        [paramId]: id
      }),
      {}
    );

  if (isEmpty(filterOptions) && (!textSearch || !textSearch.trim().length)) {
    const currentResults = getSearchResults(state);
    if (!currentResults.filter(row => !row.isSelected).length) {
      const initialTabResults = initialResults[selectedTab] ?? [];
      const initialTabData = isEdition ? initialTabResults : pathOr([], ['rows', selectedTab], latestMessage);
      if (initialTabData.length) {
        dispatch(appendResults(initialTabData, isEdition));
      }
    }

    return;
  }

  dispatch(searchRequest());

  try {
    const apiRequest = apiInterceptor(getDataSearchPagination);
    const token = getToken(commonStore.getState());
    // Just taking what we need so we decrease the weight of the query params (no need to send the entire field)
    const { lookupObjects } = lookupField ?? { lookupObjects: [] };
    const options = {
      count: 10,
      object: tabNames[selectedTab],
      page: currentPage,
      name: textSearch,
      contextObject,
      lookupField: JSON.stringify({ lookupObjects }),
      ...filterOptions
    };

    const { data } = await apiRequest(token, options);
    const tableRows = get<ResultsShape>(data, ['rows', 0], []);

    if (tableRows.length) {
      const selectedRowIds = getSelectedSearchResults(state).map(row => row.id);
      let filteredRows = tableRows.filter(row => !selectedRowIds.includes(row.id));

      if (isEditingMulti) {
        filteredRows =
          filteredRows?.map(row => {
            if (lookupField.id?.split(',').some(id => id === row.id && !row.isSelected)) {
              row.isSelected = true;
            }

            return row;
          }) ?? [];
      }

      dispatch(appendResults(filteredRows, isEdition));
    } else {
      dispatch(finishedPagination());
    }

    dispatch(searchSuccess());
  } catch (error) {
    dispatch(searchError());
  }
};

export const selectTableRow = (index: number) => (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const defaultActiveFilters = getActiveFiltersByDefault(state);
  const results = getSearchResults(state);
  const filters = getFilters(state);
  const selectedRows = getSelectedSearchResults(state);
  const pressedRow = results[index];

  if (pressedRow && typeof pressedRow === 'object') {
    if (pressedRow.postback?.fields?.[0].crossTableId) {
      dispatch(toggleUnselected(pressedRow));
    }

    const toggledRow: TableRow = {
      ...pressedRow,
      isSelected: !pressedRow.isSelected
    };

    dispatch(updateResult(index, toggledRow));

    if (toggledRow.isSelected) {
      const newFilters = defaultActiveFilters.map(filter => mapFilterToPills(filter, toggledRow));
      const isSameFilter = isEqual(filters, newFilters);

      if (!isSameFilter) {
        dispatch(clearTextSearch());
        dispatch(setFilters(newFilters));
      }
    } else {
      const otherSelectedRows = selectedRows.filter(row => row.id !== pressedRow.id);
      if (!otherSelectedRows.length) {
        dispatch(resetFilters());
      }
    }
  }
};

export const setPanelIsExpanded: (payload: boolean) => Action = (payload): SearchPanelIsExpanded => ({
  type: 'Search/IS_EXPANDED',
  payload
});
