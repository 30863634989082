import { Dispatch } from 'redux';
import { REHYDRATE } from 'redux-persist';

import { MESSAGES_PER_PAGE, trackEvent } from '@common/utils';
import commonStore from '@common/store';
import { getToken } from '@common/store/reducers/auth';
import { apiInterceptor } from '@common/store/interceptors';
import { sendSttReport } from '@common/webService';
import { scrollChatToBottom } from '@common/store/reducers/ui';

import { Action, State } from '@legacy/ducks';
import { getMessageHistory, sendMessage } from '@legacy/webService';

type SendingMessage = {
  type: 'Messages/SENDING';
  payload: boolean;
};

type LoadingMessage = {
  type: 'Messages/LOADING';
  payload: boolean;
};

type PrependMessages = {
  type: 'Messages/PREPEND';
  payload: Messages;
};

type AppendMessages = {
  type: 'Messages/APPEND';
  payload: Messages;
};

type ResetMessages = {
  type: 'Messages/RESET';
};

type MarkMessageRead = {
  type: 'Messages/MARK_AS_READ';
  payload: MessageType;
};

type AreMoreMessages = {
  type: 'Messages/ARE_MORE_MESSAGES';
  payload: boolean;
};

type ConfirmationFields = {
  type: 'Messages/SET_CONFIRMATION_FIELDS';
  payload: FieldType[];
};

type Rehydrate = {
  type: typeof REHYDRATE;
  payload: any;
};

// Actions
export type MessageActions =
  | SendingMessage
  | LoadingMessage
  | PrependMessages
  | AppendMessages
  | ResetMessages
  | MarkMessageRead
  | AreMoreMessages
  | ConfirmationFields
  | Rehydrate;
// Action Creators
const sendingMessage: (payload: boolean) => Action = (isSending): SendingMessage => ({
  type: 'Messages/SENDING',
  payload: isSending
});

export const loadingMessages: (payload: boolean) => Action = (isLoading): LoadingMessage => ({
  type: 'Messages/LOADING',
  payload: isLoading
});

export const prependMessages: (payload: Messages, scrollToBottom?: boolean) => Action = (messages, scrollToBottom = false): PrependMessages => {
  if (scrollToBottom) {
    // Scroll to bottom
    commonStore.dispatch(scrollChatToBottom());
  }

  return {
    type: 'Messages/PREPEND',
    payload: messages
  };
};

export const appendMessages: (payload: Messages) => Action = (messages): AppendMessages => ({
  type: 'Messages/APPEND',
  payload: messages
});

export const resetMessages: () => Action = (): ResetMessages => ({
  type: 'Messages/RESET'
});

const markMessagesRead: (payload: MessageType) => Action = (message): MarkMessageRead => ({
  type: 'Messages/MARK_AS_READ',
  payload: message
});

const areMoreMessages: (payload: boolean) => Action = (areMore): AreMoreMessages => ({
  type: 'Messages/ARE_MORE_MESSAGES',
  payload: areMore
});

export const setConfirmationFields: (payload?: FieldType[]) => Action = (fields = []): ConfirmationFields => ({
  type: 'Messages/SET_CONFIRMATION_FIELDS',
  payload: fields
});

// Side effects, epics, thunks, etc
export const loadMessages = (messagesToAppend?: any[]) => async (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const {
    messages: { messages, isLoading }
  } = state;

  if (!isLoading) {
    dispatch(loadingMessages(true));
    let _total = 0;
    let allMessagesCount = 0;
    try {
      const apiRequest = apiInterceptor(getMessageHistory);
      const token = getToken(commonStore.getState());
      const { data = [], total } = await apiRequest(token, messages.length, MESSAGES_PER_PAGE);
      const additionMethod = messages.length ? appendMessages : prependMessages;
      // Checking total messages vs messages already rendered to see if we have more
      _total = total;
      allMessagesCount = messages.length;

      if (data.length) {
        allMessagesCount += data.length;
        await dispatch(additionMethod(data));
      }

      if (messagesToAppend) {
        _total += messagesToAppend.length;
        allMessagesCount += messagesToAppend.length;
        messagesToAppend.forEach(m => dispatch(prependMessages([m])));
      }
    } catch (error) {
      dispatch(areMoreMessages(false));
      dispatch(loadingMessages(false));
    } finally {
      // Here we can avoid the infinit list as feature flag, just setting areMore to false.
      // ===> [check the areMore in messages store when rehydrating too]
      await dispatch(areMoreMessages(!!_total && _total > allMessagesCount));
      await dispatch(loadingMessages(false));
    }
  }
};

export const postMessage =
  (message: Postback | string, sttInteraction = false, transcript: string = '') =>
  async (dispatch: Dispatch) => {
    const userMessage: any = {
      type: 0,
      createdAt: new Date(),
      isBot: false,
      read: false,
      text: typeof message === 'object' ? message.messageText : message.trim()
    };

    try {
      // Scroll to bottom
      commonStore.dispatch(scrollChatToBottom());

      const isPostback = typeof message === 'object';

      dispatch(prependMessages([userMessage]));
      dispatch(sendingMessage(true));

      const apiRequest = apiInterceptor(sendMessage);
      const token = getToken(commonStore.getState());

      const { data } = await apiRequest(message, token, isPostback);

      if (sttInteraction) {
        const apiRequestStt = apiInterceptor(sendSttReport);
        userMessage.transcript = transcript;
        apiRequestStt(message, token);
      }

      dispatch(markMessagesRead(userMessage));
      dispatch(prependMessages(data.rollioMessages));
    } catch (error) {
      // handled by interceptor
    } finally {
      dispatch(sendingMessage(false));
      if (userMessage.transcript) {
        trackEvent('stt_message_sent', userMessage);
      }
      trackEvent('event_message_sent', userMessage);
    }
  };
