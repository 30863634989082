import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { messageTypes, pathOr } from '@common/utils';
import { PicklistPanel, PanelBaseInternalProps } from '@common/components';
import { useHeaderButtons } from '@common/hooks';
import { postMessage } from '@legacy/ducks/messages/actions';
import { getLatestMessage, getLatestMessageType } from '@legacy/ducks/messages/selectors';
import { getSelectedOptions, getVisibleOptions } from '@legacy/ducks/picklist/selectors';
import { selectPicklistOption, resetPicklist } from '@legacy/ducks/picklist/actions';

const Picklist = (props: PanelBaseInternalProps) => {
  const dispatch = useDispatch();
  const reset = useCallback(() => {
    dispatch(resetPicklist());
  }, [dispatch]);
  const selectOption = (payload: Button) => dispatch(selectPicklistOption(payload));
  const sendMessage = (payload: Postback) => dispatch(postMessage(payload));
  const latestMessageType = useSelector(getLatestMessageType);
  const latestMessage = useSelector(getLatestMessage);
  const options = useSelector(getVisibleOptions);
  const selectedOptions = useSelector(getSelectedOptions);
  const isMultiSelect = latestMessageType === messageTypes.MULTI_PICKLIST;

  const [headerButtons] = useHeaderButtons(latestMessage, {
    disableDone: selectedOptions.length === 0,
    isMultiSelect,
    onCancelPress: () => reset(),
    onDonePress: () => {
      const jointOptions = selectedOptions.join(', ');
      const contextField = pathOr('', ['params', 'contextField'], latestMessage);

      sendMessage({
        button: true,
        messageText: jointOptions,
        fields: [
          {
            entity: contextField,
            value: selectedOptions.join(';')
          }
        ]
      });

      reset();
    },
    onSkipPress: () => reset(),
    sendMessage
  });

  const expandByDefault = useMemo(() => pathOr(false, ['params', 'panelIsExpanded'], latestMessage), [latestMessage]);

  return (
    <PicklistPanel
      {...props}
      expandedDefault={expandByDefault}
      headerButtons={headerButtons}
      isMultiSelect={isMultiSelect}
      onOptionPress={selectOption}
      options={options}
      selectedOptions={selectedOptions}
    />
  );
};

export default Picklist;
