import { AppRegistry } from 'react-native';

import { appRegistryName, isWeb } from '@common/utils';

import { startApm } from '@common/utils/apm';

import App from './App';

AppRegistry.registerComponent(appRegistryName, () => App);

if (isWeb) {
  AppRegistry.runApplication(appRegistryName, {
    initialProps: {},
    rootTag: document.getElementById('root')
  });

  startApm();
}
