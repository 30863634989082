import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Animated, Easing, PanResponder, TouchableWithoutFeedback, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components/native';
import { useKeyboard } from '@react-native-community/hooks';

import { colors } from '@common/theme';
import { boolToNum, clamp } from '@common/utils';
import { usePanelHeight } from '@common/hooks';

import { getPanelIsExpanded } from '@legacy/ducks/search/selectors';
import { setPanelIsExpanded } from '@legacy/ducks/search/actions';

import HeaderButtons from './HeaderButtons';

export type PanelBaseInternalProps = {
  headerButtons?: ButtonsShape;
  expandedDefault?: boolean;
  isVisible?: boolean;
  triggerExpansion?: boolean;
  testID?: string;
};

type PanelBaseProps = PanelBaseInternalProps & {
  children: ReactNode;
  topComponents?: () => ReactNode;
};

const fullscreenStyle = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MainWrap = styled.View`
  justify-content: flex-end;
  ${fullscreenStyle}
`;

const BackdropOuter = styled.View`
  ${fullscreenStyle}
`;

const Backdrop = styled.View`
  background: ${colors.grey4};
  ${fullscreenStyle}
`;

const BaseWrap = styled.View`
  flex: 1;
  background: ${colors.white};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
`;

const HandleWrap = styled.View`
  width: 100%;
  padding: 4px 0 6px;
  align-items: center;
`;

const Handle = styled.View`
  height: 4px;
  width: 44px;
  background: ${colors.grey4};
  opacity: 0.35;
  border-radius: 2px;
`;

const ChildrenWrap = styled.View`
  flex: 1;
`;

const PanelBase = ({
  children,
  headerButtons,
  expandedDefault = false,
  isVisible = false,
  topComponents,
  triggerExpansion = false,
  testID
}: PanelBaseProps) => {
  const { heightDifference, panelHeight, maxPanelHeight } = usePanelHeight();
  const dispatch = useDispatch();
  const isExpanded = useSelector(getPanelIsExpanded);

  const [baseShadow] = useState(new Animated.Value(0));
  const [isDragging, setIsDragging] = useState(false);
  const [panelExpansion] = useState(new Animated.Value(boolToNum(isExpanded)));
  const [renderBase, setRenderBase] = useState(false);
  const [visibility] = useState(new Animated.Value(0));
  const [backdropVisibility] = useState(new Animated.Value(boolToNum(isExpanded)));
  const { keyboardShown } = useKeyboard();

  const animatePanelExpansion = useCallback(
    (expand: boolean, duration?: number) => {
      Animated.timing(panelExpansion, {
        toValue: boolToNum(expand),
        easing: Easing.bezier(0.38, 0.9, 0.46, 1.1),
        duration: duration || 500,
        useNativeDriver: false
      }).start(() => {
        dispatch(setPanelIsExpanded(expand));
      });
    },
    [panelExpansion]
  );

  const toggleExpansion = useCallback((duration?: number) => animatePanelExpansion(!isExpanded, duration), [animatePanelExpansion, isExpanded]);

  const panResponder = useMemo(
    () =>
      PanResponder.create({
        onStartShouldSetPanResponder: () => false,
        onStartShouldSetPanResponderCapture: () => false,

        onMoveShouldSetPanResponder: (evt, gestureState) => !(gestureState.dx === 0 && gestureState.dy === 0),
        onMoveShouldSetPanResponderCapture: (evt, gestureState) => !(gestureState.dx === 0 && gestureState.dy === 0),

        onPanResponderGrant: () => setIsDragging(true),

        onPanResponderMove: (evt, gestureState) => {
          const startingPoint = !isExpanded ? 0 : heightDifference;
          const moveAmount = gestureState.y0 - gestureState.moveY;

          const scrollPos = startingPoint + moveAmount;

          Animated.timing(panelExpansion, {
            toValue: clamp(scrollPos / heightDifference, 0, 1),
            duration: 0,
            useNativeDriver: false
          }).start();
        },

        onPanResponderRelease: () => {
          setIsDragging(false);

          const { _value: expansionValue = 0 }: any = panelExpansion;
          const hasPassedThreshold = isExpanded ? expansionValue < 0.9 : expansionValue > 0.1;

          if (hasPassedThreshold) {
            toggleExpansion(200);
          } else {
            animatePanelExpansion(isExpanded, 150);
          }
        },

        onPanResponderTerminationRequest: () => true,
        onPanResponderTerminate: () => {
          setIsDragging(false);
          animatePanelExpansion(isExpanded, 150);
        },

        onShouldBlockNativeResponder: () => true
      }),
    [isExpanded, heightDifference, panelExpansion, toggleExpansion, animatePanelExpansion]
  );

  const backdropOuterStyles = useMemo(
    () => ({
      opacity: visibility
    }),
    [visibility]
  );

  const backdropStyles = useMemo(
    () => ({
      opacity: backdropVisibility.interpolate({
        inputRange: [0, 1],
        outputRange: [0.15, 0.15]
      })
    }),
    [backdropVisibility]
  );

  const heightViewStyles = useMemo(
    () => ({
      height: panelExpansion.interpolate({
        inputRange: [0, 1],
        outputRange: [panelHeight, maxPanelHeight],
        ...(isDragging ? { extrapolate: 'clamp' } : {})
      })
    }),
    [panelHeight, maxPanelHeight]
  );

  const visibilityViewStyles = useMemo(
    () => ({
      flex: 1,
      opacity: visibility.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1]
      })
    }),
    [visibility]
  );

  const baseWrapStyles = useMemo(
    () => ({
      shadowColor: colors.grey4,
      shadowOffset: {
        width: 0,
        height: baseShadow.interpolate({
          inputRange: [0, 1],
          outputRange: [-8, -8]
        })
      },
      shadowRadius: 10,
      shadowOpacity: baseShadow.interpolate({
        inputRange: [0, 1],
        outputRange: [0.1, 0.15]
      })
    }),
    [baseShadow]
  );

  const evaluatedTopComponents = useMemo(() => (topComponents ? topComponents() : null), [topComponents]);

  const onVisibilityChange = useCallback(() => {
    if (isVisible) {
      visibility.setValue(0);
      dispatch(setPanelIsExpanded(expandedDefault));
      panelExpansion.setValue(boolToNum(expandedDefault));

      setRenderBase(true);
    }

    Animated.timing(visibility, {
      toValue: boolToNum(isVisible),
      duration: isVisible ? 250 : 150,
      useNativeDriver: true
    }).start(() => {
      if (!isVisible && renderBase) {
        setRenderBase(false);
      }
    });
  }, [isVisible, visibility, expandedDefault, panelExpansion, renderBase]);

  useEffect(onVisibilityChange, [isVisible]);

  useEffect(() => {
    Animated.timing(baseShadow, {
      toValue: boolToNum(isDragging),
      duration: 150,
      useNativeDriver: false
    }).start();
  }, [baseShadow, isDragging]);

  const expandPanelCallback = useCallback(() => {
    if (renderBase) {
      animatePanelExpansion(true);
    }
  }, [animatePanelExpansion, renderBase]);

  useEffect(() => {
    if (keyboardShown) {
      expandPanelCallback();
    }
  }, [expandPanelCallback, keyboardShown]);

  useEffect(() => {
    if (triggerExpansion) {
      expandPanelCallback();
    }
  }, [expandPanelCallback, triggerExpansion]);

  useEffect(() => {
    Animated.timing(backdropVisibility, {
      toValue: boolToNum(isExpanded),
      duration: 200,
      useNativeDriver: false
    }).start();
  }, [backdropVisibility, isExpanded]);

  if (!renderBase) {
    return null;
  }

  return (
    <MainWrap testID={testID}>
      <BackdropOuter as={Animated.View} style={backdropOuterStyles}>
        <Backdrop as={Animated.View} style={backdropStyles} {...panResponder.panHandlers} />
      </BackdropOuter>

      <Animated.View style={heightViewStyles}>
        <Animated.View style={visibilityViewStyles}>
          <HeaderButtons buttons={headerButtons} onBackgroundPress={() => toggleExpansion()} {...panResponder.panHandlers} />

          <BaseWrap as={Animated.View} style={baseWrapStyles}>
            <View {...panResponder.panHandlers}>
              <TouchableWithoutFeedback onPress={() => toggleExpansion()}>
                <HandleWrap>
                  <Handle />
                </HandleWrap>
              </TouchableWithoutFeedback>

              {evaluatedTopComponents}
            </View>

            <ChildrenWrap>{children}</ChildrenWrap>
          </BaseWrap>
        </Animated.View>
      </Animated.View>
    </MainWrap>
  );
};

export default PanelBase;
