import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

import { isAndroid, isIos } from '@common/utils';
import { colors } from '@common/theme';

type LoadingOverlayProps = {
  id?: string;
  showBackdrop?: boolean;
  visible?: boolean;
  style?: any;
};

const Fixed = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const OuterWrap = styled(Fixed)`
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const Backdrop = styled(Fixed)`
  background: rgba(0, 0, 0, 0.2);
`;

const SpinnerWrap = styled.View`
  width: 60px;
  height: 60px;
  padding-left: ${isIos ? '2.5px' : 0};
  padding-top: ${isIos ? '2.5px' : 0};
  background: white;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  ${isAndroid &&
  css`
    elevation: 3;
    shadow-color: ${colors.black};
  `}
`;

const SPINNER_IN_OFFSET = 30;
const SPINNER_OUT_OFFSET = 20;

const LoadingOverlay = ({ id, showBackdrop = true, visible = false, ...props }: LoadingOverlayProps) => {
  const [backdropOpacity] = useState(new Animated.Value(0));
  const [renderComponent, setRenderComponent] = useState(false);
  const [spinnerOffset] = useState(new Animated.Value(SPINNER_IN_OFFSET));
  const [spinnerOpacity] = useState(new Animated.Value(0));

  const stopAnimations = useCallback(() => {
    backdropOpacity.stopAnimation();
    spinnerOffset.stopAnimation();
    spinnerOpacity.stopAnimation();
  }, [backdropOpacity, spinnerOffset, spinnerOpacity]);

  const showLoadingOverlay = useCallback(() => {
    stopAnimations();
    setRenderComponent(true);

    Animated.parallel([
      Animated.timing(backdropOpacity, {
        toValue: 1,
        duration: 100,
        useNativeDriver: false
      }),
      Animated.timing(spinnerOpacity, {
        toValue: 1,
        duration: 70,
        useNativeDriver: false
      }),
      Animated.spring(spinnerOffset, {
        toValue: 0,
        bounciness: 16,
        speed: 6,
        useNativeDriver: false
      })
    ]).start();
  }, [backdropOpacity, spinnerOffset, spinnerOpacity, stopAnimations]);

  const hideLoadingOverlay = useCallback(() => {
    stopAnimations();

    Animated.parallel([
      Animated.timing(spinnerOffset, {
        toValue: SPINNER_OUT_OFFSET,
        duration: 100,
        useNativeDriver: false
      }),
      Animated.timing(spinnerOpacity, {
        toValue: 0,
        duration: 75,
        useNativeDriver: false
      }),
      Animated.timing(backdropOpacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: false
      })
    ]).start(() => {
      setRenderComponent(false);
      spinnerOffset.setValue(SPINNER_IN_OFFSET);
    });
  }, [backdropOpacity, spinnerOffset, spinnerOpacity, stopAnimations]);

  useEffect(() => {
    if (visible) {
      showLoadingOverlay();
    } else {
      hideLoadingOverlay();
    }
  }, [hideLoadingOverlay, showLoadingOverlay, visible]);

  const backdropStyles = useMemo(() => ({ opacity: backdropOpacity }), [backdropOpacity]);

  const spinnerWrapStyles = useMemo(
    () => ({
      opacity: spinnerOpacity,
      transform: [{ translateY: spinnerOffset }]
    }),
    [spinnerOffset, spinnerOpacity]
  );

  if (renderComponent) {
    return (
      <OuterWrap {...props}>
        {showBackdrop && <Backdrop as={Animated.View} style={backdropStyles} />}

        <SpinnerWrap as={Animated.View} style={spinnerWrapStyles} testID={`loading-overlay-${id}`}>
          <ActivityIndicator size="large" color={colors.primary} />
        </SpinnerWrap>
      </OuterWrap>
    );
  }

  return null;
};

export default LoadingOverlay;
