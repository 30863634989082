import React, { useCallback, useEffect, useState } from 'react';
import { Animated, ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import { rgba } from 'polished';

import { boolToNum } from '@common/utils';
import { colors } from '@common/theme';
import rollioAvatar from '@common/images/ChatScreenImages/logo.png';

type BotAvatarProps = {
  isVisible?: boolean;
};

const BubbleAvatar = styled.View`
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 3px ${rgba(colors.grey4, 0.2)};
  border: solid 1px ${colors.primaryDark};
  border-radius: 9px;
  background: ${colors.primary};
  position: absolute;
  left: 4px;
  top: -9px;
`;

const AvatarImage = styled.Image.attrs<{ source?: ImageSourcePropType | undefined }>({
  source: rollioAvatar,
  resizeMode: 'cover'
})`
  width: 16px;
  height: 16px;
`;

const BotAvatar = ({ isVisible = false }: BotAvatarProps) => {
  const [opacity] = useState(new Animated.Value(0));
  const [vOffset] = useState(new Animated.Value(0));

  const stopAnimations = useCallback(() => {
    opacity.stopAnimation();
    vOffset.stopAnimation();
  }, [opacity, vOffset]);

  useEffect(() => {
    stopAnimations();

    const toValue = boolToNum(isVisible);
    const vOffsetAnimationType = isVisible ? 'spring' : 'timing';
    const duration = isVisible ? 250 : 150;

    Animated.parallel([
      Animated.timing(opacity, {
        toValue,
        duration,
        useNativeDriver: true
      }),
      Animated[vOffsetAnimationType](vOffset, {
        toValue,
        duration,
        friction: 3,
        useNativeDriver: true
      })
    ]).start();

    return () => {
      stopAnimations();
    };
  }, [isVisible, opacity, stopAnimations, vOffset]);

  const animatedAvatarStyle = {
    opacity,
    transform: [
      {
        translateY: vOffset.interpolate({
          inputRange: [0, 1],
          outputRange: isVisible ? [-10, 0] : [10, 0]
        })
      }
    ]
  };

  return (
    <BubbleAvatar as={Animated.View} style={animatedAvatarStyle}>
      <AvatarImage />
    </BubbleAvatar>
  );
};

export default BotAvatar;
