import recognizeMicrophone from 'watson-speech/speech-to-text/recognize-microphone';

import { pathOr } from '@common/utils';

class WatsonSTT {
  constructor() {
    this.isListening = false;
    this.stream = null;
  }

  isListening: boolean;
  stream: any;

  startStreaming = ({
    url,
    accessToken,
    callback,
    model
  }: {
    url: string;
    accessToken: string;
    callback: (a?: boolean, b?: string, c?: boolean) => void;
    model?: string;
  }) => {
    const stream = recognizeMicrophone({
      url,
      accessToken,
      objectMode: true,
      smartFormatting: true,
      xWatsonLearningOptOut: true,
      format: false,
      ...(model !== null ? { model } : {})
    });

    this.stream = stream;
    this.isListening = true;

    stream.on('data', (data: any) => {
      const isFinal = pathOr(true, ['results', 0, 'final'], data);
      if (this.isListening || isFinal) {
        const transcript = pathOr('', ['results', 0, 'alternatives', 0, 'transcript'], data);
        callback(false, transcript, !isFinal);
      }
    });

    stream.on('error', () => {
      callback(true, '');
    });
  };

  stopStreaming = () => {
    if (this.stream) {
      this.stream.stop();
    }

    this.isListening = false;
    this.stream = null;
  };
}

const SpeechToText: WatsonSTT = new WatsonSTT();

export { SpeechToText };
