import { type Observable, of } from 'rxjs';
import { debounceTime, filter, mergeMap } from 'rxjs/operators';

import { Action } from '@legacy/ducks';

import { clearUnselectedResults, prepareSearchScreen, resetPagination, search } from './actions';

export const searchOnTextChange = (action$: Observable<Action>) =>
  action$.pipe(
    filter(action => action.type === 'Search/CHANGE_TEXT_SEARCH'),
    debounceTime(500),
    mergeMap(() => of(clearUnselectedResults(), resetPagination(), search()))
  );

export const searchOnFilterChange = (action$: Observable<Action>) => {
  return action$.pipe(
    filter(action => ['Search/SET_FILTERS', 'Search/REMOVE_FILTER', 'Search/CHANGE_TAB'].includes(action.type)),
    mergeMap(() => of(clearUnselectedResults(), resetPagination(), search()))
  );
};

export const populateSearchOnMessagesPrepend = (action$: Observable<Action>): Observable<any> =>
  action$.pipe(
    filter(action => action.type === 'Messages/PREPEND'),
    mergeMap(() => of(prepareSearchScreen()))
  );
