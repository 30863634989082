import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { Animated, ActivityIndicator } from 'react-native';
import styled from 'styled-components/native';

import { isSpeechSupported } from '@common/utils';
import { colors } from '@common/theme';

import { isDefault, isSearch, TOOLBAR_MIN_HEIGHT, TOOLBAR_MAX_HEIGHT } from './shared';
import Wrap, { WrapProps } from './Wrap';
import Input, { InputProps } from './Input';
import ButtonArea from './ButtonArea';
import { ToolbarContext } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';

export type ActionToolbarProps = {
  disableMicButton?: boolean;
  disableSendButton?: boolean;
  inputPlaceholder?: string;
  inputProps?: Partial<InputProps>;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onMicPress?: (opts?: any) => void;
  onSearchPress?: () => void;
  onSendPress?: () => void;
  onStopPress?: () => void;
} & Omit<WrapProps, 'animatedHeight' | 'children' | 'variant'>;

const LoadingIndicatorWrap = styled.View`
  margin-right: 12px;
  justify-content: center;
  height: ${TOOLBAR_MIN_HEIGHT}px;
`;

const ActionToolbar = ({
  disableMicButton = false,
  disableSendButton = false,
  inputValue = '',
  inputProps = {},
  onInputChange = () => {},
  onMicPress = () => {},
  onSearchPress = () => {},
  onSendPress = () => {},
  onStopPress = () => {},
  ...props
}: ActionToolbarProps) => {
  const [toolbarHeight] = useState(new Animated.Value(TOOLBAR_MIN_HEIGHT));
  const { variant, isListening, animatedHeight, isLoading } = useContext(ToolbarContext);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showSecondarySend, setShowSecondarySend] = useState(false);
  const [showSecondaryMic, setShowSecondaryMic] = useState(false);
  const [secondarySendVisibility] = useState(new Animated.Value(0));

  const isPopulated = !!inputValue && inputValue.trim().length > 0;

  const isMainButtonDisabled = useMemo(() => {
    if (!isListening) {
      return disableMicButton || disableSendButton;
    }

    return false;
  }, [disableMicButton, disableSendButton, isListening]);

  const isSecondaryButtonDisabled = useMemo(() => !isPopulated || disableSendButton, [disableSendButton, isPopulated]);

  const onInputSubmitEditing = useCallback(() => {
    if (isSearch(variant)) {
      onSearchPress();
      return;
    }

    if (isDefault(variant) && isPopulated) {
      onSendPress();
    }
  }, [isPopulated, onSearchPress, onSendPress, variant]);

  const onButtonPress = useCallback(() => {
    if (isAnimating) {
      return;
    }

    if (isListening) {
      onStopPress();
      return;
    }

    if (!disableSendButton && isPopulated) {
      if (isSearch(variant)) {
        onSearchPress();
      } else {
        onSendPress();
      }

      return;
    }

    if (!disableMicButton && isSpeechSupported) {
      onMicPress();
    }
  }, [disableMicButton, disableSendButton, isAnimating, isListening, isPopulated, onMicPress, onSearchPress, onSendPress, onStopPress, variant]);

  useLayoutEffect(() => {
    setIsAnimating(true);

    if (isListening) {
      setShowSecondarySend(true);
    }

    if (isListening || !isPopulated) {
      setShowSecondaryMic(false);
    }

    toolbarHeight.setValue(animatedHeight);

    Animated.timing(secondarySendVisibility, {
      toValue: isListening ? 1 : 0,
      delay: isListening ? 60 : 0,
      duration: isListening ? 120 : 80,
      useNativeDriver: true
    }).start(() => {
      if (!isListening) {
        setShowSecondarySend(false);
        isPopulated && setShowSecondaryMic(true);
      }

      setIsAnimating(false);
    });
  }, [isListening, secondarySendVisibility, animatedHeight, toolbarHeight, isPopulated]);

  const onSend = useCallback(() => {
    if (isSearch(variant) && isListening) {
      onStopPress();
    } else {
      onSendPress();
    }
  }, [onSendPress, variant, isListening]);

  return (
    <Wrap animatedHeight={toolbarHeight} variant={variant} {...props}>
      <Input {...inputProps} testID="chat-text-input" onChange={onInputChange} onInputSubmit={onInputSubmitEditing} value={inputValue} />
      <LoadingIndicatorWrap>
        <ActivityIndicator animating={isLoading} color={colors.grey5} />
      </LoadingIndicatorWrap>
      <ButtonArea
        animatedSendVisibility={secondarySendVisibility}
        disableMainButton={isMainButtonDisabled}
        disableSecondaryButton={isSecondaryButtonDisabled}
        isInputPopulated={isPopulated}
        onButtonPress={onButtonPress}
        onSendPress={onSend}
        onMicPress={onMicPress}
        showSend={showSecondarySend}
        showSecondaryMic={showSecondaryMic}
        testID="send-button"
      />
    </Wrap>
  );
};

export default ActionToolbar;

export { TOOLBAR_MAX_HEIGHT, TOOLBAR_MIN_HEIGHT };
