import { pathOr } from '@common/utils';
import { State } from '@legacy/ducks';

export const getSelectedTabId = (state: State): number => pathOr(0, ['search', 'selectedTab'], state);
export const getTabHeaders = ({ search }: State): TabHeadersShape => search.tabs.map(({ header }) => header);
export const getTabNames = ({ search }: State): TabHeadersShape => search.tabs.map(tab => tab.objectName);
export const getSearchResults = ({ search }: State): ResultsShape => pathOr([], ['results', search.selectedTab], search);
export const getInitialResults = ({ search }: State): ResultsShape => pathOr([], ['initialResults', search.selectedTab], search);
export const getSelectedSearchResults = (state: State): ResultsShape => getSearchResults(state).filter(({ isSelected }) => !!isSelected);
export const getUnselectedSearchResults = ({ search }: State): ResultsShape => pathOr([], ['unselectedResults', search.selectedTab], search);
export const getAreSelectedRows = (state: State): boolean => !!getSelectedSearchResults(state).length;
export const getAreUnselectedRows = (state: State): boolean => !!getUnselectedSearchResults(state).length;
export const getFilters = ({ search }: State): FilterGroupShape => pathOr([], ['filters', search.selectedTab], search);
export const getAreMore = ({ search }: State): boolean => pathOr(false, ['areMore', search.selectedTab], search);
export const getTextSearch = ({ search }: State): string => pathOr('', ['textSearch', search.selectedTab], search);
export const getCurrentPage = ({ search }: State): number => pathOr(0, ['currentPage', search.selectedTab], search);
export const getIsLoading = ({ search }: State): boolean => pathOr(false, ['isLoading', search.selectedTab], search);
export const getPanelIsExpanded = ({ search }: State): boolean => pathOr(false, ['isExpanded'], search);

const getSelectedTab = ({ search }: State): TabShape => pathOr({} as TabShape, ['tabs', search.selectedTab], search);
// TODO: multi_select_hierarchy is DEPRECATED
export const getIsMultiSelection = (state: State): boolean => {
  const selectedTab = getSelectedTab(state);
  const type = pathOr<string>('', ['type'], selectedTab);

  return type === 'multi_select_hierarchy' || type === 'multi_lookup';
};

export const getActiveFiltersByDefault = (state: State): FilterGroupShape => {
  const filters = pathOr([], ['filters'], getSelectedTab(state));
  return filters.filter(({ isActiveByDefault = false }) => isActiveByDefault);
};
