import React from 'react';
import styled, { css } from 'styled-components/native';
import parse from 'html-react-parser';
import RenderHtml from 'react-native-render-html';

import { colors, fonts } from '@common/theme';
import { convertToDateFrom, filterHiddenConfirmationValues, isWeb, toDataTestIdFriendly } from '@common/utils';
import { BotAvatar, Icon, FormatDate } from '@common/components';
import { useCustomReplaceValue, useSelector } from '@common/hooks';
import { getTimeFormat } from '@common/store/reducers/user';

import TableFooterButtons from './TableFooterButtons';
import MessageFooter from '../../ChatScreen/Message/ChatBubble/Bubble/BubbleBase/MessageFooter';

type TableRowValue = string | boolean | Postback;

type TableData = Array<{
  name: string;
  type: FieldTypes;
  value: TableRowValue;
}>;

type TableTypeBProps = {
  tableTitle: string;
  tableData: TableData;
  buttonsArray: Array<Button>;
  showAvatar?: boolean;
  tableCreationTime: Date;
  hideTime?: boolean;
  buttonPress: (button: Button, tableData: TableData) => void;
};

const TableOuter = styled.View`
  padding-top: 12px;
  max-width: 94%;
`;

const TableWrap = styled.View`
  background: ${colors.grey};
  border-radius: 8px;
`;

const HeaderWrap = styled.View`
  flex-direction: row;
  padding: 12px 10px;
`;

const HeaderTitle = styled.Text`
  color: ${colors.primary};
  font-family: ${fonts.bold};
  font-size: 13px;
  flex: 1;
  padding-right: 10px;
`;

const HeaderRight = styled.Text`
  color: ${colors.grey4};
  font-family: ${fonts.regular};
  font-size: 12px;
`;

const TableRowsOuter = styled.View`
  background: ${colors.white};
  border-radius: 4px;
  margin: 0 5px 5px;
`;

type RowWrapProps = {
  isLast: boolean;
};

const RowWrap = styled.View<RowWrapProps>(
  ({ isLast }) => css`
    flex-direction: row;
    padding: 8px 5px;
    border-color: ${colors.grey};
    border-bottom-width: ${!isLast ? '1px' : 0};
  `
);

const RowTitle = styled.Text`
  width: 30%;
  font-family: ${fonts.medium};
  font-size: 13px;
  text-align: right;
  margin-right: 10px;
  color: ${colors.grey4};
  padding-top: 1px;
`;

const RowTextValue = styled.Text`
  flex: 1;
  font-family: ${fonts.medium};
  font-size: 15px;
  color: ${colors.grey4};
`;

const FooterMeta = styled(MessageFooter)`
  justify-content: flex-end;
`;

type TableHeaderProps = { title: string; date: Date };

const TableHeader = ({ title, date }: TableHeaderProps) => {
  const timeFormat = useSelector(getTimeFormat);

  const titleUpper = title.toUpperCase();
  const formattedDate = convertToDateFrom(date, timeFormat);

  return (
    <HeaderWrap testID="table-b-title">
      <HeaderTitle>{titleUpper}</HeaderTitle>
      <HeaderRight>{formattedDate}</HeaderRight>
    </HeaderWrap>
  );
};

type RowValueProps = {
  type: string;
  value: TableRowValue;
  testID: string;
};

const RowValue = ({ type, value, testID }: RowValueProps) => {
  if (type === 'Checkbox') {
    // Parsing only true boolean values, everything else is false.
    let isChecked = false;
    if (value === 'true' || value === 'True') {
      isChecked = true;
    } else if (typeof value === 'boolean') {
      isChecked = value;
    }

    const name = isChecked ? 'check-box' : 'check-box-outline-blank';
    return <Icon name={name} size={20} color={colors.primary} testID={testID} />;
  }

  if (type === 'Date' || type === 'DateTime') {
    return (
      <RowTextValue testID={testID}>
        <FormatDate type={type} value={value} />
      </RowTextValue>
    );
  }

  const val = typeof value === 'object' ? value?.messageText : value ?? '';

  if (type === 'RichTextArea') {
    return <RowTextValue testID={testID}>{isWeb ? parse(val as string) : <RenderHtml source={{ html: val as string }} />}</RowTextValue>;
  }

  return <RowTextValue testID={testID}>{val}</RowTextValue>;
};

const TableRows = ({ data }: { data: TableData }) => {
  const removedHiddenValues = data.filter(filterHiddenConfirmationValues);
  const lastIndex = removedHiddenValues.length - 1;
  const replaceValue = useCustomReplaceValue();

  return (
    <>
      {removedHiddenValues.map(({ name, value, type }, index) => {
        const isLast = index === lastIndex;
        const parsedValue = replaceValue(value, type);
        return (
          <RowWrap key={name} isLast={isLast}>
            <RowTitle>{name}</RowTitle>
            <RowValue type={type} value={parsedValue} testID={`table-type-b-${toDataTestIdFriendly(name)}-value`} />
          </RowWrap>
        );
      })}
    </>
  );
};

const FooterContainer = styled.View`
  margin-left: 10px;
`;

const TableTypeB = ({ tableTitle, tableData, tableCreationTime, buttonsArray, buttonPress, showAvatar = false, hideTime }: TableTypeBProps) => {
  const showFooterButtons = buttonsArray && !!buttonsArray.length;

  return (
    <TableOuter>
      <TableWrap>
        <TableHeader title={tableTitle} date={tableCreationTime} />

        <TableRowsOuter>
          <TableRows data={tableData} />
        </TableRowsOuter>

        {showAvatar && <BotAvatar isVisible={showAvatar} />}
      </TableWrap>

      <FooterContainer>
        <FooterMeta id="table-type-b" time={hideTime ? undefined : tableCreationTime} />
      </FooterContainer>

      {showFooterButtons && <TableFooterButtons buttons={buttonsArray} onButtonPress={button => buttonPress(button, tableData)} />}
    </TableOuter>
  );
};

export default TableTypeB;
