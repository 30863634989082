import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import { Animated, Dimensions, ImageSourcePropType, NativeScrollEvent } from 'react-native';
import styled from 'styled-components/native';
import { StyledScrollView } from 'styled-components/types';

import { colors } from '@common/theme';
import { clamp, isIos, isIphoneX, isTablet, iphoneXHomeBarHeight, isWeb } from '@common/utils';
import { Button, BUTTON_HEIGHT } from '@common/components';

import Pagination from './Pagination';
import SliderStep from './SliderStep';

type WalkthroughSliderProps = {
  onContinuePress: () => void;
  onSkipPress: () => void;
  skipButtonLabel?: string;
  steps: Array<{
    description: Array<string | ReactElement>;
    image: ImageSourcePropType;
    title: string;
    showContinueButton?: boolean;
  }>;
};

const deviceWidth = Dimensions.get('window').width;

const Backdrop = styled.View`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: ${!isTablet ? '50%' : '36%'};
  background: ${colors.primary};
  border-bottom-left-radius: ${deviceWidth * 0.25}px;
  border-bottom-right-radius: ${deviceWidth * 0.25}px;
`;

const ScrollView = styled.ScrollView`
  flex: 1;
`;

const footerButtonsOffset = isIphoneX ? iphoneXHomeBarHeight + 25 : 15;

const FooterButtons = styled.View`
  position: absolute;
  bottom: ${footerButtonsOffset}px;
  left: 0;
  right: 0;
  padding: 0 12px;
  flex-direction: row;
  justify-content: space-between;
`;

const WalkthroughSlider = ({ onContinuePress, onSkipPress, skipButtonLabel = 'Skip', steps }: WalkthroughSliderProps) => {
  const scrollViewRef = useRef<StyledScrollView>(null);
  const [scrollX, setScrollX] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [animatedScrollX] = useState(new Animated.Value(0));
  const animatedActivePage = Animated.divide(animatedScrollX, deviceWidth);
  const stepCount = useMemo(() => steps.length, [steps]);
  const lastPageIndex = useMemo(() => stepCount - 1, [stepCount]);

  const calculateActivePage = useCallback(() => {
    const currentPage = Math.round(scrollX / deviceWidth);
    setActivePage(currentPage);
  }, [scrollX]);

  const onNextButtonPress = useCallback(() => {
    if (scrollViewRef && scrollViewRef.current) {
      const currentPage = Math.round(scrollX / deviceWidth);
      const targetPage = clamp(currentPage + 1, 0, lastPageIndex);
      if (!isIos) {
        setActivePage(targetPage);
      }

      if (targetPage !== currentPage) {
        const xAxis = targetPage * deviceWidth;
        if (isWeb && scrollViewRef.current._component) {
          scrollViewRef.current._component.scrollTo({ x: xAxis });
        } else {
          scrollViewRef.current.scrollTo({ x: xAxis });
        }
      }
    }
  }, [lastPageIndex, scrollX, scrollViewRef]);

  const lastSlideProps = useMemo(
    () => ({
      activePage,
      lastPage: lastPageIndex,
      onContinuePress
    }),
    [activePage, lastPageIndex, onContinuePress]
  );

  const footerButtonsStyles = useMemo(
    () => ({
      transform: [
        {
          translateY: animatedActivePage.interpolate({
            inputRange: [lastPageIndex - 1, lastPageIndex],
            outputRange: [0, footerButtonsOffset + BUTTON_HEIGHT],
            extrapolate: 'clamp'
          })
        }
      ],
      opacity: animatedActivePage.interpolate({
        inputRange: [lastPageIndex - 1, lastPageIndex],
        outputRange: [1, 1],
        extrapolate: 'clamp'
      })
    }),
    [animatedActivePage, lastPageIndex]
  );

  return (
    <>
      <Backdrop />

      <ScrollView
        ref={scrollViewRef}
        as={Animated.ScrollView}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onScroll={Animated.event<NativeScrollEvent>(
          [
            {
              nativeEvent: {
                contentOffset: {
                  x: animatedScrollX
                }
              }
            }
          ],
          {
            useNativeDriver: true,
            listener: event => setScrollX(event.nativeEvent.contentOffset.x)
          }
        )}
        onMomentumScrollEnd={calculateActivePage}
        scrollEventThrottle={1}
      >
        {steps.map(({ title, image, description, showContinueButton = false }, i) => {
          const buttonSlideProps = {
            ...lastSlideProps,
            showContinueButton
          };

          return (
            <SliderStep
              key={title}
              animatedActivePage={animatedActivePage}
              description={description}
              image={image}
              page={i}
              title={title}
              {...(showContinueButton ? buttonSlideProps : {})}
            />
          );
        })}
      </ScrollView>

      <FooterButtons as={Animated.View} style={footerButtonsStyles}>
        <Button variant="grey" onPress={onSkipPress} trackEventId="walkthrough-close-click">
          {skipButtonLabel}
        </Button>

        <Button onPress={onNextButtonPress} trackEventId="walkthrough-next-click">
          Next
        </Button>
      </FooterButtons>

      <Pagination activePage={animatedActivePage} dotCount={stepCount} />
    </>
  );
};

export default WalkthroughSlider;
