import React from 'react';
import styled from 'styled-components/native';

import { toDataTestIdFriendly } from '@common/utils';
import { Button } from '@common/components';

type TableFooterButtonsProps = {
  buttons: Array<Button>;
  onButtonPress: (buttton: Button) => void;
};

const TableFooterButtonsWrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 8px;
`;

const FooterButton = styled(Button)`
  margin-right: 6px;
  margin-bottom: 6px;
`;

const getButtonVariant = (isCancel: boolean = false, isEdit: boolean = false): ButtonVariant | undefined => {
  if (isEdit || isCancel) {
    return 'light';
  }

  return undefined;
};

const getTrackEventId = (variant?: ButtonVariant, friendlyLabel?: string) => {
  if (variant !== 'light') {
    return 'chat_table_confirm_click';
  }
  return `chat_table_${friendlyLabel}_click`;
};

const TableFooterButtons = ({ buttons = [], onButtonPress }: TableFooterButtonsProps) => (
  <TableFooterButtonsWrap>
    {buttons.map(item => {
      const { text = '', isCancel, openEditScreen } = item;
      const variant = getButtonVariant(isCancel, openEditScreen);
      const onPress = () => onButtonPress(item);
      const friendlyLabel = toDataTestIdFriendly(text);
      const trackEventId = getTrackEventId(variant, friendlyLabel);

      return (
        <FooterButton key={text} onPress={onPress} variant={variant} testID={`${friendlyLabel}-footer-button`} trackEventId={trackEventId}>
          {text}
        </FooterButton>
      );
    })}
  </TableFooterButtonsWrap>
);

export default TableFooterButtons;
