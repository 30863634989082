import React from 'react';
import { render } from 'react-dom';

type ButtonType = {
  text: string;
  style?: 'cancel';
  onPress?: () => null;
};
type OptionsType = {
  cancelable?: boolean;
};

type WebAlertProps = {
  title: string;
  message?: string;
  buttons?: ButtonType[];
  options?: OptionsType;
};

const createElementAlert = (props: WebAlertProps) => {
  const WebAlert = require('@common/components/WebAlert').default;
  let target = document.getElementById('rollio-alert');
  if (!target) {
    target = document.createElement('div');
    target.id = 'rollio-alert';
    document.body.appendChild(target);
  }
  render(<WebAlert {...props} />, target);
};

class AlertStatic {
  alert = (title: string, message?: string, buttons?: ButtonType[], options?: OptionsType) => {
    createElementAlert({ title, message, buttons, options });
  };
}

const Alert = new AlertStatic();

export default Alert;
