import { pathOr } from '@common/utils';
import { State } from '@legacy/ducks';

export const getVisibleOptions = ({ picklist }: State): Array<Button> => {
  const { options, searchText } = picklist;

  if (searchText) {
    const lcSearchText = searchText.toLowerCase();
    return options.filter(({ text }) => (text || '').toLowerCase().includes(lcSearchText));
  }

  return options;
};

export const getSearchText = (state: State): string => pathOr('', ['picklist', 'searchText'], state);

export const getSelectedOptions = (state: State): Array<string> => pathOr([], ['picklist', 'selectedOptions'], state);
