import { Dispatch } from 'redux';

import { isWeb, messageTypes, pathOr } from '@common/utils';
import { navigate } from '@common/navigation/utils';
import { Action, State } from '@legacy/ducks';
import { postMessage } from '@legacy/ducks/messages/actions';
import { getLatestMessage, getLatestMessageType } from '@legacy/ducks/messages/selectors';
import { WEBVIEW_SCREEN } from '@common/navigation/routes';

// Action types
type PicklistPrepopulateResults = {
  type: 'Picklist/PREPOPULATE_RESULTS';
  payload: Array<ResultsShape>;
};

type PicklistUpdateSearch = {
  type: 'Picklist/UPDATE_SEARCH';
  payload: string;
};

type PicklistUpdateSelectedOptions = {
  type: 'Picklist/UPDATE_SELECTED_OPTIONS';
  payload: Array<string>;
};

type PicklistReset = {
  type: 'Picklist/RESET';
};

export type PicklistActions = PicklistPrepopulateResults | PicklistUpdateSearch | PicklistUpdateSelectedOptions | PicklistReset;

// Action creators
const prepopulateResults: (payload: Array<ResultsShape>) => Action = (results): PicklistPrepopulateResults => ({
  type: 'Picklist/PREPOPULATE_RESULTS',
  payload: results
});

export const updateSearch: (payload: string) => Action = (searchTerm): PicklistUpdateSearch => ({
  type: 'Picklist/UPDATE_SEARCH',
  payload: searchTerm
});

const updateSelectedOptions: (payload: Array<string>) => Action = (options): PicklistUpdateSelectedOptions => ({
  type: 'Picklist/UPDATE_SELECTED_OPTIONS',
  payload: options
});

export const resetPicklist: () => Action = (): PicklistReset => ({
  type: 'Picklist/RESET'
});

// Thunks
export const preparePicklistData = () => (dispatch: Dispatch, getState: () => State) => {
  const state = getState();
  const latestMessageType = getLatestMessageType(state);

  if ([messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(latestMessageType)) {
    const latestMessage = getLatestMessage(state);
    const options = pathOr([], ['options'], latestMessage);

    dispatch(prepopulateResults(options));
  }
};

export const selectPicklistOption = (option: Button) => (dispatch: Dispatch<any>, getState: () => State) => {
  const state = getState();
  const isMultiSelect = getLatestMessageType(state) === messageTypes.MULTI_PICKLIST;

  if (state.picklist.searchText) {
    dispatch(updateSearch(''));
  }

  if (isMultiSelect) {
    const { selectedOptions } = state.picklist;

    if (selectedOptions.includes(option.text)) {
      const updatedOptions = selectedOptions.filter(id => id !== option.text);
      dispatch(updateSelectedOptions(updatedOptions));
    } else {
      dispatch(updateSelectedOptions([...selectedOptions, option.text]));
    }

    return;
  }

  if (option.url) {
    if (!isWeb) {
      navigate({
        name: WEBVIEW_SCREEN,
        params: {
          url: option.url,
          header: option.text
        }
      });
    } else {
      window.open(option.url, '_blank');
    }

    return;
  }

  if (option.postback) {
    dispatch(postMessage(option.postback));
    dispatch(resetPicklist());
  }
};
