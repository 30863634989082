import { webStorageKeys } from '@common/utils';

export const getGenericPassword = () => {};
export const setGenericPassword = () => {};
export const resetGenericPassword = () => {};
export const saveLoginToKeychain = (save: boolean, username: string) => {
  if (save) {
    localStorage.setItem(webStorageKeys.loginEmail, username);
  } else {
    localStorage.removeItem(webStorageKeys.loginEmail);
  }
};
