import React, { useEffect, useRef } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import { useDispatch, useSelector } from '@common/hooks';
import { selectIsLoggedIn } from '@common/store/reducers/auth';
import { Navigator, AuthNavigator } from '@common/navigation';
import { navigationRef } from '@common/navigation/utils';
import { LOADING_SCREEN, LOGIN_SCREEN, WALKTHROUGH_SCREEN } from '@common/navigation/routes';
import { trackEvent } from '@common/utils';
import { uiSetStatusBarStyle } from '@common/store/reducers/ui';

import { name as appName } from '../../../app.json';

const AppRoute = () => {
  const routeNameRef = useRef<string>();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      navigationRef.navigate(LOADING_SCREEN);
    } else {
      navigationRef.navigate(LOGIN_SCREEN);
    }
  }, [isLoggedIn]);

  return (
    <NavigationContainer
      ref={navigationRef}
      documentTitle={{
        formatter: () => appName
      }}
      onReady={() => {
        routeNameRef.current = navigationRef.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const routeName = navigationRef.getCurrentRoute()?.name;
        if (routeName && routeNameRef.current !== routeName) {
          trackEvent('event_screen', { routeName });

          const barStyle = [
            // PROFILE_SCREEN,
            WALKTHROUGH_SCREEN
          ].includes(routeName)
            ? 'light-content'
            : 'dark-content';

          dispatch(uiSetStatusBarStyle(barStyle));

          routeNameRef.current = routeName;
        }
      }}
    >
      {isLoggedIn ? <Navigator /> : <AuthNavigator />}
    </NavigationContainer>
  );
};

export default AppRoute;
