import React from 'react';
import styled from 'styled-components/native';

import TabButton from './Tab';

export type TabsProps = {
  activeTabId: number;
  onTabPress: (id: number) => void;
  tabs: Array<{ label: string; id: number }>;
};

const TabsWrap = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
`;

const Tabs = ({ activeTabId, onTabPress, tabs }: TabsProps) => (
  <TabsWrap>
    {tabs.map(({ label, id }) => (
      <TabButton active={activeTabId === id} key={id} onPress={() => onTabPress(id)}>
        {label}
      </TabButton>
    ))}
  </TabsWrap>
);

export default Tabs;
