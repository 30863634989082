import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { NotificationsScreen } from '@legacy/app/layouts';
import { NOTIFICATIONS_SCREEN } from '@legacy/app/navigation/routes';
import { NotificationsTabParamList } from './types';

const Stack = createStackNavigator<NotificationsTabParamList>();

const NotificationsStack = () => (
  <Stack.Navigator initialRouteName={NOTIFICATIONS_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={NOTIFICATIONS_SCREEN} component={NotificationsScreen} />
  </Stack.Navigator>
);

export default NotificationsStack;
