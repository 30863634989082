import React, { Key } from 'react';
import { Linking, Text } from 'react-native';
import styled from 'styled-components/native';

import { isWeb, pathOr, toDataTestIdFriendly } from '@common/utils';
import { Button } from '@common/components';

type QuickReplyProps = {
  message: QuickReplyMessage;
  sendMessage: (postback: Postback) => void;
};

const Wrap = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6px;
  padding-left: 8px;
`;

const ResponseButton = styled(Button)`
  margin-right: 6px;
  margin-bottom: 6px;
`;

const createCommonResponseButton = (text: string, onPress: any, key: Key, type: 'button' | 'option', style: ButtonVariant) => {
  const buttonType = {
    button: {
      testId: `message-button-${toDataTestIdFriendly(text)}`,
      trackEventId: 'chat_button_click'
    },
    option: {
      testId: `${toDataTestIdFriendly(text)}-quick-reply`,
      trackEventId: 'chat_quicklink_click'
    }
  };

  return (
    <ResponseButton variant={style} testID={buttonType[type].testId} key={key} onPress={onPress} trackEventId={buttonType[type].trackEventId}>
      <Text>{text}</Text>
    </ResponseButton>
  );
};

const createOptionButton = (text: string, index: Key, postback: Postback, sendMessage: (postback: Postback) => void, isCancel: boolean, isAction?: string) => {
  const key = `link_${index}`;
  const match = text.match(/href="(.*)".*>(.*)</);
  let style;
  if (isCancel) {
    style = 'gray';
  } else if (!isAction) {
    style = 'light';
  }

  if (!match || match.length < 3) {
    return createCommonResponseButton(text, () => sendMessage(postback), key, 'option', style as ButtonVariant);
  }

  return createCommonResponseButton(
    match[2],
    isWeb
      ? () => {
          window.open(match[1], '_top');
          sendMessage(postback);
        }
      : () => {
          Linking.openURL(match[1]);
          sendMessage(postback);
        },
    key,
    'option',
    style as ButtonVariant
  );
};

const QuickReply = ({ message, sendMessage }: QuickReplyProps) => {
  const options = pathOr([], ['options'], message);
  const buttons = pathOr([], ['buttons'], message);

  return (
    <Wrap>
      {options.map(({ postback, text, isCancel, isAction }, index) => createOptionButton(text, index, postback, sendMessage, isCancel, isAction))}
      {buttons.map(({ postback, text }, index) => createCommonResponseButton(text, () => sendMessage(postback), `${index}_${text}`, 'button', 'grey'))}
    </Wrap>
  );
};

export default QuickReply;
