import React, { useCallback, useMemo } from 'react';
import { FlatList, Keyboard } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '@common/theme';

import TableColumnsHeader from './TableColumnsHeader';
import TableDataRow from './TableDataRow';

export type TableTypeCProps = {
  listProps?: Object;
  onEndReached?: () => void;
  onRowPress: (tableRows: TableRowsShape, index: number) => void;
  tableRows: TableRowsShape;
};

const ContainerWrap = styled.View`
  flex: 1;
  background: ${colors.white};
`;

const TableRows = styled.FlatList`
  flex: 1;
` as unknown as typeof FlatList;

const TableTypeC = ({ listProps = {}, onEndReached = () => {}, onRowPress, tableRows }: TableTypeCProps) => {
  const [firstRow] = tableRows;

  const tableColHeaders = firstRow ? firstRow.tableData.map(({ name = '' }) => name) : [];

  const onTableRowPress = (index: number) => {
    Keyboard.dismiss();
    onRowPress(tableRows, index);
  };

  const renderItem = useCallback(
    ({ item: { id, tableData: rowData = [], isSelected = false }, index }) => {
      const formattedRowData = rowData.map(({ value = '' }) => value);
      return <TableDataRow id={id} onPress={() => onTableRowPress(index)} selected={isSelected} values={formattedRowData} />;
    },
    [tableRows]
  );

  const keyExtractor = useCallback(row => row.id, []);

  const extraData = useMemo(() => JSON.parse(JSON.stringify(tableRows)), [tableRows]);

  return (
    <ContainerWrap>
      {!!tableRows.length && !!tableColHeaders.length && <TableColumnsHeader headers={tableColHeaders} />}
      <TableRows<TableRow>
        {...listProps}
        onEndReachedThreshold={0.01}
        onEndReached={onEndReached}
        extraData={extraData}
        keyboardShouldPersistTaps="always"
        data={tableRows.sort((a, b) => Number(!!b.isSelected) - Number(!!a.isSelected))}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
      />
    </ContainerWrap>
  );
};

export default TableTypeC;
