import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useSelector as useCommonSelector } from '@common/hooks';
import { apiInterceptor } from '@common/store/interceptors';
import { getToken } from '@common/store/reducers/auth';
import { omit } from '@common/utils';

import { getSelectedTabId, getSearchResults, getTabHeaders } from '@legacy/ducks/search/selectors';
import { getIsSearching, getLookupField } from '@legacy/ducks/edition/selectors';
import { lookup } from '@legacy/webService';
import { prepareSearch, resetSearch, toggleUnselected } from '@legacy/ducks/search/actions';
import { resetEdition, editLookup, editContextObject, searchEdition, setLookupField } from '@legacy/ducks/edition/actions';

const useSearchLookup = () => {
  // Dispatcher (legacy)
  const dispatch = useDispatch();
  const searchFilters = {
    filters: [],
    onRemovePress: (_filter: { value: string; label: string }) => {},
    visible: false
  };
  const isSearching = useSelector(getIsSearching);
  const [searchPanelVisible, setSearchPanelVisible] = useState(false);
  // Selectors
  const tableData = useSelector(getSearchResults);
  const tabHeaders = useSelector(getTabHeaders);
  const selectedTab = useSelector(getSelectedTabId);
  const lookupField = useSelector(getLookupField);
  // Common selector
  const token = useCommonSelector(getToken);

  const triggerSearch = useCallback(
    async (field: FieldType) => {
      try {
        // Dispatch
        dispatch(searchEdition(true));
        dispatch(editLookup(true));
        dispatch(editContextObject((field?.objectType || field?.entity) ?? ''));
        const _field = omit(['crossTableId', 'crossTableIdToDelete', 'idsAlreadySelected'], field);
        dispatch(setLookupField(_field));

        const result: LookupTableRequest = await apiInterceptor(lookup)(field, token);
        if (result?.lookupTableData && result?.status) {
          let { rows, tabs } = result.lookupTableData;

          if (field?.type === 'MultiLookup') {
            const updatedResults =
              rows?.[selectedTab]?.map(data => {
                if ((!field.id && data.postback.fields?.[0]?.crossTableId) || field.id?.split(',').some(id => id === data.id && !data.isSelected)) {
                  data.isSelected = true;
                }

                if (!data.isSelected && data.postback.fields?.[0]?.crossTableId) {
                  dispatch(toggleUnselected(data));
                }

                return data;
              }) ?? [];

            rows.splice(selectedTab, 1, updatedResults);
          }

          dispatch(prepareSearch(tabs, selectedTab, rows));
          setSearchPanelVisible(true);
        } else {
          dispatch(editLookup(false));
        }

        dispatch(searchEdition(false));
      } catch {
        cancelSearch();
      }
    },
    [token, dispatch, prepareSearch, setSearchPanelVisible, searchEdition, apiInterceptor, setLookupField, editContextObject, editLookup]
  );

  const cancelSearch = useCallback(() => {
    setSearchPanelVisible(false);
    dispatch(resetEdition());
    dispatch(resetSearch());
  }, []);

  return {
    searchPanelVisible,
    setSearchPanelVisible,
    cancelSearch,
    isSearching,
    selectedTab,
    tableData,
    tabHeaders,
    searchFilters,
    triggerSearch,
    lookupField
  };
};

export default useSearchLookup;
