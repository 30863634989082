import { StatusBarStyle } from 'react-native';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@common/store';

interface UIState {
  focusToolbarInput?: boolean;
  isTabBarMinimized: boolean;
  lastBotMessageHeight?: number;
  scrollChat?: boolean;
  statusBarStyle: StatusBarStyle;
}

const initialState: UIState = {
  isTabBarMinimized: false,
  lastBotMessageHeight: 0,
  statusBarStyle: 'default'
};

// Slice
export const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    uiFocusToolbarInput: (state, action) => {
      state.focusToolbarInput = action.payload;
    },
    uiScrollChatToBottom: (state, action) => {
      state.scrollChat = action.payload;
    },
    uiSetStatusBarStyle: (state, action) => {
      state.statusBarStyle = action.payload;
    },
    uiSetLastBotMessageHeight: (state, action) => {
      state.lastBotMessageHeight = action.payload;
    },
    toggleIsTabBarMinimized: state => {
      state.isTabBarMinimized = !state.isTabBarMinimized;
    },
    resetUI: () => initialState
  }
});

// Actions
export const { uiFocusToolbarInput, uiScrollChatToBottom, uiSetStatusBarStyle, uiSetLastBotMessageHeight, toggleIsTabBarMinimized, resetUI } = ui.actions;

// Selectors
export const getIsTabBarMinimized = (state: RootState) => state.ui.isTabBarMinimized;

export const getScrollChat = (state: RootState) => state.ui.scrollChat;

export const getFocusToolbarInput = (state: RootState) => state.ui.focusToolbarInput;

export const getLastBotMessageHeight = (state: RootState): number => state.ui.lastBotMessageHeight ?? 0;

export const getStatusBarStyle = (state: RootState) => state.ui.statusBarStyle;

export const getIsLoading = (state: RootState) => state.notifications.isLoading && state.notifications.notifications.length === 0;

// Thunks
export const scrollChatToBottom = createAsyncThunk('ui/scrollChatToBottom', (_, { dispatch }) => {
  dispatch(uiScrollChatToBottom(true));
  // Delayed by 1 "frame" to give `useEffect` listeners time to catch the change
  setTimeout(() => dispatch(uiScrollChatToBottom(null)), 0);
});

export const focusToolbarInput = createAsyncThunk('ui/focusToolbarInput', (_, { dispatch }) => {
  dispatch(uiFocusToolbarInput(true));
  // Delayed by 1 "frame" to give `useEffect` listeners time to catch the change
  setTimeout(() => dispatch(uiFocusToolbarInput(null)), 0);
});

export default ui.reducer;
