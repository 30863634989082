import React, { useMemo } from 'react';

import { colors } from '@common/theme';

import Bubble from './BubbleBase';
import Dots from './Dots';

type UserBubbleProps = {
  message: string;
  time: Date | undefined;
  read?: boolean;
};

type BotBubbleProps = {
  message: string;
  time: Date | undefined;
  showAvatar?: boolean;
};

export const UserBubble = ({ message, time, read }: UserBubbleProps) => <Bubble message={message} read={read} showStatus time={time} />;

export const BotBubble = ({ message, time, showAvatar }: BotBubbleProps) => {
  const messageLoaded = useMemo(() => message.length > 0, [message]);
  const messageTime = useMemo(() => (messageLoaded ? time : undefined), [messageLoaded, time]);
  const messageNode = useMemo(() => (!messageLoaded ? <Dots /> : message), [message, messageLoaded]);
  return <Bubble isBot message={messageNode} showAvatar={showAvatar} textColor={colors.grey4} time={messageTime} />;
};
