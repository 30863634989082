import { AppState, AppStateStatus } from 'react-native';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@common/store';
import { isWeb } from '@common/utils';

import { loadProfileImage } from './user';
import { loadNotifications, resetNotifications } from './notifications';
import { setSttTranscribing, stopSttThunk } from './stt';

interface AppStateState {
  currentState: AppStateStatus;
}

const initialState: AppStateState = {
  currentState: AppState.currentState
};

// Slice
export const appState = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    updateState: (state, action) => {
      state.currentState = action.payload;
    }
  }
});

// Actions
export const { updateState } = appState.actions;

// Thunks
export const updateAppState = createAsyncThunk<void, AppStateStatus, { state: RootState }>(
  'appState/updateAppState',
  (nextState: AppStateStatus, { dispatch, getState }) => {
    const {
      appState: { currentState },
      auth,
      stt
    } = getState();
    if (!isWeb) {
      const isAuthed = auth.token && auth.token.length && !auth.redirectUrl;

      if (isAuthed) {
        if (nextState === 'background' && currentState === 'active') {
          // Stop STT
          const { isSttListening } = stt;
          if (isSttListening) {
            dispatch(stopSttThunk());
          }
          // dispatch(resetMessages());
          dispatch(resetNotifications());
        }

        if (nextState === 'active' && currentState === 'background') {
          // dispatch(loadMessages());
          dispatch(loadNotifications());
          dispatch(loadProfileImage());
          dispatch(setSttTranscribing(false));
        }
      }
    }

    if (nextState !== 'inactive') {
      dispatch(updateState(nextState));
    }
  }
);

export default appState.reducer;
