import React from 'react';
import styled from 'styled-components/native';

import { colors } from '@common/theme';

export const PILL_BORDER_RADIUS = '22px';

const DividerOuter = styled.View`
  width: ${PILL_BORDER_RADIUS};
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
`;

const DividerInner = styled.View`
  position: absolute;
  width: 200%;
  right: 0;
  top: -2px;
  bottom: -2px;
  border-top-right-radius: ${PILL_BORDER_RADIUS};
  border-bottom-right-radius: ${PILL_BORDER_RADIUS};
  border-color: ${colors.primary};
  border-width: 2px;
  border-left-width: 0;
`;

const SegmentDivider = () => (
  <DividerOuter>
    <DividerInner />
  </DividerOuter>
);

export default SegmentDivider;
