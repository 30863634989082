import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { RootStackParamList } from '@common/navigation';
import { MAIN_STACK, LOADING_SCREEN, WALKTHROUGH_SCREEN, WEBVIEW_SCREEN } from '@common/navigation/routes';
import { LoadingScreen, WalkthroughScreen, WebViewScreen } from '@common/layouts';

import MainStack from './MainStack';

const Stack = createStackNavigator<RootStackParamList>();

const Navigator = () => {
  return (
    <Stack.Navigator initialRouteName={LOADING_SCREEN} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={LOADING_SCREEN} component={LoadingScreen} options={{ gestureEnabled: false }} />
      <Stack.Screen name={MAIN_STACK} component={MainStack} options={{ gestureEnabled: false }} />
      <Stack.Screen name={WALKTHROUGH_SCREEN} component={WalkthroughScreen} />
      <Stack.Screen name={WEBVIEW_SCREEN} component={WebViewScreen} />
    </Stack.Navigator>
  );
};

export default Navigator;
