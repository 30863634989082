import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { RootState } from '@common/store';
import { apiInterceptor } from '@common/store/interceptors';
import { getToken } from '@common/store/reducers/auth';
import { isChromeExtension, pathOr, setAnalyticsUserProps } from '@common/utils';
import { getProfileImage } from '@common/webService';

interface UserState {
  isLoading: boolean;
  profile: UserProfile;
  profileImage: ProfileImageType;
}

const initialState: UserState = {
  isLoading: false,
  profile: {},
  profileImage: ''
};

// Slice
export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadingProfile: (state, action) => {
      state.isLoading = action.payload;
    },
    setProfileData: (state, action) => {
      state.profile = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profileImage = action.payload;
    },
    resetUserData: () => initialState
  }
});

// Actions
export const { loadingProfile, setProfileData, setProfileImage, resetUserData } = user.actions;

// Selectors
export const getUsersInitials = (state: RootState) => {
  const initials = [pathOr('', ['profile', 'first_name'], state.user).charAt(0), pathOr('', ['profile', 'last_name'], state.user).charAt(0)];
  return initials.join('');
};

export const getUser = (state: RootState) => {
  const name = [pathOr('', ['profile', 'first_name'], state.user), pathOr('', ['profile', 'last_name'], state.user)].join(' ');
  return {
    name,
    email: pathOr('', ['profile', 'email'], state.user),
    userImage: state.user?.profileImage,
    userInitials: getUsersInitials(state)
  };
};

export const getUserId = (state: RootState) => {
  return pathOr('', ['profile', 'id'], state.user);
};

export const getUserProfile = (state: RootState) => state.user.profile;

export const getTimeFormat = (state: RootState): UserTimeFormat => pathOr('24-hour', ['profile', 'timeformat'], state.user);

// Thunks
export const loadProfileImage = createAsyncThunk<void, void, { state: RootState }>('user/loadProfileImage', async (_, { dispatch, getState }) => {
  const authToken = getToken(getState());
  dispatch(loadingProfile(true));
  const profileImageRequest = apiInterceptor(getProfileImage);
  try {
    const imageBlob = await profileImageRequest(authToken);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Image = reader.result;
      dispatch(setProfileImage(base64Image));
    };
    reader.readAsDataURL(imageBlob);
  } catch (error) {
    dispatch(setProfileImage(''));
  } finally {
    dispatch(loadingProfile(false));
  }
});

export const extractProfileFromToken = createAsyncThunk<void, void, { state: RootState }>('user/extractProfileFromToken', async (_, { dispatch, getState }) => {
  const authToken = getToken(getState());
  if (authToken) {
    if (isChromeExtension) {
      window.parent.postMessage(
        {
          type: 'ROLLIO/AUTH_TOKEN_REFRESH',
          payload: authToken
        },
        document.referrer || '*'
      );
    }

    const decodedToken = jwtDecode<any>(authToken);
    if (decodedToken.user) {
      const {
        user: { ...profile }
      } = decodedToken;
      setAnalyticsUserProps(profile);
      dispatch(setProfileData({ ...profile, instance_url: decodedToken.instanceUrl }));
    }
  }
});

export default user.reducer;
