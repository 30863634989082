import React from 'react';
import styled from 'styled-components/native';
import { lighten, rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { isWeb } from '@common/utils';
import { Icon, TouchableWithEvent } from '@common/components';

type HelpSectionProps = {
  onUrlPress: (link: string) => void;
};

export const WALKTHROUGH_LINK = 'walkthrough_link';
export const CONTACT_LINK = 'contact_us';

const headerIconColor = rgba(colors.grey4, 0.5);
const dividerColor = lighten(0.15, colors.grey5);

const HelpWrapper = styled.View`
  background: ${colors.grey6};
  border-radius: 4px;
  margin: 15px 10px 20px;
`;

const Header = styled.View`
  flex-direction: row;
  padding: 6px;
  align-items: center;
`;

const HeaderTitle = styled.Text`
  font-family: ${fonts.medium};
  font-size: 12px;
  color: ${colors.grey4};
  padding-left: 5px;
`;

const LinksWrapper = styled.View`
  flex-direction: row;
  padding-bottom: 14px;
  padding-top: 8px;
`;

const LinkItem = styled.TouchableOpacity.attrs({ activeOpacity: 0.7 })`
  flex: 1;
  flex-direction: column;
`;

const IconWrapper = styled.View`
  padding-top: 13px;
  padding-bottom: 10px;
  align-items: center;
`;

const LinkTitle = styled.Text`
  font-family: ${fonts.medium};
  font-size: 15px;
  color: ${colors.grey4};
  text-align: center;
  padding: 0 30px;
`;

const Divider = styled.View`
  height: 110px;
  width: 1px;
  border-left-width: 1px;
  border-color: ${dividerColor};
  justify-content: center;
  align-items: center;
`;

const DividerItem = styled.View`
  background: ${dividerColor};
  height: 14px;
  width: 22px;
  border-radius: 7px;
`;

const DividerText = styled.Text`
  font-family: ${fonts.medium};
  font-size: 11px;
  color: ${colors.grey4};
  text-align: center;
`;

const HelpSection = ({ onUrlPress }: HelpSectionProps) => (
  <HelpWrapper>
    <Header>
      <Icon name="help" size={20} color={headerIconColor} />
      <HeaderTitle>Need help?</HeaderTitle>
    </Header>
    <LinksWrapper>
      {!isWeb && (
        <>
          <LinkItem onPress={() => onUrlPress(WALKTHROUGH_LINK)} as={TouchableWithEvent} trackEventId="profile-walkthrough-click">
            <IconWrapper>
              <Icon name="map" color={colors.primary} size={42} />
            </IconWrapper>
            <LinkTitle>View Walkthrough</LinkTitle>
          </LinkItem>
          <Divider>
            <DividerItem>
              <DividerText allowFontScaling={false}>or</DividerText>
            </DividerItem>
          </Divider>
        </>
      )}
      <LinkItem onPress={() => onUrlPress(CONTACT_LINK)} as={TouchableWithEvent} trackEventId="profile-contactus-click">
        <IconWrapper>
          <Icon name="mail" color={colors.primary} size={42} />
        </IconWrapper>
        <LinkTitle>Contact Us</LinkTitle>
      </LinkItem>
    </LinksWrapper>
  </HelpWrapper>
);

export default HelpSection;
