import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import qs from 'query-string';
import SplashScreen from 'react-native-splash-screen';
import DeepLinking from 'react-native-deep-linking';
// Common
import { RootState } from '@common/store';
import { apiInterceptor } from '@common/store/interceptors';
import { loadProfileImage, resetUserData } from '@common/store/reducers/user';
import { loadNotifications, resetNotifications } from '@common/store/reducers/notifications';
import { deregisterFCMToken, getFCMToken, registerFCMToken, requestFCMToken, resetFCMToken } from '@common/store/reducers/firebase';
import { resetUI } from '@common/store/reducers/ui';
import { isChromeExtension, isSalesforceWebTab, isWeb, removeAnalyticsUserProps } from '@common/utils';
import { saveLoginToKeychain } from '@common/utils/keychain';
import { Badge } from '@common/utils/badge';
import { replace } from '@common/navigation/utils';
import { LOGIN_SCREEN, WEBVIEW_SCREEN } from '@common/navigation/routes';
import { getRedirectUrl, logoutWith } from '@common/webService';
import { Refresh } from '@common/utils/refresh';
// Legacy
import store from '@legacy/store';
import { resetMessages } from '@legacy/ducks/messages/actions';
import { resetPicklist } from '@legacy/ducks/picklist/actions';
import { resetSearch } from '@legacy/ducks/search/actions';
import { resetEdition, setContextUpdated } from '@legacy/ducks/edition/actions';

export const AUTH_TOKEN_STORAGE_KEY = '@rollio_token';
export const WEB_IS_AWAITING_REDIRECT = 'awaitingRedirect';

interface AuthState {
  isLoading: boolean;
  isOAuthLoading: boolean;
  redirectUrl: string;
  token: string;
}

const initialState: AuthState = {
  isLoading: false,
  isOAuthLoading: false,
  redirectUrl: '',
  token: ''
};

// Slice
export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestLogin: state => {
      state.isLoading = true;
    },
    loginSuccess: state => {
      state.isLoading = false;
    },
    loginError: state => {
      state.isLoading = false;
    },
    requestOAuth: state => {
      state.isOAuthLoading = true;
    },
    oAuthSuccess: state => {
      state.isOAuthLoading = false;
      state.redirectUrl = '';
    },
    oAuthError: state => {
      state.isOAuthLoading = false;
      state.redirectUrl = '';
    },
    oAuthExternalBrowser: state => {
      state.isOAuthLoading = false;
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    resetAuth: () => initialState
  }
});

// Actions
export const {
  requestLogin,
  loginSuccess,
  loginError,
  requestOAuth,
  oAuthSuccess,
  oAuthError,
  oAuthExternalBrowser,
  updateToken,
  updateRedirectUrl,
  resetAuth
} = auth.actions;

// Selectors
export const getIsLoading = (state: RootState) => state.auth.isLoading || state.auth.isOAuthLoading;

export const getToken = (state: RootState) => state.auth?.token;

export const selectIsLoggedIn = (state: RootState) => !!state.auth.token;

// Thunks
export const setAuthToken = createAsyncThunk<void, string, { state: RootState }>('auth/setAuthToken', async (token: string, { dispatch }) => {
  dispatch(updateToken(token));
  AsyncStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);
});

const oAuthRequestRedirect = createAsyncThunk<void, boolean, { state: RootState }>(
  'auth/oAuthRequestRedirect',
  async (useExternalBrowser: boolean, { dispatch, getState }) => {
    const {
      auth: { redirectUrl }
    }: RootState = getState();

    dispatch(requestOAuth());
    const canRedirect = await Linking.canOpenURL(redirectUrl);

    if (canRedirect) {
      if (isWeb) {
        await AsyncStorage.setItem(WEB_IS_AWAITING_REDIRECT, 'true');
        if (isChromeExtension || isSalesforceWebTab) {
          window.addEventListener('message', ({ data }) => {
            if (data.type === 'ROLLIO/AUTH_ERROR' || data.type === 'ROLLIO/AUTH_RESET') {
              dispatch(oAuthError());
            }
          });

          window.parent.postMessage(
            {
              type: 'ROLLIO/AUTH_REDIRECT',
              payload: redirectUrl
            },
            document.referrer || '*'
          );
        } else {
          dispatch(oAuthExternalBrowser());
          window.open(redirectUrl, '_self');
        }
      } else {
        replace(WEBVIEW_SCREEN, {
          url: redirectUrl,
          header: 'Login | Salesforce',
          openInExternalBrowser: useExternalBrowser,
          backPress: () => {
            dispatch(oAuthError());
            replace(LOGIN_SCREEN);
          }
        });
      }
    } else {
      dispatch(oAuthError());
    }
  }
);

export const login = createAsyncThunk<void, { username: string; saveCredentials: boolean; firebaseIdToken?: string }, { state: RootState }>(
  'auth/login',
  async ({ username, saveCredentials, firebaseIdToken }, { dispatch }) => {
    dispatch(requestLogin());

    try {
      const { openInNewBrowserWindow, redirectUrl = '' } = await getRedirectUrl(username, firebaseIdToken);
      if (typeof redirectUrl === 'string' && !!redirectUrl.length) {
        dispatch(loginSuccess());
        dispatch(updateRedirectUrl(redirectUrl));
        dispatch(oAuthRequestRedirect(openInNewBrowserWindow));

        saveLoginToKeychain(saveCredentials, username);

        return Promise.resolve();
      }

      throw new Error();
    } catch (error) {
      dispatch(loginError());
      return Promise.reject(error);
    }
  }
);

export const postLoginRedirect = createAsyncThunk<void, string, { state: RootState }>('auth/postLoginRedirect', (token: string, { dispatch, getState }) => {
  dispatch(setAuthToken(token));

  if (!isChromeExtension) {
    const fcmToken = getFCMToken(getState());
    if (fcmToken) {
      dispatch(registerFCMToken(fcmToken));
    }
  }

  dispatch(loadProfileImage());
  dispatch(loadNotifications());
});

export const logout = createAsyncThunk<void, void, { state: RootState }>('auth/logout', (_, { dispatch, getState }) => {
  if (isChromeExtension) {
    window.parent.postMessage({ type: 'ROLLIO/LOGOUT' }, document.referrer || '*');
  }

  const state = getState();
  const token = getToken(state);
  const logoutRequest = apiInterceptor(logoutWith);

  const fcmToken = getFCMToken(getState());
  if (fcmToken) {
    dispatch(deregisterFCMToken(fcmToken));
  }

  try {
    logoutRequest(token);
  } catch (error) {
    // handled by interceptor
  }
  dispatch(resetAuth());
  store.dispatch(resetMessages());
  dispatch(resetNotifications());
  store.dispatch(resetPicklist());
  store.dispatch(resetSearch());
  store.dispatch(resetEdition());
  dispatch(resetUI());
  dispatch(resetUserData());
  dispatch(resetFCMToken());
  store.dispatch(setContextUpdated(false));

  AsyncStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
  DeepLinking.removeRoute('/sf-redirect/:token');
  Badge.setBadge(0);

  if (!isWeb) {
    Refresh.stop('reloadNotifications');
  }
  removeAnalyticsUserProps();
});

export const oAuthSuccessRedirect = createAsyncThunk<void, string, { state: RootState }>('auth/oAuthSuccessRedirect', async (token, { dispatch, getState }) => {
  const { auth: authState } = getState();
  const isAwaitingWebRedirect = await AsyncStorage.getItem(WEB_IS_AWAITING_REDIRECT);

  const allowDeeplink = authState.redirectUrl || (isWeb && isAwaitingWebRedirect === 'true');

  if (allowDeeplink) {
    await dispatch(requestFCMToken());
    await dispatch(postLoginRedirect(token));
    dispatch(oAuthSuccess());

    // Remove query parameters from the URL
    if (isWeb) {
      const { url } = qs.parseUrl(window.location.href);
      window.history.replaceState({}, '', url);
      AsyncStorage.removeItem(WEB_IS_AWAITING_REDIRECT);
    }
  } else {
    // Capture any deep-link attempts that we aren't expecting
    dispatch(oAuthError());
    dispatch(logout());
  }
});

export const recoverSession = createAsyncThunk<void, void, { state: RootState }>('auth/recoverSession', async (_, { dispatch }) => {
  try {
    const token = await AsyncStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

    if (typeof token === 'string' && !!token.length) {
      await dispatch(requestFCMToken());
      await dispatch(postLoginRedirect(token));
    } else {
      throw new Error();
    }
  } catch (error) {
    replace(LOGIN_SCREEN);
    await dispatch(requestFCMToken());
  } finally {
    if (!isWeb) {
      SplashScreen.hide();
    }
  }
});

export default auth.reducer;
