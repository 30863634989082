import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import {
  // WebViewScreen,
  ChatScreen,
  EditScreen
} from '@legacy/app/layouts';
import {
  // WEBVIEW_SCREEN,
  CHAT_SCREEN,
  EDIT_SCREEN
} from '@legacy/app/navigation/routes';
import { ChatTabParamList } from './types';

const Stack = createStackNavigator<ChatTabParamList>();

const ChatStack = () => (
  <Stack.Navigator initialRouteName={CHAT_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={CHAT_SCREEN} component={ChatScreen} />
    <Stack.Screen name={EDIT_SCREEN} component={EditScreen} />
    {/* <Stack.Screen name={WEBVIEW_SCREEN} component={WebViewScreen} /> */}
  </Stack.Navigator>
);

export default ChatStack;
