import React from 'react';
import { ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';

import { colors, fonts } from '@common/theme';
import notificationLogo from '@common/images/NotificationImages/logo.png';

type InAppNotificationProps = {
  title: string;
  body: string;
} & StyledViewProps;

const Wrap = styled.View`
  padding: 14px 10px 5px;
  align-self: stretch;
`;

const Header = styled.View`
  margin-bottom: 6px;
  flex-direction: row;
`;

const Logo = styled.Image.attrs<{ source?: ImageSourcePropType }>({
  source: notificationLogo
})`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;

const Title = styled.Text`
  font-size: 15px;
  font-family: ${fonts.medium};
  color: ${colors.grey3};
  flex: 1;
`;

const Body = styled.Text`
  font-size: 14px;
  font-family: ${fonts.regular};
  color: ${colors.grey3};
`;

const InAppNotification = ({ title, body, ...props }: InAppNotificationProps) => (
  <Wrap {...props}>
    <Header>
      <Logo />
      <Title>{title}</Title>
    </Header>
    <Body>{body}</Body>
  </Wrap>
);

export default InAppNotification;
