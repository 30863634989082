import { StackActions, TabActions, createNavigationContainerRef } from '@react-navigation/native';

export const navigationRef = createNavigationContainerRef();

export const navigate = (...args: any) => {
  if (navigationRef.isReady()) {
    navigationRef.navigate.apply(null, args);
  }
};

export const replace = (...args: any) => {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(StackActions.replace.apply(null, args));
  }
};

export const jumpTo = (...args: any) => {
  if (navigationRef.isReady()) {
    navigationRef.dispatch(TabActions.jumpTo.apply(null, args));
  }
};

export const getNavState = () => {
  if (navigationRef.isReady()) {
    return navigationRef.getState();
  }

  return undefined;
};
