import React from 'react';
import { BackHandler } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { FlatList, Header, LoadingOverlay, EmptyState, ScreenWrap, Notification } from '@common/components';
import { useTabBarHeight, useDispatch as useCommonDispatch, useSelector } from '@common/hooks';
import { getNotifications, loadNotifications, reloadNotifications, selectNotification } from '@common/store/reducers/notifications';
import { NotificationsStackScreenProps } from '@legacy/app/navigation/types';
import { CHAT_SCREEN, CHAT_TAB, NOTIFICATIONS_SCREEN } from '@legacy/app/navigation/routes';
import { prependMessages } from '@legacy/ducks/messages/actions';

type NotificationsScreenProps = NotificationsStackScreenProps<typeof NOTIFICATIONS_SCREEN>;

const NotificationsScreen = ({ navigation }: NotificationsScreenProps) => {
  const dispatch = useDispatch();
  const commmonDispatch = useCommonDispatch();
  const { areMore, isLoading, isProcessingNotification, notifications } = useSelector(getNotifications);

  const loadMoreNotifications = () => commmonDispatch(loadNotifications());

  const onNotificationPress = (notification: NotificationShape) => {
    commmonDispatch(selectNotification(notification.id))
      // @ts-ignore
      .then(unwrapResult)
      .then((messages: any) => {
        if (messages.length) {
          dispatch(prependMessages(messages, true));
          navigation.navigate(CHAT_TAB, { screen: CHAT_SCREEN });
        }
      });
  };

  const refreshNotifications = () => commmonDispatch(reloadNotifications());

  const { animatedTabBarHeight } = useTabBarHeight();
  const hasNotifications = notifications.length > 0;
  const isInitialLoading = !hasNotifications && isLoading;

  useFocusEffect(
    React.useCallback(() => {
      const onBackPress = () => {
        return true;
      };
      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => subscription.remove();
    }, [])
  );

  return (
    <ScreenWrap>
      <Header center="Notifications" />

      {(hasNotifications || isInitialLoading) && (
        <FlatList<NotificationShape>
          areMoreItems={areMore}
          data={notifications}
          isLoading={isLoading}
          onLoadMore={loadMoreNotifications}
          onRefresh={refreshNotifications}
          renderItem={({ item }) => (
            <Notification title={item.title} date={item.date} onPress={() => onNotificationPress(item)} isRead={item.isRead} body={item.body} />
          )}
        />
      )}

      {!hasNotifications && !isLoading && <EmptyState title="All clear! 👏" displayButton={false} style={{ paddingBottom: animatedTabBarHeight }} />}

      <LoadingOverlay visible={isProcessingNotification} />
    </ScreenWrap>
  );
};

export default NotificationsScreen;
