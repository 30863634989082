import React, { useEffect } from 'react';

import { RootStackScreenProps } from '@common/navigation';
import { LOADING_SCREEN, MAIN_STACK } from '@common/navigation/routes';
import { LoadingOverlay } from '@common/components';

type LoadingScreenProps = RootStackScreenProps<typeof LOADING_SCREEN>;

const LoadingScreen = ({ navigation }: LoadingScreenProps) => {
  useEffect(() => {
    const timeOut = setTimeout(() => navigation.navigate(MAIN_STACK), 1000);
    return () => clearTimeout(timeOut);
  }, []);

  return <LoadingOverlay id="loading-screen" visible />;
};

export default LoadingScreen;
