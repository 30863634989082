import React, { forwardRef, RefAttributes, useMemo, type Ref, useContext, useState, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components/native';
import { StyledTextInput, StyledTextInputProps } from 'styled-components/types';

import ScrollComponent from '@common/components/ChatScreen/ActionToolbar/Scroll';
import { ToolbarContext, ToolbarDispatchContext } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';

import { colors, fonts } from '@common/theme';
import { isAndroid, isWeb } from '@common/utils';

import {
  FONT_SIZE,
  INPUT_LINE_HEIGHT,
  isDefault,
  isSearch,
  MAX_HEIGHT,
  MAX_INPUT_HEIGHT,
  MINIMIZED_HEIGHT,
  PLACEHOLDER_COLOR,
  TOOLBAR_MIN_HEIGHT
} from './shared';
import { TextInputComponent } from 'react-native';

export type InputProps = {
  onChange: (text: string) => void;
  onInputSubmit: () => void;
  value: string;
  testID: string;
} & RefAttributes<StyledTextInput>;

type InternalProps = InputProps & {
  innerRef: Ref<StyledTextInput>;
};

const speechToTextPlaceholder = 'Speech to text is activated...';

const textStyles = css`
  color: ${colors.grey4};
  padding: 0;
  font-family: ${fonts.regular};
  margin: 5px 12px 5px 18px;
  min-height: ${MINIMIZED_HEIGHT}px;
  justify-content: center;
`;

const ToolbarInput = styled.TextInput`
  ${textStyles};
  flex: 1;
  font-size: ${FONT_SIZE}px;
  line-height: ${INPUT_LINE_HEIGHT}px;

  ${isWeb &&
  css`
    outline-width: 0;
    caret-color: ${colors.primary};
  `}
`;

const getReturnKeyType = (variant: ToolbarVariants) => {
  if (isSearch(variant)) {
    return 'search';
  }

  return 'send';
};

const InputComponent = ({ innerRef, onChange, onInputSubmit, testID, value }: InternalProps) => {
  const { animatedHeight, isListening, variant, placeholder } = useContext(ToolbarContext);
  const { setAnimatedHeight } = useContext(ToolbarDispatchContext);
  const [currentHeight, setHeight] = useState(MINIMIZED_HEIGHT);
  const inputReturnKeyType = useMemo(() => getReturnKeyType(variant), [variant]);
  const inputProps = useMemo(() => {
    const props: StyledTextInputProps = {};
    const isExpanded = currentHeight > MINIMIZED_HEIGHT;
    props.testID = testID;
    props.placeholder = isListening ? speechToTextPlaceholder : placeholder;
    props.placeholderTextColor = PLACEHOLDER_COLOR;
    props.underlineColorAndroid = 'transparent';
    props.autoCorrect = false;
    props.blurOnSubmit = true;
    props.style = {
      alignSelf: isExpanded ? 'flex-start' : 'flex-end',
      height: isExpanded ? currentHeight : MINIMIZED_HEIGHT,
      maxHeight: MAX_INPUT_HEIGHT,
      marginTop: isExpanded ? 15 : 5
    };
    props.enablesReturnKeyAutomatically = true;

    if (isDefault(variant)) {
      props.multiline = true;
    }

    if (isDefault(variant) || isSearch(variant)) {
      props.onChangeText = onChange;
      props.onSubmitEditing = onInputSubmit;
      props.returnKeyType = inputReturnKeyType;
      props.selectionColor = colors.grey9;
      props.value = value;
    }

    return props;
  }, [testID, isListening, inputReturnKeyType, animatedHeight, currentHeight, onChange, onInputSubmit, placeholder, value, variant]);

  const handleWebContentResize = (e: any) => {
    if (isWeb) {
      const isExpanded = !!e.target.value.length && e.target.scrollHeight > MINIMIZED_HEIGHT;
      e.target.style.height = 0;
      e.target.style.alignSelf = isExpanded ? 'flex-start' : 'flex-end';
      e.target.style.height = isExpanded ? `${e.target.scrollHeight}px` : MINIMIZED_HEIGHT;
      e.target.style.maxHeight = `${MAX_INPUT_HEIGHT}px`;
      e.target.style.marginTop = isExpanded ? '15px' : '5px';
      setHeight(e.target.scrollHeight);
    }
  };

  const handleMobileAndSTTContentResize = (e: any) => {
    const { contentSize } = e.nativeEvent;
    const { height } = contentSize;
    if (height !== currentHeight) {
      setHeight(height);
    }
  };

  useLayoutEffect(() => {
    const isExpanded = currentHeight > MINIMIZED_HEIGHT;
    const tempAnimatedHeight = isExpanded ? currentHeight + 30 : TOOLBAR_MIN_HEIGHT;

    if (currentHeight <= MAX_INPUT_HEIGHT) {
      setAnimatedHeight(tempAnimatedHeight);
    } else {
      setAnimatedHeight(MAX_HEIGHT);
    }
  }, [currentHeight]);

  useLayoutEffect(() => {
    if (!value && currentHeight !== MINIMIZED_HEIGHT) {
      setHeight(MINIMIZED_HEIGHT);
    }
  }, [value]);

  if ((isAndroid || isWeb) && isListening) {
    return <ScrollComponent height={currentHeight} value={value} placeholder={placeholder} setHeight={setHeight} />;
  }

  return (
    <ToolbarInput
      as={TextInputComponent}
      ref={innerRef}
      {...inputProps}
      multiline
      onChange={handleWebContentResize}
      onContentSizeChange={handleMobileAndSTTContentResize}
    />
  );
};

const Input = forwardRef<StyledTextInput, InputProps>((props, ref) => <InputComponent {...props} innerRef={ref} />);

export default Input;
