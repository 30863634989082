import React, { ReactNode, useMemo } from 'react';
import { TextStyle, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { StyledTouchableOpacityProps } from 'styled-components/types';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { toDataTestIdFriendly } from '@common/utils';
import { TouchableWithEvent, TouchableWithEventProps } from '@common/components';

import HeaderBase from './HeaderBase';

type HeaderProps = {
  center?: ReactNode;
  centerStyle?: ViewStyle;
  left?: ReactNode;
  onLeftPress?: () => void;
  onRightPress?: () => void;
  right?: ReactNode;
};

type HeaderSideProps = {
  children?: ReactNode;
  textStyle?: TextStyle;
  as?: any;
} & TouchableWithEventProps;

const HeaderButton = styled.TouchableOpacity.attrs<StyledTouchableOpacityProps>({
  activeOpacity: 0.75
})`
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 14px;
`;

const HeaderButtonLabel = styled.Text`
  font-size: 15px;
  font-family: ${fonts.regular};
  color: ${rgba(colors.grey4, 0.85)};
`;

const HeaderSide = ({ children, textStyle, ...props }: HeaderSideProps) => {
  if (typeof children === 'string') {
    return (
      <HeaderButton {...props} accessibilityRole="button">
        <HeaderButtonLabel style={textStyle}>{children}</HeaderButtonLabel>
      </HeaderButton>
    );
  }

  return <>{children}</>;
};

const rightSideTextStyle = {
  color: colors.primary
};

const HeaderCenter = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderTitle = styled.Text.attrs({ numberOfLines: 1 })`
  color: ${colors.primary};
  font-family: ${fonts.medium};
  font-size: 18px;
  text-align: center;
  padding: 0 15px;
`;

const Header = ({ center, centerStyle, left, onLeftPress = () => {}, onRightPress = () => {}, right }: HeaderProps) => {
  const centerNode = useMemo(() => {
    if (typeof center === 'string') {
      return (
        <HeaderTitle accessibilityRole="header" aria-level="1">
          {center}
        </HeaderTitle>
      );
    }

    return center;
  }, [center]);

  const friendlyLabelLeft = typeof left === 'string' ? toDataTestIdFriendly(left) : 'action';
  const friendlyLabelRight = typeof right === 'string' ? toDataTestIdFriendly(right) : 'action';

  return (
    <HeaderBase>
      <HeaderSide
        onPress={onLeftPress}
        testID={`header-left-button-${friendlyLabelLeft}`}
        as={TouchableWithEvent}
        trackEventId={`header_${friendlyLabelLeft}_click`}
      >
        {left}
      </HeaderSide>
      <HeaderCenter style={centerStyle}>{centerNode}</HeaderCenter>
      <HeaderSide
        onPress={onRightPress}
        textStyle={rightSideTextStyle}
        testID={`header-right-button-${friendlyLabelRight}`}
        as={TouchableWithEvent}
        trackEventId={`header_${friendlyLabelRight}_click`}
      >
        {right}
      </HeaderSide>
    </HeaderBase>
  );
};

export default Header;
