import { Action } from '@reduxjs/toolkit';
import { type Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import { updateToken } from '@common/store/reducers/auth';
import { extractProfileFromToken } from '@common/store/reducers/user';

export const getProfileOnTokenUpdate = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(updateToken),
    mergeMap(() => of(extractProfileFromToken()))
  );
