import { useMemo } from 'react';
import { useForm } from 'react-final-form';

import { getDependantOptions } from '@common/utils';
import { useGetParentDependency } from '@common/hooks';

const useFieldOptions = (field: FieldType, dependantAllowedValues: DependantAllowedValues = []): { fieldOptions: Array<string>; fieldReactKey: string } => {
  const { name: fieldName = '', allowedValues: defaultOptions = [] } = field;
  const form = useForm();
  const { parentDependency } = useGetParentDependency(fieldName, dependantAllowedValues);

  // Field state for the parent field
  const parentFieldState = parentDependency ? form.getFieldState(parentDependency.parentField) : null;

  // An array of options provided to the `fieldName` picklist/select field,
  // if it is controlled by a parent the options are calculated based on the parent's value
  const fieldOptions = useMemo(() => {
    if (parentDependency) {
      return getDependantOptions(parentDependency, parentFieldState?.value, defaultOptions);
    }

    return defaultOptions;
  }, [defaultOptions, parentDependency, parentFieldState]);

  // A unique string necessary for React to re-render the select input on web
  const fieldReactKey = useMemo(() => {
    if (parentDependency && parentFieldState) {
      return [fieldName, parentDependency.parentField, parentFieldState.value].join('__');
    }

    return fieldName;
  }, [fieldName, parentDependency, parentFieldState]);

  return { fieldOptions, fieldReactKey };
};

export default useFieldOptions;
