const apmServerURL = process.env.APM_SERVER_URL;
const apmSecretToken = process.env.APM_SECRET_TOKEN;
const env = process.env.ENV;
const envPlatform = process.env.ENV_PLATFORM;
const authUrl = process.env.AUTH_URL;
const notificationUrl = process.env.NOTIFICATION_URL;
const coreUrl = process.env.CORE_URL;
const sttUrl = process.env.STT_URL;
const sttPostDisabled = process.env.STT_POST_DISABLED === 'true';
const sttModel = process.env.STT_MODEL;
const appCoreUrl = process.env.APP_CORE_URL;

export { apmServerURL, apmSecretToken, env, envPlatform, authUrl, notificationUrl, coreUrl, sttUrl, sttPostDisabled, sttModel, appCoreUrl };
