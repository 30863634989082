import React from 'react';
import { Text, TextProps } from 'react-native';

import { getDateString } from '@common/utils';
import { useSelector } from '@common/hooks';
import { getTimeFormat } from '@common/store/reducers/user';

type FormatDateProps = {
  type?: FieldDateTypes;
  value: any;
} & TextProps;

const FormatDate = ({ type, value, ...props }: FormatDateProps) => {
  const timeFormat = useSelector(getTimeFormat);
  const string = getDateString(value, timeFormat, type);

  return string ? <Text {...props}>{string}</Text> : null;
};

export default FormatDate;
