import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@common/store';
import { getToken } from '@common/store/reducers/auth';
import { getUserId } from '@common/store/reducers/user';
import { apiInterceptor } from '@common/store/interceptors';
import { deregisterDevice, registerDevice } from '@common/webService';
import { isWeb } from '@common/utils';
import { messaging } from '@common/utils/firebase';

interface FirebaseState {
  token?: string;
}

const initialState: FirebaseState = {};

// Slice
export const firebase = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    updateFCMToken: (state, action) => {
      state.token = action.payload;
    },
    resetFCMToken: () => initialState
  }
});

// Actions
export const { updateFCMToken, resetFCMToken } = firebase.actions;

// Selectors
export const getFCMToken = (state: RootState) => state.firebase?.token;

// Thunks
export const requestFCMToken = createAsyncThunk<void, void, { state: RootState }>('firebase/requestFCMToken', async (_, { dispatch }) => {
  try {
    // Check whether we have permission to receive notifications
    if (!isWeb) {
      const status = await messaging().hasPermission();
      const enabled = status === messaging.AuthorizationStatus.AUTHORIZED || status === messaging.AuthorizationStatus.PROVISIONAL;

      if (!enabled) {
        await messaging().requestPermission();
      }
    } else if ('Notification' in window && Notification.permission !== 'granted') {
      const hasPermission = await Notification.requestPermission();

      if (hasPermission !== 'granted') {
        return;
      }
    }

    // Obtain FCM token
    const fcmToken = await messaging().getToken();

    dispatch(updateFCMToken(fcmToken));
  } catch (error) {
    // ToDo: add apm
    console.log('error====>', error);
  }
});

export const registerFCMToken = createAsyncThunk<void, string, { state: RootState }>('firebase/registerFCMToken', async (fcmToken: string, { getState }) => {
  const state = getState();
  const token = getToken(state);
  const userId = getUserId(state);
  const apiRequest = apiInterceptor(registerDevice);

  try {
    await apiRequest(token, userId, fcmToken);
  } catch (error) {
    // ToDo: add apm
    console.log('error====>', error);
  }
});

export const deregisterFCMToken = createAsyncThunk<void, string, { state: RootState }>(
  'firebase/deregisterFCMToken',
  async (fcmToken: string, { getState }) => {
    const token = getToken(getState());
    const apiRequest = apiInterceptor(deregisterDevice);

    try {
      await apiRequest(token, fcmToken);
    } catch (error) {
      // ToDo: add apm
      console.log('error====>', error);
    }
  }
);

export default firebase.reducer;
