import React from 'react';

import Header from './Header';

type HeaderBackProps = {
  onBackPress: () => void;
  title: string;
};

const rightOffset = {
  paddingRight: 60
};

const HeaderBack = ({ onBackPress, title }: HeaderBackProps) => <Header left="Back" onLeftPress={onBackPress} center={title} centerStyle={rightOffset} />;

export default HeaderBack;
