import { useCallback } from 'react';

import { useSelector as useCommonSelector } from '@common/hooks';
import { apiInterceptor } from '@common/store/interceptors';
import { getToken } from '@common/store/reducers/auth';

import { edition } from '@legacy/webService';

const useEditConfiguration = () => {
  const token = useCommonSelector(getToken);
  const linkSlotsWithLookupConfig = useCallback(async (fields: FieldType[]) => apiInterceptor(edition)(fields, token), [token, apiInterceptor]);

  return {
    linkSlotsWithLookupConfig
  };
};

export default useEditConfiguration;
