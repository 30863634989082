import { isChromeExtension } from '@common/utils';

export const Badge = {
  setBadge: (value: number) => {
    if (!isChromeExtension) {
      return;
    }

    window.parent.postMessage(
      {
        type: 'ROLLIO/SET_BADGE',
        payload: value
      },
      document.referrer || '*'
    );
  }
};
