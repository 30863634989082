import { REHYDRATE } from 'redux-persist';

import { flip, over, set, lensPath, concat, MESSAGES_PER_PAGE } from '@common/utils';
import { Action } from '@legacy/ducks';

// State
export type MessageState = {
  areMore: boolean;
  isLoading: boolean;
  isSending: boolean;
  messages: Messages;
  rehydrated: boolean;
  confirmationFields: FieldType[];
};

const initialState: MessageState = {
  areMore: false,
  isLoading: false,
  isSending: false,
  rehydrated: false,
  confirmationFields: [],
  messages: []
};

// Reducer
const messages = (state: MessageState = initialState, action: Action) => {
  const setAreMore = (areMore: any) => set(lensPath(['areMore']), areMore, state);

  switch (action.type) {
    case 'Messages/SENDING':
      return set(lensPath(['isSending']), action.payload, state);

    case 'Messages/LOADING':
      return set(lensPath(['isLoading']), action.payload, state);

    case 'Messages/APPEND':
      // @ts-ignore
      return over(lensPath(['messages']), flip(concat)(action.payload), state);

    case 'Messages/PREPEND':
      return over(lensPath(['messages']), concat(action.payload), state);

    case 'Messages/RESET':
      return initialState;

    case 'Messages/ARE_MORE_MESSAGES':
      return setAreMore(action.payload);

    case 'Messages/MARK_AS_READ': {
      const { payload } = action;
      const index = state.messages.findIndex(message => message === payload);

      return set(lensPath(['messages', index, 'read']), true, state);
    }

    case 'Messages/SET_CONFIRMATION_FIELDS': {
      const { payload } = action;

      return set(lensPath(['confirmationFields']), payload, state);
    }

    case REHYDRATE:
      const persistedMessages = action.payload?.messages?.messages ?? [];
      const rehydrated = !!action.payload?._persist?.rehydrated;

      return {
        ...state,
        areMore: persistedMessages.length >= MESSAGES_PER_PAGE,
        messages: persistedMessages,
        rehydrated
      };

    default:
      return state;
  }
};

export default messages;
