import React from 'react';
import { toast, Slide } from 'react-toastify';

import { InAppNotification } from '@common/components';

const style = { paddingTop: 0, paddingLeft: 0 };

export const Notification = {
  configure: () => toast.configure(),
  show: ({ title, body }: VisibleNotification, onClick: () => void = () => {}) =>
    toast(<InAppNotification title={title} body={body} style={style} />, {
      position: 'top-right',
      autoClose: 8000,
      pauseOnHover: true,
      transition: Slide,
      progressClassName: 'rollio-in-app-notification__progress-bar',
      onClick
    })
};
