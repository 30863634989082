import React, { useMemo } from 'react';

import { messageTypes, pathOr, prettyDate } from '@common/utils';
import { useSelector } from '@common/hooks';
import { getTimeFormat } from '@common/store/reducers/user';

import ChatBubble from './ChatBubble';
import DateIndicator from './DateIndicator';
import QuickReply from './QuickReply';
import TableBMessage from './TableBMessage';

type MessageProps = {
  areMoreMessages?: boolean;
  isLatestBotMessage?: boolean;
  isLatestMessage?: boolean;
  message: MessageType;
  navigateToEditScreen: (tableData: any, dependantAllowedValues: DependantAllowedValues) => void;
  nextMessage?: MessageType;
  sendMessage: (postback: Postback) => void;
};

const Message = ({ areMoreMessages, isLatestBotMessage, isLatestMessage, message, navigateToEditScreen, nextMessage, sendMessage }: MessageProps) => {
  const timeFormat = useSelector(getTimeFormat);

  const inlineMessageDate = useMemo(() => {
    if (!nextMessage && areMoreMessages) {
      return null;
    }

    const messageDate = message ? prettyDate(message.createdAt, timeFormat) : '';
    const nextMessageDate = nextMessage ? prettyDate(nextMessage.createdAt, timeFormat) : '';

    if (messageDate !== nextMessageDate) {
      return messageDate;
    }

    return null;
  }, [areMoreMessages, message, nextMessage, timeFormat]);

  const messageComponent = useMemo(() => {
    switch (message.type) {
      case messageTypes.TEXT:
        return <ChatBubble {...message} showAvatar={isLatestBotMessage} />;

      case messageTypes.TABLE: {
        const fieldsLength = pathOr(0, ['fields', 'length'], message);

        return fieldsLength ? (
          <TableBMessage
            message={message}
            showAvatar={isLatestBotMessage}
            showFooterButtons={isLatestMessage}
            sendMessage={sendMessage}
            navigateToEditScreen={navigateToEditScreen}
          />
        ) : null;
      }

      case messageTypes.QUICK_REPLY:
        return isLatestMessage ? <QuickReply message={message} sendMessage={sendMessage} /> : null;

      default:
        return null;
    }
  }, [isLatestBotMessage, isLatestMessage, message, sendMessage]);

  return (
    <>
      {messageComponent}
      {inlineMessageDate && <DateIndicator label={inlineMessageDate} />}
    </>
  );
};

export default Message;
