import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import { useSelector } from '@common/hooks';
import { getUnreadNotificationsCount } from '@common/store/reducers/notifications';
import { CHAT_TAB, NOTIFICATIONS_TAB, PROFILE_TAB } from '@legacy/app/navigation/routes';
import { TabBar } from '@legacy/app/components';

import NotificationsStack from './NotificationsStack';
import ChatStack from './ChatStack';
import ProfileStack from './ProfileStack';

const Tab = createBottomTabNavigator();

const Navigator = () => {
  const unreadNotificationsCount = useSelector(getUnreadNotificationsCount);

  return (
    <Tab.Navigator initialRouteName={CHAT_TAB} screenOptions={{ headerShown: false }} tabBar={props => <TabBar {...props} />}>
      <Tab.Screen
        name={NOTIFICATIONS_TAB}
        component={NotificationsStack}
        options={{
          tabBarLabel: 'Notifications',
          tabBarBadge: unreadNotificationsCount
        }}
      />
      <Tab.Screen
        name={CHAT_TAB}
        component={ChatStack}
        options={{
          tabBarLabel: 'Chat'
        }}
      />
      <Tab.Screen
        name={PROFILE_TAB}
        component={ProfileStack}
        options={{
          tabBarLabel: 'Profile'
        }}
      />
    </Tab.Navigator>
  );
};
export default Navigator;
