import styled from 'styled-components/native';

import dotIndicator from '@common/images/ChatScreenImages/dot-indicator.gif';

const Dots = styled.Image.attrs({
  source: dotIndicator,
  testID: 'dots-loader'
})`
  width: 51.8px;
  height: 17.5px;
`;

export default Dots;
