import moment, { MomentInput } from 'moment';

const usaDateFormat = 'MM/DD/YYYY';
const usaTimeFormat = 'hh:mm a';
const usaDateTimeFormat = `${usaDateFormat} ${usaTimeFormat}`;
const europeDateFormat = 'DD/MM/YYYY';
const europeTimeFormat = 'HH:mm';
const europeDateTimeFormat = `${europeDateFormat} ${europeTimeFormat}`;
const fullISOFormat = 'YYYY-MM-DDTHH:mm:ss.SSS+0000';
const dateOnlyISOFormat = 'YYYY-MM-DD';
const pickerDateFormat = 'YYYY-MM-DD';
const pickerDateTimeFormat = 'YYYY-MM-DDTHH:mm';

const getFormatString = (timeFormat: TimeFormatType, type: FieldDateTypes) => {
  switch (type) {
    case 'Date':
      return timeFormat === '12-hour' ? usaDateFormat : europeDateFormat;
    case 'DateTime':
      return timeFormat === '12-hour' ? usaDateTimeFormat : europeDateTimeFormat;
    case 'Time':
      return timeFormat === '12-hour' ? usaTimeFormat : europeTimeFormat;
    default:
      throw new Error(`Invalid date type: "${type || 'undefined'}"`);
  }
};

export const convertToDateFrom = (date: MomentInput, timeFormat: TimeFormatType, type: FieldDateTypes = 'Date'): string => {
  const formatString = getFormatString(timeFormat, type);

  return moment(date).format(formatString);
};

const isToday = (date: MomentInput): boolean => moment(date).isSame(moment(), 'day');

const isYesterday = (date: MomentInput): boolean => moment(date).isSame(moment().subtract(1, 'day'), 'day');

const isTomorrow = (date: MomentInput): boolean => moment(date).isSame(moment().add(1, 'day'), 'day');

export const prettyDate = (date: MomentInput, timeFormat: TimeFormatType): string => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }
  if (isTomorrow(date)) {
    return 'Tomorrow';
  }

  return convertToDateFrom(date, timeFormat);
};

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getDateString = (value: MomentInput, timeFormat: TimeFormatType, type?: FieldDateTypes) => {
  if (value) {
    const momentDate = moment(value);

    try {
      if (momentDate.isValid()) {
        return convertToDateFrom(value, timeFormat, type);
      }

      throw new Error(`Invalid format for date: ${value}`);
    } catch (error) {
      console.log('error', error);
    }
  }

  return '';
};

const getTypeFromDate = (value: MomentInput) => {
  if (moment(value, [fullISOFormat], true).isValid()) {
    return 'DateTime';
  }
  if (moment(value, [dateOnlyISOFormat], true).isValid()) {
    return 'Date';
  }

  return null;
};

export const formatDateValue = (value: MomentInput, timeFormat: TimeFormatType) => {
  const type = getTypeFromDate(value);

  return type ? convertToDateFrom(value, timeFormat, type) : value;
};

export const toDate = (value: MomentInput) => moment(value).toDate();

export const toISOString = (value: MomentInput) => moment(value).toISOString();

export const displayPickerDate = (value: MomentInput, isDateTime: boolean) => moment(value).format(isDateTime ? pickerDateTimeFormat : pickerDateFormat);

const formatDateTimeRange = (from: MomentInput, to: MomentInput, timeFormat: TimeFormatType): string => {
  const startTime = getDateString(from, timeFormat, 'Time');
  const endTime = getDateString(to, timeFormat, 'Time');

  return startTime === endTime ? startTime : `${startTime} - ${endTime}`;
};

export const formatDateRange = (from: Date, to: Date, timeFormat: TimeFormatType) => {
  const startMoment = moment(from);
  const endMoment = moment(to);
  const start = prettyDate(startMoment, timeFormat);
  const end = prettyDate(endMoment, timeFormat);
  const startMonth = startMoment.format('MMM').toUpperCase();
  const startDay = startMoment.format('DD');
  const time = start === end ? formatDateTimeRange(startMoment, endMoment, timeFormat) : '';

  return {
    startDay,
    startMonth,
    duration: time === '' ? `${start} - ${end}` : `${start} ・ ${time}`
  };
};
