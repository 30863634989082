import React, { useCallback } from 'react';
import { ScrollView, ViewStyle } from 'react-native';

import { TOOLBAR_MIN_HEIGHT } from '@common/components/ChatScreen/ActionToolbar';

import PanelBase, { type PanelBaseInternalProps } from '../PanelBase';
import PicklistButtons from './PicklistButtons';

type PicklistPanelProps = PanelBaseInternalProps & {
  isMultiSelect?: boolean;
  onOptionPress: (option: Button) => void;
  options: Array<Button>;
  selectedOptions?: Array<string>;
};

const bottomSpace = TOOLBAR_MIN_HEIGHT + 20;

const scrollViewInnerStyles: ViewStyle = {
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  padding: 5,
  paddingBottom: bottomSpace
};

const PicklistPanel = ({ isMultiSelect = false, onOptionPress, options = [], selectedOptions = [], ...props }: PicklistPanelProps) => {
  const isOptionSelected = useCallback(
    ({ text }) => {
      if (isMultiSelect) {
        return selectedOptions.includes(text);
      }

      return false;
    },
    [isMultiSelect, selectedOptions]
  );

  return (
    <PanelBase {...props} testID="picklist-panel">
      <ScrollView contentContainerStyle={scrollViewInnerStyles} keyboardShouldPersistTaps="always">
        <PicklistButtons isOptionSelected={isOptionSelected} onOptionPress={onOptionPress} options={options} />
      </ScrollView>
    </PanelBase>
  );
};

export default PicklistPanel;
