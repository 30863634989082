import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { getDependantOptions } from '@common/utils';
import { useGetParentDependency } from '@common/hooks';

const useDependantFieldUpdater = (field: FieldType, dependantAllowedValues: DependantAllowedValues = []): void => {
  const form = useForm();
  const { name: fieldName = '', allowedValues: defaultOptions = [] } = field;
  const { parentDependency } = useGetParentDependency(fieldName, dependantAllowedValues);
  // Subscription listener for the parent field (if present), when the parent changes
  // we evaluate whether we should update `fieldName` to a new value.
  useEffect(() => {
    let unsubscribe: any;

    if (fieldName && parentDependency) {
      unsubscribe = form.subscribe(
        () => {
          const formState = form.getState();

          if (formState.dirty && formState.active === parentDependency.parentField) {
            const currentFieldState = form.getFieldState(fieldName);
            const currentParentFieldState = form.getFieldState(parentDependency.parentField);

            const options = getDependantOptions(parentDependency, currentParentFieldState?.value, defaultOptions);

            if (
              (currentFieldState && !currentFieldState.value) ||
              (currentFieldState && currentFieldState.value && !options.includes(currentFieldState.value))
            ) {
              form.change(fieldName, options?.[0] ?? undefined);
            }
          }
        },
        {
          values: true
        }
      );
    }

    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [defaultOptions, fieldName, form, parentDependency]);
};

export default useDependantFieldUpdater;
