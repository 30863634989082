import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@common/store';
import { apiInterceptor } from '@common/store/interceptors';
import { getToken } from '@common/store/reducers/auth';
import { isWeb, pathOr, trackEvent } from '@common/utils';
import { Badge } from '@common/utils/badge';
import { Notification as InAppMessageNotification } from '@common/utils/inAppMessage';
import { getNotifications as getNotificationsRequest, triggerNotification } from '@common/webService';

interface NotificationsState {
  areMore: boolean;
  currentPage: number;
  isLoading: boolean;
  isProcessingNotification: boolean;
  notifications: RawNotifications;
  unreadCount: number;
  visibleNotification: VisibleNotification;
}

const initialState: NotificationsState = {
  areMore: true,
  currentPage: 0,
  isLoading: false,
  isProcessingNotification: false,
  notifications: [],
  unreadCount: 0,
  visibleNotification: { title: '', body: '' }
};

// Slice
export const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationsRequest: state => {
      state.isLoading = true;
    },
    notificationsSuccess: (state, action) => {
      state.notifications = [...state.notifications, ...action.payload];
      state.isLoading = false;
    },
    notificationsError: state => {
      state.isLoading = false;
    },
    incrementNotificationsPage: state => {
      state.currentPage++;
    },
    finishedNotificationsPagination: state => {
      state.areMore = false;
    },
    markNotificationAsRead: (state, action) => {
      state.notifications = state.notifications.map(n => {
        if (n.id === action.payload) {
          return {
            ...n,
            is_read: true
          };
        }

        return n;
      });
    },
    setUnreadNotificationCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    setVisibleNotification: (state, action) => {
      state.visibleNotification = action.payload;
    },
    clearVisibleNotification: state => {
      state.visibleNotification = { title: '', body: '' };
    },
    setIsProcessingNotification: (state, action) => {
      state.isProcessingNotification = action.payload;
    },
    resetNotifications: () => initialState
  }
});

// Actions
export const {
  notificationsRequest,
  notificationsSuccess,
  notificationsError,
  incrementNotificationsPage,
  finishedNotificationsPagination,
  markNotificationAsRead,
  setUnreadNotificationCount,
  setVisibleNotification,
  clearVisibleNotification,
  setIsProcessingNotification,
  resetNotifications
} = notifications.actions;

// Thunks
export const loadNotifications = createAsyncThunk<void, void, { state: RootState }>('notifications/loadNotifications', async (_, { dispatch, getState }) => {
  const state = getState();
  const { isLoading } = state.notifications;

  if (!isLoading) {
    dispatch(notificationsRequest());
    const apiRequest = apiInterceptor(getNotificationsRequest);

    try {
      const token = getToken(state);
      const currentPage = getCurrentPage(state);
      const {
        data: { rows = [], unread = 0 }
      } = await apiRequest(token, currentPage, 10);

      dispatch(notificationsSuccess(rows));
      dispatch(setUnreadNotificationCount(unread));

      Badge.setBadge(unread);

      if (rows.length) {
        dispatch(incrementNotificationsPage());
      } else {
        dispatch(finishedNotificationsPagination());
      }
    } catch (error) {
      dispatch(notificationsError());
    }
  }
});

export const updateBadge = createAsyncThunk<void, void, { state: RootState }>('notifications/updateBadge', async (_, { getState }) => {
  const state = getState();
  const apiRequest = apiInterceptor(getNotificationsRequest);

  try {
    const token = getToken(state);
    if (token) {
      const {
        data: { unread = 0 }
      } = await apiRequest(token, 0, 10);

      Badge.setBadge(unread);
    }
  } catch (error) {
    console.log('error', error);
  }
});

export const selectNotification = createAsyncThunk<Promise<any>, number, { state: RootState }>(
  'notifications/selectNotification',
  async (id, { dispatch, getState }) => {
    dispatch(setIsProcessingNotification(true));
    const apiRequest = apiInterceptor(triggerNotification);
    const state = getState();
    const token = getToken(state);
    const currentUnread = getUnreadNotificationsCount(state);
    try {
      const response = await apiRequest(token, id);

      dispatch(markNotificationAsRead(id));

      const unread = pathOr(currentUnread, ['data', 'unread'], response);
      dispatch(setUnreadNotificationCount(unread));

      Badge.setBadge(unread);

      const messages = pathOr([], ['data', 'savedMessages'], response);

      return messages;
    } catch (error) {
      // handled by interceptor
    } finally {
      dispatch(setIsProcessingNotification(false));
    }
  }
);

export const reloadNotifications = createAsyncThunk<void, void, { state: RootState }>('notifications/reloadNotifications', (_, { dispatch }) => {
  dispatch(resetNotifications());
  dispatch(loadNotifications());
});

export const hideInAppNotification = createAsyncThunk<void, void, { state: RootState }>('notifications/hideInAppNotification', (_, { dispatch }) => {
  if (!isWeb) {
    dispatch(clearVisibleNotification());
    // @ts-ignore
    InAppMessageNotification.hide();
  }
});

export const notificationRedirect = createAsyncThunk<void, void, { state: RootState }>('notifications/notificationRedirect', () => {
  trackEvent('event-notification-click');
  // navigate({ name: NOTIFICATIONS_TAB });
});

export const displayInAppNotification = createAsyncThunk<void, VisibleNotification, { state: RootState }>(
  'notifications/displayInAppNotification',
  (notification, { dispatch }) => {
    trackEvent('event-notification-arrived');
    if (!isWeb) {
      dispatch(setVisibleNotification(notification));
      // @ts-ignore
      InAppMessageNotification.show();
    } else {
      // @ts-ignore
      InAppMessageNotification.show(notification, () => dispatch(notificationRedirect()));
    }
  }
);

// Selectors
export const getCurrentPage = (state: RootState) => pathOr(1, ['notifications', 'currentPage'], state);

export const getNotifications = (state: RootState) => {
  const rawNotifications = pathOr<RawNotifications>([], ['notifications', 'notifications'], state);
  const areMore = pathOr(true, ['notifications', 'areMore'], state);
  const isLoading = pathOr(false, ['notifications', 'isLoading'], state);
  const isProcessingNotification = pathOr(false, ['notifications', 'isProcessingNotification'], state);

  return {
    areMore,
    isLoading,
    isProcessingNotification,
    notifications: rawNotifications.map(n => ({
      title: n.title,
      body: n.body,
      date: n.createdAt,
      isRead: n.is_read,
      id: n.id,
      payload: n.payload
    }))
  };
};

export const getUnreadNotificationsCount = (state: RootState) => pathOr(0, ['notifications', 'unreadCount'], state);

export const getVisibleNotification = (state: RootState) => pathOr({ title: '', body: '' }, ['notifications', 'visibleNotification'], state);

export const geNotificationsLoading = (state: RootState) => pathOr(false, ['notifications', 'isLoading'], state);

export default notifications.reducer;
