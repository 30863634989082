import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { LoginScreen, WalkthroughScreen, WebViewScreen } from '@common/layouts';
import { RootStackParamList } from '@common/navigation';
import { LOGIN_SCREEN, WALKTHROUGH_SCREEN, WEBVIEW_SCREEN } from '@common/navigation/routes';

const Stack = createStackNavigator<RootStackParamList>();

const AuthNavigator = () => (
  <Stack.Navigator initialRouteName={LOGIN_SCREEN} screenOptions={{ headerShown: false }}>
    <Stack.Screen name={WALKTHROUGH_SCREEN} component={WalkthroughScreen} />
    <Stack.Screen name={LOGIN_SCREEN} component={LoginScreen} options={{ gestureEnabled: false }} />
    <Stack.Screen
      name={WEBVIEW_SCREEN}
      component={WebViewScreen}
      initialParams={{
        padTabs: false
        // isLogin: true,
      }}
    />
  </Stack.Navigator>
);

export default AuthNavigator;
