import { set, lensPath, pipe, inc, over, flip, times, pathOr } from '@common/utils';
import { Action } from '@legacy/ducks';

// State
export type SearchState = {
  areMore: Array<boolean>;
  currentPage: Array<number>;
  filters: Array<FilterGroupShape>;
  isLoading: Array<boolean>;
  results: Array<ResultsShape>;
  initialResults: ResultsShape[];
  unselectedResults: Array<ResultsShape>;
  selectedTab: number;
  tabs: TabsShape;
  textSearch: Array<string>;
  isExpanded: boolean;
};

const initialState: SearchState = {
  areMore: [],
  currentPage: [],
  filters: [],
  isLoading: [],
  results: [],
  initialResults: [],
  unselectedResults: [],
  selectedTab: 0,
  tabs: [],
  textSearch: [],
  isExpanded: false
};

// Reducer
const search = (state: SearchState = initialState, action: Action) => {
  const { selectedTab, results, filters, tabs, unselectedResults } = state;
  const currentResults = results[selectedTab] || [];
  const currentUnselectedResults = unselectedResults?.[selectedTab] || [];
  const currentFilters = filters[selectedTab] || [];

  const setAllCurrentPage = set(lensPath(['currentPage']));
  const incrementPage = over(lensPath(['currentPage', selectedTab]), inc);
  const setCurrentPage = set(lensPath(['currentPage', selectedTab]));
  const setAllIsLoading = set(lensPath(['isLoading']));
  const setIsLoading = set(lensPath(['isLoading', selectedTab]));
  const setAllResults = set(lensPath(['results']));
  const setResults = set(lensPath(['results', selectedTab]));
  const setInitialResults = set(lensPath(['initialResults']));
  const setUnselectedResults = set(lensPath(['unselectedResults', selectedTab]));
  const setTabs = set(lensPath(['tabs']));
  const setSelectedTab = set(lensPath(['selectedTab']));
  const setAllTextSearch = set(lensPath(['textSearch']));
  const setAllAreMore = set(lensPath(['areMore']));
  const setAreMore = set(lensPath(['areMore', selectedTab]));
  const setAllFilters = set(lensPath(['filters']));
  const setFilters = set(lensPath(['filters', selectedTab]));
  const setIsExpanded = set(lensPath(['isExpanded']));

  switch (action.type) {
    case 'Search/REQUEST':
      return setIsLoading(true, state);

    case 'Search/SUCCESS':
    case 'Search/ERROR':
      return setIsLoading(false, state);
    case 'Search/TOGGLE_UNSELECTED_RESULT': {
      const row = action.payload;
      if (row.postback?.fields?.[0].lookupSelected) {
        row.postback.fields[0].lookupSelected = false;
      } else {
        row.postback.fields[0].lookupSelected = true;
      }

      const toggledObject = currentUnselectedResults.find(value => value?.id === row.id);
      if (toggledObject) {
        const filteredList = currentUnselectedResults.filter(result => result.id !== row.id);
        return setUnselectedResults(filteredList, state);
      }

      return setUnselectedResults([...currentUnselectedResults, row], state);
    }

    case 'Search/INITIAL_RESULTS':
      return setInitialResults(action.payload, state);

    case 'Search/PREPOPULATE_RESULTS': {
      const resultsArrays = pathOr([], ['payload'], action);
      const createSlots = flip<any, any>(times)(resultsArrays.length);

      return pipe(
        x => setAllResults(resultsArrays, x),
        x =>
          setAllAreMore(
            createSlots(() => false),
            x
          ),
        x =>
          setAllCurrentPage(
            createSlots(() => 0),
            x
          ),
        x =>
          setAllFilters(
            createSlots(() => []),
            x
          ),
        x =>
          setAllTextSearch(
            createSlots(() => ''),
            x
          ),
        x =>
          setAllIsLoading(
            createSlots(() => false),
            x
          )
      )(state);
    }

    case 'Search/APPEND_RESULTS': {
      const { results: _results, isEditing } = action.payload;
      const searchResults = [...currentResults, ..._results];
      // Avoids duplicated search results ROL-1404
      let uniqueSearched = searchResults.filter((result, index, array) => array.findIndex(t => t.id === result.id) === index);
      if (!isEditing) {
        uniqueSearched = uniqueSearched.map(res => ({
          ...res,
          isSelected: (!!res.postback.fields?.[0]?.crossTableId && !currentUnselectedResults.some(resu => resu.id === res.id)) || res.isSelected
        }));
      }

      return setResults(uniqueSearched, state);
    }

    case 'Search/CLEAR_UNSELECTED_RESULTS':
      return setResults([...currentResults.filter(({ isSelected }) => isSelected)], state);

    case 'Search/CLEAR_RESULTS':
      return setResults([], state);

    case 'Search/UPDATE_RESULT': {
      const { rowIndex, row } = action.payload;
      return set(lensPath(['results', selectedTab, rowIndex]), row, state);
    }

    case 'Search/INCREMENT_PAGE':
      return incrementPage(state);

    case 'Search/RESET_PAGINATION':
      return pipe(
        x => setCurrentPage(0, x),
        x => setAreMore(true, x)
      )(state);

    case 'Search/FINISHED_PAGINATION':
      return setAreMore(false, state);

    case 'Search/DEFINE_TABS':
      return setTabs(action.payload, state);

    case 'Search/CHANGE_TAB': {
      return setSelectedTab(action.payload, state);
    }

    case 'Search/CHANGE_TEXT_SEARCH': {
      const createSlots = flip<any, any>(times)(tabs.length);
      return setAllTextSearch(
        createSlots(() => action.payload),
        state
      );
    }

    case 'Search/CLEAR_TEXT_SEARCH': {
      const createSlots = flip<any, any>(times)(tabs.length);
      return setAllTextSearch(
        createSlots(() => ''),
        state
      );
    }

    case 'Search/SET_FILTERS':
      return setFilters(action.payload, state);

    case 'Search/RESET_FILTERS':
      return setFilters(action.payload, state);

    case 'Search/REMOVE_FILTER': {
      const newFilters = [...currentFilters.slice(0, action.payload), ...currentFilters.slice(action.payload + 1)];

      return setFilters(newFilters, state);
    }

    case 'Search/IS_EXPANDED': {
      return setIsExpanded(action.payload, state);
    }

    case 'Search/RESET_ALL':
      return initialState;

    default:
      return state;
  }
};

export default search;
