const ASCII_SEPARATOR = '\uD800\uDC00';
const ASCII_SEPARATOR_STRING = String.fromCodePoint(ASCII_SEPARATOR.codePointAt(0) ?? 0);

const getLabel = (value: string) => {
  if (value.includes(ASCII_SEPARATOR_STRING)) {
    return value.split(ASCII_SEPARATOR_STRING)[0];
  }
  // Needed to handle old values (we can remove it in the future)
  if (value.includes('|')) {
    return value.split('|')[0];
  }

  return value;
};

const useCustomReplaceValue = () => (initialValue: any, type?: FieldTypes) => {
  // The picklist values comes as "label|value" we want to show the label
  // However core uses the complete string "label|value"
  if (type === 'MultiPicklist') {
    const values = initialValue?.split(';');
    return values?.map((value: any) => getLabel(value)).join(';') ?? [];
  }

  const label = typeof initialValue === 'string' ? getLabel(initialValue) : undefined;

  return label || initialValue;
};

export default useCustomReplaceValue;
