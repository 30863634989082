import React, { useState, useEffect } from 'react';
import { Animated } from 'react-native';
import styled, { css } from 'styled-components/native';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { boolToNum, isAndroid, statusBarHeight } from '@common/utils';

type HeaderProps = {
  dateLabel?: string;
  hideContents?: boolean;
};

const BADGE_HEIGHT = 24;
const BADGE_TOP_OFFSET = 8;
export const CHAT_HEADER_HEIGHT = statusBarHeight + BADGE_HEIGHT + BADGE_TOP_OFFSET;

const BackgroundWrap = styled.View.attrs({ pointerEvents: 'none' })`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  align-items: center;
`;

const StatusBarCover = styled.View`
  width: 100%;
  height: ${statusBarHeight}px;
  background: ${colors.white};
`;

const DateBadge = styled.View`
  margin-top: ${BADGE_TOP_OFFSET}px;
  height: ${BADGE_HEIGHT}px;
  justify-content: center;
  border-radius: 12px;
  background: ${rgba(colors.grey6, 0.95)};
  box-shadow: 0 1px 6px ${rgba(colors.grey4, 0.1)};
  ${isAndroid &&
  css`
    elevation: 1;
    shadow-color: ${colors.black};
  `}
`;

const DateLabel = styled.Text`
  font-family: ${fonts.medium};
  font-size: 12px;
  color: ${rgba(colors.grey4, 0.5)};
  padding: 0 9px;
`;

const Header = ({ dateLabel, hideContents = false }: HeaderProps) => {
  const [internalDateLabel, setInternalDateLabel] = useState(dateLabel);
  const showDate = !!dateLabel && !hideContents;
  const [dateVisibility] = useState(new Animated.Value(boolToNum(showDate)));

  useEffect(() => {
    const toValue = boolToNum(showDate);

    Animated.parallel([
      Animated.timing(dateVisibility, {
        toValue,
        duration: showDate ? 250 : 200,
        useNativeDriver: true
      })
    ]).start();
  }, [dateVisibility, showDate]);

  useEffect(() => {
    if (dateLabel) {
      setInternalDateLabel(dateLabel);
    }
  }, [dateLabel]);

  const animatedComponentStyle = {
    opacity: dateVisibility,
    transform: [
      {
        translateY: dateVisibility.interpolate({
          inputRange: [0, 1],
          outputRange: [-20, 0]
        })
      }
    ]
  };

  return (
    <BackgroundWrap>
      <StatusBarCover />
      <DateBadge as={Animated.View} style={animatedComponentStyle}>
        <DateLabel>{internalDateLabel}</DateLabel>
      </DateBadge>
    </BackgroundWrap>
  );
};

export default Header;
