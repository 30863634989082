import React, { useMemo } from 'react';
import styled from 'styled-components/native';

import { colors } from '@common/theme';
import { EmptyState, type EmptyStateProps, LoadingOverlay, TableTypeC, type TableTypeCProps, TOOLBAR_MIN_HEIGHT } from '@common/components';

import PanelBase, { type PanelBaseInternalProps } from '../PanelBase';
import Filters, { type FiltersProps } from './Filters';
import FiltersToggle from './FiltersToggle';
import Tabs, { type TabsProps } from './Tabs';

type SearchPanelProps = PanelBaseInternalProps & {
  emptyState: EmptyStateProps;
  filters: FiltersProps;
  isLoading?: boolean;
  isSending?: boolean;
  onFiltersTogglePress: () => void;
  table: TableTypeCProps;
  tabs: TabsProps;
};

const bottomSpace = TOOLBAR_MIN_HEIGHT + 24;

const TopRowOuter = styled.View`
  padding: 2px 10px 0;
`;

const TopRowInner = styled.View`
  flex-direction: row;
  align-items: center;
`;

const TopRowDivider = styled.View`
  background: ${colors.grey4};
  opacity: 0.2;
  height: 1px;
  border-radius: 0.5px;
`;

const NoResults = styled(EmptyState)`
  padding-bottom: ${bottomSpace}px;
`;

const SearchPanel = ({
  emptyState,
  filters,
  isLoading = false,
  isSending = false,
  onFiltersTogglePress,
  table: { tableRows = [], ...tableProps },
  tabs,
  ...props
}: SearchPanelProps) => {
  const filtersToggleProps = {
    active: filters.visible,
    disabled: !filters.filters || filters.filters.length === 0
  };

  const searchTableProps = useMemo(
    () => ({
      ...tableProps,
      listProps: {
        contentContainerStyle: { paddingBottom: bottomSpace }
      }
    }),
    [tableProps, bottomSpace]
  );

  const areTableRows = !!tableRows && !!tableRows.length;
  const showEmptyState = !isSending && !isLoading && !areTableRows;
  const showResults = areTableRows;

  const unselectedTableRows = tableRows?.filter(row => !row.isSelected) ?? [];
  const showLoading = isLoading && !unselectedTableRows.length;

  return (
    <PanelBase
      {...props}
      testID="search-panel"
      topComponents={() => (
        <TopRowOuter>
          <TopRowInner>
            <Tabs {...tabs} />
            <FiltersToggle {...filtersToggleProps} onPress={onFiltersTogglePress} />
          </TopRowInner>
          <TopRowDivider />
        </TopRowOuter>
      )}
    >
      <Filters {...filters} />

      {showEmptyState && <NoResults {...emptyState} />}

      {showResults && <TableTypeC {...searchTableProps} tableRows={tableRows} />}

      <LoadingOverlay visible={showLoading} showBackdrop={false} style={{ bottom: bottomSpace }} id="search-panel" />
    </PanelBase>
  );
};

export default SearchPanel;
