import React from 'react';
import { TouchableWithoutFeedback, View, ViewProps } from 'react-native';
import styled, { css } from 'styled-components/native';
import { rgba } from 'polished';

import { colors } from '@common/theme';
import { isWeb } from '@common/utils';
import { toDataTestIdFriendly } from '@common/utils';
import { Button } from '@common/components';

type HeaderButtonsProps = {
  buttons?: ButtonsShape;
  onBackgroundPress?: () => void;
} & ViewProps;

const Wrap = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px;
  padding-bottom: 5px;
  z-index: 2;
`;

type HeaderButtonProps = {
  isLast: boolean;
};

const HeaderButton = styled(Button)<HeaderButtonProps>(
  ({ isLast }) => css`
    box-shadow: 0 1px 3px ${rgba(colors.grey4, 0.15)};
    margin-right: ${!isLast ? '5px' : 0};
  `
);

const HeaderButtons = ({ buttons = [], onBackgroundPress = () => {}, ...props }: HeaderButtonsProps) => {
  const lastButtonIndex = buttons ? buttons.length - 1 : 0;

  if (!buttons.length) {
    return null;
  }

  return (
    <View {...props}>
      <TouchableWithoutFeedback onPress={onBackgroundPress}>
        <Wrap>
          {buttons.map(({ disabled, label, onPress, variant }, index) => {
            const friendlyLabel = toDataTestIdFriendly(label);
            // Use onPressOut to avoid conflict with pan responder
            const onPressProps = isWeb ? { onPress } : { onPressOut: onPress };

            return (
              <HeaderButton
                testID={`header-button-${friendlyLabel}`}
                key={label}
                disabled={disabled}
                isLast={lastButtonIndex === index}
                variant={variant}
                trackEventId={`chat_panel_${friendlyLabel}_click`}
                {...onPressProps}
              >
                {label}
              </HeaderButton>
            );
          })}
        </Wrap>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default HeaderButtons;
