import React from 'react';
import styled from 'styled-components/native';

import { colors } from '@common/theme';

import PillSegment, { PillSegmentProps } from './PillSegment';
import { PILL_BORDER_RADIUS } from './PillSegment/SegmentDivider';

export type FilterPillProps = {
  segments: PillShape;
} & Omit<PillSegmentProps, 'index' | 'label' | 'value'>;

const PillOuterWrap = styled.View`
  align-self: flex-start;
  flex-direction: row;
  align-items: stretch;
  border-width: 2px;
  border-color: ${colors.primary};
  border-radius: ${PILL_BORDER_RADIUS};
  background: ${colors.white};
  padding-left: 5px;
`;

const FilterPill = ({ segments = [], ...props }: FilterPillProps) => {
  const visibleSegments =
    segments.filter((segment, index) => {
      if (index === 0) {
        return !!segment.value;
      }

      const previousSegmentValues = segments.reduce((acc, seg, i) => (i >= index ? acc : !!seg.value), true);

      return previousSegmentValues;
    }) || [];

  const lastSegment = visibleSegments.length ? visibleSegments[visibleSegments.length - 1] : null;
  const lastSegmentTextValue = lastSegment?.value || '';

  return (
    <PillOuterWrap>
      {segments.map((segment, index) => {
        /* Display the "remove" icon if:
            - This segment has a "value", AND:
              - This segment is the previous to last, and the last
                item doesn't have a value, OR:
              - This segment is the last one
          */
        const showRemoveIcon = !!segment.value && ((index === visibleSegments.length - 2 && !lastSegmentTextValue) || index === visibleSegments.length - 1);

        const isLastChildSegment = index === visibleSegments.length - 1;

        return (
          <PillSegment
            key={segment.id}
            label={segment.label}
            last={isLastChildSegment}
            value={segment.value ?? ''}
            showAddIcon={!segment.value}
            showRemoveIcon={showRemoveIcon}
            showSelectArrow={segment.allowSelect}
            index={index}
            {...props}
          />
        );
      })}
    </PillOuterWrap>
  );
};

export default FilterPill;
