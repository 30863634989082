import React, { Fragment, useMemo } from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import { useForm } from 'react-final-form';
import { FieldState } from 'final-form';
import DateTimePicker from 'react-native-modal-datetime-picker';
import styled, { css } from 'styled-components/native';

import { append, isWeb, without, toDate } from '@common/utils';
import { Picker } from '@common/components';
import { useCustomReplaceValue, useFieldOptions } from '@common/hooks';

import PickerHeader from './PickerHeader';
import MultiSelect from './MultiSelect';

type PickersProps = {
  dependantAllowedValues: DependantAllowedValues;
  fields: Array<FieldType>;
  initialValues: Record<string, string>;
};

const fixed = `
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: white;
  opacity: 0.1;
`;

const Fixed = styled.View`
  ${fixed}
`;

const OuterWrap = styled(Fixed)`
  align-items: center;
  justify-content: center;
`;

const Touchable = styled(TouchableWithoutFeedback)`
  ${fixed}
`;

const TouchableOverlay = ({ input }: { input: FieldState<any> }) => (
  <Touchable onPress={input.blur}>
    <OuterWrap />
  </Touchable>
);

const Container = styled(View)<{ isMulti?: boolean }>(
  ({ isMulti }) => css`
    z-index: 101;
    height: ${isMulti ? '40%' : 'auto'};
  `
);

const Pickers = ({ dependantAllowedValues, fields, initialValues }: PickersProps) => {
  const form = useForm();
  const formState = form.getState();
  const activeFieldName = formState.active ?? '';
  const activeField = useMemo(() => fields.find(field => field.name === activeFieldName) ?? ({} as FieldType), [fields, activeFieldName]);
  const { fieldOptions } = useFieldOptions(activeField, dependantAllowedValues);
  const replaceValue = useCustomReplaceValue();

  if (!activeFieldName) {
    return null;
  }

  const input = form.getFieldState(activeFieldName);
  if (!input) {
    return null;
  }

  if (!isWeb && activeField && ['Date', 'DateTime'].includes(activeField.type)) {
    const dateValue = toDate(input.value);
    const mode = activeField.type.toLowerCase();
    // const titleSuffix = activeField.type === 'DateTime' ? ' and time' : '';
    // const pickerTitle = `Select a date${titleSuffix}`;

    return (
      <DateTimePicker
        date={input.value ? dateValue : new Date()}
        onConfirm={date => {
          input.blur();
          input.change(date);
        }}
        onCancel={input.blur}
        mode={mode as PickerMode}
        confirmTextIOS="Done"
        isVisible
        // titleIOS={pickerTitle}
        isDarkModeEnabled={false}
      />
    );
  }

  const cancel = () => {
    input.change(initialValues[activeFieldName]);
    input.blur();
  };

  if (!isWeb && activeField && ['Picklist', 'QuickReply'].includes(activeField.type)) {
    return (
      <Fragment>
        <TouchableOverlay input={input} />
        <Container>
          <PickerHeader title={activeFieldName} onCancelPress={cancel} onDonePress={input.blur} />
          <Picker selectedValue={input.value || activeField.value} onValueChange={input.change}>
            {fieldOptions.map(item => (
              <Picker.Item label={replaceValue(item)} value={item} key={item} />
            ))}
          </Picker>
        </Container>
      </Fragment>
    );
  }

  if (activeField && activeField.type === 'MultiPicklist') {
    return (
      <Fragment>
        <TouchableOverlay input={input} />
        <Container isMulti>
          <MultiSelect
            onCancelPress={cancel}
            onItemPress={prop => {
              const safeValue = input.value ?? [];
              const newValue = safeValue.includes(prop) ? without([prop], safeValue) : append(prop, safeValue);
              input.change(newValue);
            }}
            onMultiSelectionDone={input.blur}
            list={fieldOptions}
            title={activeFieldName}
            value={input.value ?? []}
          />
        </Container>
      </Fragment>
    );
  }

  return null;
};

export default Pickers;
