import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Keyboard } from 'react-native';
import { Form } from 'react-final-form';
import styled, { css } from 'styled-components/native';
import { is } from 'ramda';
import { isArray } from 'lodash';
// Common
import { iphoneXHomeBarHeight, isAndroid, filterHiddenConfirmationValues, toDataTestIdFriendly, lensPath, view, isWeb } from '@common/utils';
import { colors } from '@common/theme';
import { Header, ScreenWrap, Field, Pickers, KeyboardAwareScrollView, SearchPanel, LoadingOverlay } from '@common/components';
import { ToolbarProvider } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';
import { useSelector as useCommonSelector } from '@common/hooks';
import { getToken } from '@common/store/reducers/auth';
import { useSearchLookup } from '../../../common/hooks';
import useEditConfiguration from '@common/hooks/useEditConfiguration';
// Legacy
import { EDIT_SCREEN } from '@legacy/app/navigation/routes';
import { ChatStackScreenProps } from '@legacy/app/navigation/types';
import { getContextUpdated, getIsSearching } from '@legacy/ducks/edition/selectors';
import {
  getAreSelectedRows,
  getAreUnselectedRows,
  getIsLoading,
  getIsMultiSelection,
  getSelectedSearchResults,
  getUnselectedSearchResults
} from '@legacy/ducks/search/selectors';
import { setContextUpdated, refreshContext, getDependentPicklists } from '@legacy/ducks/edition/actions';
import { changeTab, selectTableRow } from '@legacy/ducks/search/actions';
import { getConfirmationFields } from '@legacy/ducks/messages/selectors';
// Legacy Chat Utils
import { createLookupsFromParents } from './utils';

import Toolbar from './ChatScreen/Toolbar';

const InnerScrollWrap = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    padding: 15,
    paddingBottom: 25 + iphoneXHomeBarHeight
  },
  extraHeight: 120
})`
  flex: 1;
`;

const InnerShadowWrap = styled.View`
  background: ${colors.white};
  border-radius: 5px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  padding: 20px 15px 0;
  ${isAndroid &&
  css`
    elevation: 1;
    shadow-color: ${colors.black};
  `}
`;

const headerRightOffset = {
  paddingRight: 10
};

type EditScreenProps = ChatStackScreenProps<typeof EDIT_SCREEN>;

const updateConfirmationFields = (lookupField: FieldType | undefined, processedFields: FieldType[], selectedRow?: TableRow) => {
  if (!selectedRow || !lookupField) {
    return processedFields;
  }

  const lookParents = lookupField.parent && !is(Array, lookupField.parent) ? [lookupField.parent] : lookupField.parent;
  const parents = processedFields.filter(p => lookParents?.some(lp => lp.entity === p.name));
  return processedFields.reduce((acc: FieldType[], field) => {
    if (field.type === 'MultiLookup' && (field.relatedLookupApi === lookupField.api || parents?.some(parent => parent.api === field.relatedLookupApi))) {
      return [
        ...acc,
        {
          ...field,
          id: '',
          value: '',
          idsAlreadySelected: '',
          crossTableIdToDelete: '',
          crossTableId: ''
        }
      ];
    }

    return acc;
  }, []);
};

const EditScreen = ({ navigation, route }: EditScreenProps) => {
  const { callback, dependantAllowedValues, fields } = route.params;
  const dispatch = useDispatch();

  const token = useCommonSelector(getToken);
  const isSearching = useSelector(getIsSearching);
  const isLoadingSearch = useSelector(getIsLoading);
  const contextUpdated = useSelector(getContextUpdated);
  const isMultiSelection = useSelector(getIsMultiSelection);
  const areSelectedRows = useSelector(getAreSelectedRows);
  const areUnselectedRows = useSelector(getAreUnselectedRows);
  const unselectedRows = useSelector(getUnselectedSearchResults);
  const selectedRows = useSelector(getSelectedSearchResults);
  const confirmationFields = useSelector(getConfirmationFields);

  const [processedFields, setProcessedFields] = useState(fields.filter(filterHiddenConfirmationValues));
  const [intent, setIntent] = useState({ name: undefined });
  const [initialValues, setInitialValues] = useState({});
  const [isEditionLoading, setIsEditionLoading] = useState(false);
  const [formValues, setFormValues] = useState({} as Record<string, string>);
  const [dependentPicklists, setDependentPicklists] = useState(dependantAllowedValues);

  const { triggerSearch, tableData, tabHeaders, cancelSearch, selectedTab, searchPanelVisible, searchFilters, lookupField } = useSearchLookup();
  const editConfiguration = useEditConfiguration();

  const onTableRowPress = useCallback((index: number) => dispatch(selectTableRow(index)), [dispatch]);

  useEffect(() => {
    const fetchAll = async () => {
      try {
        setIsEditionLoading(true);
        const { data: result, intent: actionIntent } = await editConfiguration.linkSlotsWithLookupConfig(fields);
        if (!dependentPicklists) {
          const { dependentPicklists: dp } = await getDependentPicklists();
          setDependentPicklists(dp);
        }

        setIntent(actionIntent);
        setProcessedFields(result ?? fields);
        setIsEditionLoading(false);
      } catch {
        setIsEditionLoading(false);
      }
    };

    if (token) {
      fetchAll();
    }
  }, [token, fields]);

  useEffect(() => {
    if (processedFields?.length) {
      const processMultiValue = (value: string | string[]) => (isArray(value) ? value : value.split(';'));
      setInitialValues(
        processedFields.reduce(
          (values, field) => ({
            ...values,
            [field.name]: field.type === 'MultiPicklist' && !!field.value ? processMultiValue(field.value) : field.value
          }),
          {}
        )
      );
    }
  }, [processedFields]);

  const updateFields = useCallback(
    updatedContextFields =>
      processedFields.map(field => {
        const updatedEntity = updatedContextFields.find((entity: FieldType) => entity.entity === field.name);
        if (updatedEntity) {
          return { ...field, ...updatedEntity };
        }

        if (field.entity && formValues[field.entity]) {
          field.value = formValues[field.entity];
        }

        return field;
      }),
    [processedFields, formValues]
  );

  const headerButtons = useCallback((): ButtonsShape => {
    const cancelBtn = { label: 'Cancel', onPress: cancelSearch, variant: 'grey' };
    const doneButton = {
      label: 'Done',
      disabled: !areSelectedRows && !areUnselectedRows,
      onPress: async () => {
        if (areSelectedRows || areUnselectedRows) {
          if (lookupField?.type === 'MultiLookup') {
            let _selectedRows: ResultsShape = [...selectedRows];
            if (areUnselectedRows) {
              _selectedRows = [..._selectedRows, ...unselectedRows];
            }

            const _fields = _selectedRows.reduce((acc, row) => [...acc, ...row.postback.fields], [] as TableRowsShape);

            try {
              cancelSearch();
              setIsEditionLoading(true);
              const { updated } = await refreshContext({
                fields: _fields,
                messageText: 'Done Selected',
                isDone: true,
                edit: true
              });

              const updatedFields = updateFields(updated);

              setProcessedFields(updatedFields);
              dispatch(setContextUpdated(true));
              setIsEditionLoading(false);
            } catch {
              cancelSearch();
              setIsEditionLoading(false);
            }
          } else {
            cancelSearch();
          }
        }
      }
    };

    if (searchPanelVisible) {
      return (isMultiSelection ? [cancelBtn, doneButton] : [cancelBtn]) as ButtonsShape;
    }

    return [];
  }, [areSelectedRows, areUnselectedRows, unselectedRows, cancelSearch, isMultiSelection, lookupField, processedFields, selectedRows]);

  const onRowPress = useCallback(
    async (row, index) => {
      if (isMultiSelection) {
        onTableRowPress(index);
      } else {
        setIsEditionLoading(true);
        const updatedEditFields = updateConfirmationFields(lookupField, processedFields, row[index]);
        cancelSearch();
        try {
          const rowFields = view(lensPath(['postback', 'fields']), row[index]);
          const { updated } = await refreshContext({
            fields: [...rowFields, ...updatedEditFields],
            messageText: 'Edited',
            edit: true
          });

          const updatedFields = updateFields(updated);

          setProcessedFields(updatedFields);
          dispatch(setContextUpdated(true));
          setIsEditionLoading(false);
        } catch {
          cancelSearch();
          setIsEditionLoading(false);
        }
      }
    },
    [processedFields, lookupField, dispatch, setProcessedFields, updateConfirmationFields, cancelSearch, dispatch, isMultiSelection]
  );

  const onBackPress = useCallback(() => {
    if (contextUpdated) {
      refreshContext({
        fields: confirmationFields,
        messageText: 'Edited',
        edit: true,
        back: true
      });

      dispatch(setContextUpdated(false));
    }

    Keyboard.dismiss();
    navigation.goBack();
  }, [navigation, confirmationFields, contextUpdated]);

  const onSubmit = useCallback(
    (values: any) => {
      Keyboard.dismiss();
      const mappedFields = createLookupsFromParents(processedFields ?? [], values);

      if (callback) {
        callback(mappedFields);
        dispatch(setContextUpdated(false));
      }

      navigation.goBack();
    },
    [processedFields, callback]
  );

  const trigger = useCallback(
    (field: FieldType) => {
      triggerSearch(field);
    },
    [triggerSearch]
  );

  const render = useCallback(
    ({ handleSubmit, values }: any) => {
      setFormValues(values);
      return (
        <ScreenWrap>
          <LoadingOverlay id="edition-loading" visible={isEditionLoading} showBackdrop={true} />
          <Header onLeftPress={onBackPress} onRightPress={handleSubmit} center="Edit" centerStyle={headerRightOffset} left="Back" right="Save" />

          <InnerScrollWrap>
            <InnerShadowWrap>
              {processedFields.map(field => {
                const isLoading =
                  (field.type === 'Lookup' || field.type === 'MultiLookup') && lookupField && field.api === lookupField?.api ? isSearching : false;

                return (
                  <Field
                    disabled={isSearching}
                    testID={`edit-field-${toDataTestIdFriendly(field.name)}`}
                    key={field.name}
                    isLoading={isLoading}
                    dependantAllowedValues={dependentPicklists}
                    intent={intent.name}
                    field={field}
                    callback={() => {
                      (field.type === 'Lookup' || field.type === 'MultiLookup') && trigger(field);
                    }}
                  />
                );
              })}
            </InnerShadowWrap>
          </InnerScrollWrap>

          <Pickers dependantAllowedValues={dependentPicklists} fields={processedFields} initialValues={initialValues} />
        </ScreenWrap>
      );
    },
    [processedFields, initialValues, dependentPicklists, isSearching, lookupField, isEditionLoading, tableData, isWeb]
  );

  const setSelectedTab = useCallback(index => dispatch(changeTab(index)), [dispatch]);
  const tabsProps = useMemo(
    () => ({
      activeTabId: selectedTab,
      onTabPress: setSelectedTab,
      tabs: tabHeaders.map((label, index) => ({
        label,
        id: index
      }))
    }),
    [selectedTab, setSelectedTab, tabHeaders]
  );

  return (
    <>
      <Form initialValues={initialValues} onSubmit={onSubmit} render={render} />
      <SearchPanel
        testID="search-panel-edition"
        emptyState={{}}
        expandedDefault={true}
        filters={searchFilters}
        headerButtons={headerButtons()}
        isLoading={isLoadingSearch || isSearching}
        onFiltersTogglePress={() => {}}
        table={{
          tableRows: tableData,
          onRowPress
        }}
        tabs={tabsProps}
        isVisible={searchPanelVisible}
      />

      {searchPanelVisible && (
        <ToolbarProvider>
          <Toolbar isVisible={searchPanelVisible} />
        </ToolbarProvider>
      )}
    </>
  );
};

export default EditScreen;
