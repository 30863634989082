import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from '@common/store';
import { SpeechToText } from '@common/utils/watson';

interface SttState {
  isSttListening: boolean;
  isLoadingStt: boolean;
  isSttTranscribing: boolean;
}

const initialState: SttState = {
  isSttListening: false,
  isLoadingStt: false,
  isSttTranscribing: false
};

// Slice
export const stt = createSlice({
  name: 'stt',
  initialState,
  reducers: {
    setSttListening: (state, action) => {
      state.isSttListening = action.payload;
    },
    setSttLoading: (state, action) => {
      state.isLoadingStt = action.payload;
    },
    setSttTranscribing: (state, action) => {
      state.isSttTranscribing = action.payload;
    },
    resetSttData: () => initialState
  }
});

// Actions
export const { resetSttData, setSttListening, setSttLoading, setSttTranscribing } = stt.actions;
// Selectors
export const getSttListening = (state: RootState): boolean => !!state.stt?.isSttListening;
export const getSttLoading = (state: RootState): boolean => !!state.stt?.isLoadingStt;
export const getSttTranscribing = (state: RootState): boolean => !!state.stt?.isSttTranscribing;
// Thunks
export const stopSttThunk = createAsyncThunk<void, void, { state: RootState }>('stt/stopStt', async (_, { dispatch, getState }) => {
  const {
    stt: { isSttListening, isLoadingStt, isSttTranscribing }
  } = getState();

  if (isSttListening) {
    SpeechToText.stopStreaming();
    dispatch(setSttListening(false));
  }

  if (isLoadingStt) {
    dispatch(setSttLoading(false));
  }

  if (isSttTranscribing) {
    dispatch(setSttTranscribing(false));
  }
});

export default stt.reducer;
