import { isWeb } from '@common/utils';

export const colors: Colors = {
  black: '#000000',
  grey: '#f1eff0',
  grey2: '#807f7f',
  grey3: '#2b2a2a',
  grey4: '#292929',
  grey5: '#a1a1a1',
  grey6: '#f1f1f1',
  grey7: '#dfdfdf',
  grey8: '#9d9d9d',
  grey9: '#bbbbbb',
  primary: '#1e147d',
  primaryDark: '#20195d',
  primaryLight: '#00dcff',
  primaryContrast: '#1e10a3',
  red: '#ff4444',
  red2: '#d34545',
  white: '#ffffff'
};

const fontFallback = (font: string): string => (isWeb ? `'${font}'` : font);

export const fonts: Fonts = {
  regular: fontFallback('SFUIText-Regular'),
  light: fontFallback('SFUIText-Light'),
  medium: fontFallback('SFUIText-Medium'),
  bold: fontFallback('SFUIText-Bold')
};
