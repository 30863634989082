import React from 'react';
import { GestureResponderEvent, View } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '@common/theme';
import { Icon } from '@common/components';

type ActionIconProps = {
  onPress?: (e: GestureResponderEvent) => void;
  type?: 'add' | 'remove';
};

const IconWrap = styled.TouchableOpacity`
  align-self: stretch;
  justify-content: center;
  padding: 0 9px 0 2px;
  margin: 0 2px;
`;

const ActionIcon = ({ onPress = () => {}, type = 'remove' }: ActionIconProps) => {
  const isRemove = type === 'remove';
  const icon = isRemove ? 'remove-circle-outline' : 'add-circle-outline';
  const wrapProps = isRemove ? { activeOpacity: 0.5, onPress } : { as: View };

  return (
    <IconWrap {...wrapProps}>
      <Icon name={icon} size={18} color={colors.primary} />
    </IconWrap>
  );
};

export default ActionIcon;
