import React, { useEffect } from 'react';
import { AppState, Linking, StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import qs from 'query-string';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DeepLinking from 'react-native-deep-linking';
import SplashScreen from 'react-native-splash-screen';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { useSelector, useDispatch } from '@common/hooks';
import store, { commonContext } from '@common/store';
import { oAuthError, oAuthSuccessRedirect, recoverSession, WEB_IS_AWAITING_REDIRECT } from '@common/store/reducers/auth';
import { getStatusBarStyle } from '@common/store/reducers/ui';
import { hideInAppNotification, notificationRedirect, getVisibleNotification, reloadNotifications, updateBadge } from '@common/store/reducers/notifications';
import { updateAppState } from '@common/store/reducers/appState';
import { AppRoute } from '@common/navigation';
import { replace } from '@common/navigation/utils';
import { WALKTHROUGH_SCREEN } from '@common/navigation/routes';
import { isChromeExtension, isWeb, pathOr, walkthroughStorageKey } from '@common/utils';
import { Notification } from '@common/utils/inAppMessage';
import { messaging } from '@common/utils/firebase';
import { Refresh } from '@common/utils/refresh';
import { InAppNotification } from '@common/components';

const persistor = persistStore(store);

if (!isWeb) {
  messaging().setBackgroundMessageHandler(async () => {
    const state = store.getState();
    if (state.auth.token) {
      if (!state.notifications.isLoading) {
        store.dispatch(reloadNotifications());
      }
    } else {
      store.dispatch(updateBadge());
    }
    return Promise.resolve();
  });
}

/* Initial redirecting on app launch:
  ◆ native: Check for walkthrough or recover session
  ◆ web: Recover session
*/
const initRedirect = async () => {
  const { dispatch } = store;

  if (isWeb) {
    const { query } = qs.parseUrl(window.location.href);
    const token = query['redirect-token'];

    if (token) {
      const tokenParam = token.toString();
      dispatch(oAuthSuccessRedirect(tokenParam));
    } else {
      dispatch(recoverSession());
      AsyncStorage.removeItem(WEB_IS_AWAITING_REDIRECT);
    }
  } else {
    const hasViewedWalkthrough = await AsyncStorage.getItem(walkthroughStorageKey);
    if (hasViewedWalkthrough !== 'true') {
      replace(WALKTHROUGH_SCREEN);
      SplashScreen.hide();
    } else {
      dispatch(recoverSession());
    }
  }
};

const NotificationComponent = (props: any) => {
  const dispatch = useDispatch();
  const { title, body } = useSelector(getVisibleNotification);
  const onNotificationPress = () => {
    dispatch(hideInAppNotification());
    dispatch(notificationRedirect());
  };

  return (
    <Notification
      customComponent={<InAppNotification {...props} title={title} body={body} />}
      onPress={onNotificationPress}
      duration={5000}
      hideStatusBar={false}
    />
  );
};

const ConnectedStatusBar = (props: any) => {
  const statusBarStyle = useSelector(getStatusBarStyle);

  return <StatusBar {...props} barStyle={statusBarStyle} translucent backgroundColor="transparent" />;
};

const Root = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    initRedirect();

    // AppState change event listener
    const appStateSubscription = AppState.addEventListener('change', appState => {
      dispatch(updateAppState(appState));
    });

    // Configure deeplinks (for auth)
    const linkingSubscription = Linking.addEventListener('url', ({ url }) => {
      Linking.canOpenURL(url).then(supported => {
        if (supported) {
          DeepLinking.evaluateUrl(url);
        }
      });
    });
    DeepLinking.addScheme('rollio-app://');
    DeepLinking.addRoute('/auth/:token', ({ token }) => dispatch(oAuthSuccessRedirect(token)));

    if (!isWeb) {
      // Schedule task to reload notifications periodically
      Refresh.start(
        'reloadNotifications',
        () => {
          dispatch(reloadNotifications());
        },
        120000 // 2 minutes
      );
    }

    if (isWeb && !isChromeExtension) {
      const sw = navigator.serviceWorker;
      if (sw) {
        sw.onmessage = ({ data }) => {
          if (data && data.type === 'ROLLIO_SW/ON_CLICK_NOTIFICATION') {
            store.dispatch(reloadNotifications());
          }
        };
      }
    }

    const handleWebMessageEvent = ({ data = {} }) => {
      const type = pathOr<string>('', ['type'], data);
      if (type === 'ROLLIO/AUTH_ERROR' || type === 'ROLLIO/AUTH_RESET') {
        dispatch(oAuthError());
      }
    };

    if (isChromeExtension) {
      window.addEventListener('message', handleWebMessageEvent, false);
    }

    return () => {
      if (appStateSubscription) {
        appStateSubscription.remove();
      }

      if (linkingSubscription) {
        linkingSubscription.remove();
      }

      if (isChromeExtension) {
        window.removeEventListener('message', handleWebMessageEvent, false);
      }

      if (!isWeb) {
        Refresh.stop('reloadNotifications');
      }
    };
  }, []);

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <AppRoute />
        {!isWeb && <NotificationComponent />}
        <ConnectedStatusBar animated />
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

const App = ({ isHeadless }: { isHeadless?: number }) => {
  useEffect(() => {
    if (isHeadless) {
      SplashScreen.hide();
    }
  }, [isHeadless]);

  if (isHeadless) {
    return null;
  }

  return (
    <Provider store={store} context={commonContext}>
      <PersistGate persistor={persistor}>
        <Root />
      </PersistGate>
    </Provider>
  );
};

export default App;
