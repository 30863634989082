import React, { useEffect, useMemo, useState } from 'react';
import { Animated } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors, fonts } from '@common/theme';

type TabBadgeProps = {
  isVisible?: boolean;
  value?: number;
};

type BadgeWrapProps = {
  labelLength: number;
};

const BadgeWrap = styled.View<BadgeWrapProps>(
  ({ labelLength }) => css`
    align-self: center;
    background: ${colors.red};
    height: 14px;
    min-width: 14px;
    border-radius: 4px;
    position: absolute;
    top: -2px;
    right: ${labelLength * -2}px;
    justify-content: center;
    padding: 1px 3px;
  `
);

const BadgeLabel = styled.Text`
  color: ${colors.white};
  font-family: ${fonts.medium};
  font-size: 10px;
  text-align: center;
`;

const formatBadgeLabel = (count?: number): string => {
  if (count) {
    return count < 100 ? `${count}` : '99+';
  }

  return '';
};

const TabBadge = ({ isVisible = false, value }: TabBadgeProps) => {
  const [animatedBadgeVisibility] = useState(new Animated.Value(0));
  const [showBadge, setShowBadge] = useState(false);

  const wrapStyles = useMemo(
    () => ({
      opacity: animatedBadgeVisibility,
      transform: [
        {
          translateY: animatedBadgeVisibility.interpolate({
            inputRange: [0, 1],
            outputRange: [5, 0]
          })
        }
      ]
    }),
    [animatedBadgeVisibility]
  );

  const computedValue = useMemo(() => formatBadgeLabel(value), [value]);

  useEffect(() => {
    setShowBadge(isVisible);

    if (isVisible) {
      Animated.spring(animatedBadgeVisibility, {
        toValue: 1,
        // duration: 250,
        bounciness: 12,
        useNativeDriver: true
      }).start();
    } else {
      animatedBadgeVisibility.setValue(0);
    }
  }, [animatedBadgeVisibility, isVisible]);

  if (!showBadge) {
    return null;
  }

  return (
    <BadgeWrap as={Animated.View} labelLength={computedValue.length} style={wrapStyles}>
      <BadgeLabel>{computedValue}</BadgeLabel>
    </BadgeWrap>
  );
};

export default TabBadge;
