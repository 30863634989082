import React, { createContext, RefObject, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLatestMessageType } from '@legacy/ducks/messages/selectors';
import { TOOLBAR_MIN_HEIGHT } from '@common/components';
import { useStt } from '@common/hooks';
import { messageTypes } from '@common/utils';
import { StyledTextInput } from 'styled-components/types';
import { getIsEdition } from '@legacy/ducks/edition/selectors';

export type ToolbarDetails = {
  animatedHeight: number;
  minHeight?: number;
  isListening: boolean;
  isTranscribing: boolean;
  inputRef?: RefObject<StyledTextInput>;
  placeholder: string;
  variant: ToolbarVariants;
  transcript?: string;
  isLoading?: boolean;
  toolbarType: messageTypes;
  isEditing: boolean;
};

interface IToolbarDispatcher {
  setAnimatedHeight: (value: number) => void;
  startStt: (inputValue?: string) => void;
  stopStt: () => void;
  stopAndResetStt: () => void;
  resetStt: () => void;
  focus: () => void;
}

const ToolbarContext = createContext<ToolbarDetails>({
  animatedHeight: TOOLBAR_MIN_HEIGHT,
  toolbarType: messageTypes.TEXT,
  isLoading: false,
  isTranscribing: false,
  isListening: false,
  variant: 'default',
  placeholder: 'What can I help you with?',
  isEditing: false
});

const ToolbarDispatchContext = createContext<IToolbarDispatcher>({
  setAnimatedHeight: () => {},
  startStt: () => {},
  stopStt: () => {},
  stopAndResetStt: () => {},
  resetStt: () => {},
  focus: () => {}
});

const ToolbarProvider = ({ children }: any) => {
  const [animatedHeight, setAnimatedHeight] = useState(TOOLBAR_MIN_HEIGHT);
  const { isListening, isLoadingStt, startStt, stopStt, stopAndResetStt, transcript, resetStt, isTranscribingStt } = useStt();
  const inputRef = useRef<StyledTextInput>(null);
  const isEditing = useSelector(getIsEdition);
  const toolbarType = useSelector(getLatestMessageType);
  const placeholder = useMemo(() => {
    if (toolbarType === messageTypes.SEARCH || isEditing) {
      return 'Who are we searching for?';
    }

    if ([messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(toolbarType)) {
      return 'What are we looking for?';
    }

    return 'What can I help you with?';
  }, [toolbarType, isEditing]);

  const variant: ToolbarVariants = useMemo(() => {
    if ([messageTypes.PICKLIST, messageTypes.SEARCH, messageTypes.MULTI_PICKLIST].includes(toolbarType) || isEditing) {
      return 'search';
    }

    return 'default';
  }, [toolbarType, isEditing]);

  const isLoading = useMemo(() => {
    if (![messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(toolbarType)) {
      return isLoadingStt;
    }
    return false;
  }, [isLoadingStt, toolbarType]);

  const isTranscribing = useMemo(() => isTranscribingStt, [isTranscribingStt]);

  const focus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const dispatcher = useMemo(
    () => ({
      setAnimatedHeight,
      startStt,
      stopStt,
      stopAndResetStt,
      focus,
      resetStt
    }),
    [setAnimatedHeight, startStt, stopStt, stopAndResetStt, focus, resetStt]
  );

  const value = useMemo(
    () => ({ inputRef, animatedHeight, placeholder, isListening, isLoading, transcript, variant, toolbarType, isTranscribing, isEditing }),
    [inputRef, animatedHeight, placeholder, isListening, isLoading, transcript, variant, toolbarType, isTranscribing, isEditing]
  );

  return (
    <ToolbarContext.Provider value={value}>
      <ToolbarDispatchContext.Provider value={dispatcher}>{children}</ToolbarDispatchContext.Provider>
    </ToolbarContext.Provider>
  );
};

export { ToolbarProvider, ToolbarContext, ToolbarDispatchContext };
