import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Animated, Keyboard, Platform, TextInput as RNTextInput } from 'react-native';
import styled from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';
import qs from 'query-string';

import rollioLogo from '@common/images/LogInScreenImages/logo.png';
import salesforceLogo from '@common/images/LogInScreenImages/SFicon.png';
import { isWeb, isSalesforceWebTab, iphoneXHomeBarHeight, statusBarHeight, webStorageKeys } from '@common/utils';
import Toast from '@common/utils/toast';
import { getGenericPassword } from '@common/utils/keychain';
import { colors, fonts } from '@common/theme';
import { LoadingOverlay, KeyboardAwareScrollView, ScreenWrap, TextInput, Checkbox, TouchableWithEvent } from '@common/components';
import { RootStackScreenProps } from '@common/navigation';
import { LOGIN_SCREEN, WEBVIEW_SCREEN } from '@common/navigation/routes';
import { useDispatch, useFirebaseListener, useSelector } from '@common/hooks';
import { login, getIsLoading } from '@common/store/reducers/auth';

const LoginScrollWrap = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    paddingTop: statusBarHeight,
    paddingBottom: iphoneXHomeBarHeight
  },
  keyboardShouldPersistTaps: 'always',
  extraHeight: 30
})``;

const InnerWrap = styled.View`
  align-items: center;
  padding: 10px 10px 30px;
`;

const RollioLogo = styled.Image.attrs({ source: rollioLogo })`
  resize-mode: contain;
  width: 209px;
  height: 51px;
  margin: 45px auto;
`;

const TextHeader = styled.Text`
  font-family: ${fonts.regular};
  margin-bottom: 40px;
  padding: 0 8%;
  color: ${colors.grey4};
  font-size: 15px;
  text-align: center;
`;

const SfLogo = styled.Image.attrs({ source: salesforceLogo })`
  resize-mode: contain;
  width: 90px;
  height: 60px;
  margin-bottom: 48px;
`;

const LoginForm = styled.View`
  width: 80%;
  max-width: 300px;
  padding: 10px;
  margin-bottom: 45px;
`;

const LoginFieldSpace = styled.View`
  margin-bottom: 10px;
`;

const FormButton = styled.TouchableOpacity.attrs({ activeOpacity: 0.75 })`
  background: ${colors.primary};
  padding: 8px 30px;
  border-radius: 9999px;
  margin-top: 16px;
  align-self: center;
`;

const FormButtonLabel = styled.Text`
  color: ${colors.white};
  font-family: ${fonts.medium};
  font-size: 18px;
  text-align: center;
`;

const FooterWrap = styled.View`
  flex-direction: row;
`;

const FooterText = styled.Text`
  font-family: ${fonts.regular};
  color: ${colors.grey4};
  font-size: 12px;
`;

const FooterLink = styled.Text`
  color: ${colors.primaryDark};
  text-decoration-line: underline;
  font-size: 12px;
`;

const missingUsername = 'Please enter your Salesforce login';

const prepareAuthForm = async () => {
  if (!isWeb) {
    const keychainDetails = await getGenericPassword();
    const keychainExists = !!keychainDetails && keychainDetails.username !== null;

    return {
      username: keychainExists ? keychainDetails.username : ''
    };
  }
  document.cookie = 'username=thisIsATest';
  const test = localStorage.getItem(webStorageKeys.loginEmail);
  return {
    username: test || ''
  };
};

const formAccessibilityProps = Platform.select<StyledViewProps>({
  web: { accessibilityRole: 'button' },
  default: {}
});

const useInputFocus = () => {
  const inputRef = useRef<RNTextInput>(null);
  const focusInput = useCallback(() => {
    if (inputRef && inputRef.current && typeof inputRef.current.focus === 'function') {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return { inputRef, focusInput };
};

const webInputAttrs = isWeb ? { testID: 'email', nativeID: 'username' } : {};
const webButtonAttrs = isWeb ? { testID: 'login-action' } : {};

type LoginScreenProps = RootStackScreenProps<typeof LOGIN_SCREEN>;

const LoginScreen = ({ navigation }: LoginScreenProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);

  const loginWith = useCallback(args => dispatch(login(args)), [dispatch]);

  const [username, setUsername] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { inputRef: usernameInputRef, focusInput: focusUsernameInput } = useInputFocus();
  const [animatedWrapVisibility] = useState(new Animated.Value(0));
  const { login: loginViaFirebase } = useFirebaseListener(loginWith);

  const onLinkPress = useCallback(
    (header, url) => {
      if (!isWeb) {
        navigation.navigate(WEBVIEW_SCREEN, {
          header,
          url
        });
      } else {
        window.open(url, '_blank');
      }
    },
    [navigation]
  );

  const onTermsPress = useCallback(() => onLinkPress('Terms', 'https://www.rollio.ai/terms-of-use/'), [onLinkPress]);

  const onPrivacyPress = useCallback(() => onLinkPress('Privacy Policy', 'https://www.rollio.ai/privacy-policy'), [onLinkPress]);

  const toggleRememberMe = useCallback(() => setRememberMe(remember => !remember), []);

  const loginMethod = useCallback(
    async (un: string, remember: boolean) => {
      if (isSalesforceWebTab) {
        return loginViaFirebase(un);
      }

      return loginWith({ username: un, saveCredentials: remember });
    },
    [loginWith, loginViaFirebase]
  );

  const submitLoginForm = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if (username) {
      try {
        await loginMethod(username, rememberMe);
      } catch (error: any) {
        if (error && error.message) {
          Toast(error.message);
        }
      }
    } else {
      focusUsernameInput();
      Toast(missingUsername, 'center');
    }
  }, [focusUsernameInput, isLoading, loginMethod, rememberMe, username]);

  const onLoginButtonPress = useCallback(() => {
    Keyboard.dismiss();

    submitLoginForm();
  }, [submitLoginForm]);

  const wrapStyles = useMemo(
    () => ({
      opacity: animatedWrapVisibility,
      transform: [
        {
          translateY: animatedWrapVisibility.interpolate({
            inputRange: [0, 1],
            outputRange: [20, 0]
          })
        }
      ]
    }),
    [animatedWrapVisibility]
  );

  useEffect(() => {
    prepareAuthForm().then(creds => {
      setUsername(creds.username);
      setRememberMe(!!creds.username.length);

      if (isSalesforceWebTab) {
        const { query } = qs.parseUrl(window.location.href);

        if (query.username) {
          const usernameParam = query.username.toString();

          setUsername(usernameParam);
          loginMethod(usernameParam, true);
        }
      }
    });
  }, [loginMethod]);

  useEffect(() => {
    Animated.spring(animatedWrapVisibility, {
      toValue: 1,
      // duration: 600,
      bounciness: 10,
      useNativeDriver: true
    }).start();
  }, [animatedWrapVisibility]);

  return (
    <ScreenWrap>
      <LoginScrollWrap>
        <InnerWrap as={Animated.View} style={wrapStyles}>
          <RollioLogo />

          <TextHeader accessibilityRole="header" aria-level="2">
            Let Rollio take care of your Salesforce, while you spend time with your client
          </TextHeader>

          <SfLogo />

          <LoginForm {...formAccessibilityProps}>
            <LoginFieldSpace>
              <TextInput
                placeholder="Salesforce Login"
                value={username}
                onChange={setUsername}
                onSubmitEditing={submitLoginForm}
                keyboardType="email-address"
                textContentType="username"
                returnKeyType="next"
                inputRef={usernameInputRef}
                {...webInputAttrs}
              />
            </LoginFieldSpace>

            <LoginFieldSpace>
              <Checkbox checked={rememberMe} onPress={toggleRememberMe}>
                Remember me
              </Checkbox>
            </LoginFieldSpace>

            <FormButton onPress={onLoginButtonPress} accessibilityRole="button" as={TouchableWithEvent} trackEventId="login_auth_click" {...webButtonAttrs}>
              <FormButtonLabel>LOG IN</FormButtonLabel>
            </FormButton>
          </LoginForm>

          <FooterWrap>
            <TouchableWithEvent onPress={onTermsPress} trackEventId="login_terms_click">
              <FooterLink>Terms</FooterLink>
            </TouchableWithEvent>
            <FooterText> and </FooterText>
            <TouchableWithEvent onPress={onPrivacyPress} trackEventId="login_privacy_click">
              <FooterLink>Privacy Policy</FooterLink>
            </TouchableWithEvent>
          </FooterWrap>
        </InnerWrap>
      </LoginScrollWrap>

      <LoadingOverlay id="login" visible={isLoading} />
    </ScreenWrap>
  );
};

export default LoginScreen;
