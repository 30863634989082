class ApiError extends Error {
  response: any;

  status?: number;

  constructor(message: string, response?: any, status?: number) {
    super(message);
    this.response = response;
    this.status = status;
  }
}

export type ApiResponse = Promise<any>;
type ApiRequestUrl = string;
type ApiRequestToken = string;
type ApiRequestOptions = RequestInit;

const BEARER_STR = 'Bearer ';
const extractedToken = (headers: Headers) => {
  const authHeader = headers.get('Authorization');
  if (authHeader) {
    return authHeader.startsWith(BEARER_STR) ? authHeader.substring(BEARER_STR.length) : authHeader;
  }

  return '';
};

const isValidToken = (token?: ApiRequestToken): boolean => typeof token === 'string' && !!token.length;

const authHeader = (token?: ApiRequestToken): HeadersInit => {
  if (isValidToken(token) && token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
};

const baseFetch = (url: ApiRequestUrl, { headers = {}, ...options }: ApiRequestOptions = {}) =>
  fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers
    },
    ...options
  });

export const apiRequest = async (url: ApiRequestUrl, options?: ApiRequestOptions): ApiResponse => {
  const response = await baseFetch(url, options);
  if (!response.ok) {
    let text = await response.text();
    try {
      const json = JSON.parse(text);
      if (typeof json.message === 'string') {
        text = json.message;
      }
    } catch (err) {
      throw new ApiError('JSON response parsing error', response, response.status);
    }

    throw new ApiError(text, response, response.status);
  }

  const json = await response.json();
  return {
    ...json,
    authToken: extractedToken(response.headers)
  };
};

export const authorizedApiRequest = (url: ApiRequestUrl, token?: ApiRequestToken, { headers = {}, ...options }: ApiRequestOptions = {}): ApiResponse => {
  if (isValidToken(token)) {
    return apiRequest(url, {
      headers: {
        ...authHeader(token),
        ...headers
      },
      ...options
    });
  }

  return Promise.resolve({});
};

export const authorizedFileRequest = async (url: ApiRequestUrl, token?: ApiRequestToken): ApiResponse => {
  if (isValidToken(token)) {
    const response = await baseFetch(url, {
      headers: {
        'cache-control': 'no-cache',
        ...authHeader(token)
      }
    });

    if (!response.ok) {
      throw new ApiError('', response, response.status);
    }

    return response.blob();
  }

  return Promise.resolve({});
};

export const authorizedApiRequestText = async (
  url: ApiRequestUrl,
  token?: ApiRequestToken,
  { headers = {}, ...options }: ApiRequestOptions = {}
): ApiResponse => {
  if (isValidToken(token)) {
    const response = await baseFetch(url, {
      headers: {
        ...authHeader(token),
        ...headers
      },
      ...options
    });
    const text = await response.text();

    if (!response.ok) {
      throw new ApiError('Error trying to get response data', response, response.status);
    }

    return {
      text,
      authToken: extractedToken(response.headers)
    };
  }

  return Promise.resolve({});
};
