import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { lensPath, view, pathOr } from '@common/utils';
import { SearchPanel, PanelBaseInternalProps } from '@common/components';
import { useDispatch as useCommonDispatch, useHeaderButtons } from '@common/hooks';
import { focusToolbarInput } from '@common/store/reducers/ui';
import { postMessage } from '@legacy/ducks/messages/actions';
import { getLatestMessage, getIsSending } from '@legacy/ducks/messages/selectors';
import { search, changeTab, incrementPage, selectTableRow, removeFilter, resetSearch } from '@legacy/ducks/search/actions';
import {
  getTabHeaders,
  getIsMultiSelection,
  getAreSelectedRows,
  getSearchResults,
  getFilters,
  getAreMore,
  getIsLoading,
  getSelectedTabId,
  getAreUnselectedRows,
  getUnselectedSearchResults
} from '@legacy/ducks/search/selectors';

const Search = (props: PanelBaseInternalProps) => {
  const dispatch = useDispatch();
  const commonDispatch = useCommonDispatch();
  const areMore = useSelector(getAreMore);
  const areSelectedRows = useSelector(getAreSelectedRows);
  const areUnselectedRows = useSelector(getAreUnselectedRows);
  const unselectedRows = useSelector(getUnselectedSearchResults);
  const filters = useSelector(getFilters);
  const latestMessage = useSelector(getLatestMessage);
  const isLoading = useSelector(getIsLoading);
  const isMultiSelection = useSelector(getIsMultiSelection);
  const isSending = useSelector(getIsSending);
  const selectedTab = useSelector(getSelectedTabId);
  const tableData = useSelector(getSearchResults);
  const tabHeaders = useSelector(getTabHeaders);

  const deleteFilterPill = useCallback(index => dispatch(removeFilter(index)), [dispatch]);
  const loadMore = useCallback(() => {
    dispatch(incrementPage());
    dispatch(search());
  }, [dispatch]);
  const onRefineSearch = useCallback(() => commonDispatch(focusToolbarInput()), [commonDispatch]);
  const onTableRowPress = useCallback(index => dispatch(selectTableRow(index)), [dispatch]);
  const reset = useCallback(() => dispatch(resetSearch()), [dispatch]);
  const sendMessage = useCallback((message: Postback) => dispatch(postMessage(message)), [dispatch]);
  const setSelectedTab = useCallback(index => dispatch(changeTab(index)), [dispatch]);

  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [expandPanel, setExpandPanel] = useState(false);
  const [headerButtons] = useHeaderButtons(latestMessage, {
    disableDone: !areSelectedRows && !areUnselectedRows,
    isMultiSelect: isMultiSelection,
    onCancelPress: () => reset(),
    onDonePress: () => {
      if (areSelectedRows || areUnselectedRows) {
        let selectedRows = tableData.filter(({ isSelected }) => isSelected);
        const lensName = lensPath(['postback', 'fields', 0, 'value']);
        const viewName = view(lensName);
        const selectedNames = selectedRows.map(row => (row.isSelected ? viewName(row) : '')).join(', ');

        if (areUnselectedRows) {
          selectedRows = [...selectedRows, ...unselectedRows];
        }

        const fields = selectedRows.reduce((acc, row) => [...acc, ...row.postback.fields], [] as TableRowsShape);

        sendMessage({
          fields,
          messageText: selectedNames || 'Done Selected',
          isDone: true
        });
        reset();
      }
    },
    onSkipPress: () => reset(),
    sendMessage
  });

  const triggerExpansion = useCallback(() => {
    setExpandPanel(true);
    // Delayed by 1 "frame" to give `useEffect` listeners time to catch the change
    setTimeout(() => setExpandPanel(false), 0);
  }, [setExpandPanel]);

  const onFiltersChange = useCallback(() => {
    if (!filters.length && areFiltersVisible) {
      setAreFiltersVisible(false);
    } else if (filters.length && !areFiltersVisible) {
      setAreFiltersVisible(true);
    }
  }, [areFiltersVisible, filters]);

  useEffect(() => {
    onFiltersChange();
  }, [filters, onFiltersChange]);

  const emptyStateProps = useMemo(
    () => ({
      displayButton: false,
      onButtonPress: () => onRefineSearch()
    }),
    [onRefineSearch]
  );

  const filtersProps = useMemo(
    () => ({
      filters,
      onRemovePress: (_filter: { value: string; label: string }, index: number) => deleteFilterPill(index),
      visible: areFiltersVisible
    }),
    [areFiltersVisible, deleteFilterPill, filters]
  );

  const expandByDefault = useMemo(() => pathOr(false, ['params', 'panelIsExpanded'], latestMessage), [latestMessage]);

  const tableProps = useMemo(
    () => ({
      tableRows: tableData,
      onEndReached: () => {
        if (!isLoading && areMore) {
          loadMore();
        }
      },
      onRowPress: (rows: TableRowsShape, rowIndex: number) => {
        if (isMultiSelection) {
          triggerExpansion();
          onTableRowPress(rowIndex);
        } else {
          const rowFields = view(lensPath(['postback', 'fields']), rows[rowIndex]);
          const name = view(lensPath([0, 'value']), rowFields);

          sendMessage({
            fields: rowFields,
            messageText: name || 'Selected'
          });
          reset();
        }
      }
    }),
    [areMore, isLoading, isMultiSelection, loadMore, onTableRowPress, reset, sendMessage, tableData, triggerExpansion]
  );

  const tabsProps = useMemo(
    () => ({
      activeTabId: selectedTab,
      onTabPress: setSelectedTab,
      tabs: tabHeaders.map((label, index) => ({
        label,
        id: index
      }))
    }),
    [selectedTab, setSelectedTab, tabHeaders]
  );

  return (
    <SearchPanel
      {...props}
      testID="search-panel"
      emptyState={emptyStateProps}
      expandedDefault={expandByDefault}
      filters={filtersProps}
      headerButtons={headerButtons}
      isLoading={isLoading}
      isSending={isSending}
      onFiltersTogglePress={() => setAreFiltersVisible(prev => !prev)}
      table={tableProps}
      tabs={tabsProps}
      triggerExpansion={expandPanel}
    />
  );
};

export default Search;
