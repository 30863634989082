import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Animated, Keyboard, PermissionsAndroid, Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import styled, { css } from 'styled-components/native';

import { boolToNum, messageTypes, nativeAlert, isAndroid, isWeb } from '@common/utils';
import { useSelector as useCommonSelector } from '@common/hooks';
import { ActionToolbar, TOOLBAR_MAX_HEIGHT, TOOLBAR_MIN_HEIGHT } from '@common/components';
import { getFocusToolbarInput } from '@common/store/reducers/ui';
import { postMessage } from '@legacy/ducks/messages/actions';
import { getIsSending } from '@legacy/ducks/messages/selectors';
import { updateSearch } from '@legacy/ducks/picklist/actions';
import { getSearchText } from '@legacy/ducks/picklist/selectors';
import { changeTextSearch, clearUnselectedResults, resetPagination, search } from '@legacy/ducks/search/actions';
import { CHAT_SCREEN } from '@legacy/app/navigation/routes';
import { ToolbarContext, ToolbarDispatchContext } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';

type ToolbarProps = {
  isVisible: boolean;
};

export const TOOLBAR_WRAP_SPACING = 8;
const SPACE_HEIGHT = TOOLBAR_MAX_HEIGHT - TOOLBAR_MIN_HEIGHT;

const ToolbarWrap = styled.View<{ variant: ToolbarVariants }>(
  ({ variant }) => css`
    ${variant === 'search'
      ? () => css`
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        `
      : css``}
    padding: 0 ${TOOLBAR_WRAP_SPACING}px ${TOOLBAR_WRAP_SPACING}px;
  `
);

const Toolbar = ({ isVisible, ...props }: ToolbarProps) => {
  const dispatch = useDispatch();
  // Selectors
  const picklistSearch = useSelector(getSearchText);
  const isSending = useSelector(getIsSending);
  const focusInput = useCommonSelector(getFocusToolbarInput);
  // Hooks
  const route = useRoute();
  const { startStt, stopStt, stopAndResetStt, focus } = useContext(ToolbarDispatchContext);
  const { toolbarType, isEditing, isListening, transcript, inputRef, variant } = useContext(ToolbarContext);
  // State
  const [inputValue, setInputValue] = useState('');
  const [internalSearchText, setInternalSearchText] = useState('');
  const [toolbarOffset] = useState(new Animated.Value(0));
  const [toolbarOpacity] = useState(new Animated.Value(0));
  const [toolbarSpaceHeight] = useState(new Animated.Value(0));
  const [clearInput, setClearInput] = useState(false);
  const [sttInteraction, setSttInteraction] = useState(false);

  const runSearch = useCallback(() => {
    dispatch(clearUnselectedResults());
    dispatch(resetPagination());
    dispatch(search());
  }, [clearUnselectedResults, resetPagination, search]);

  const sendMessage = useCallback(
    (message, hasSttInteraction, transcriptText) => dispatch(postMessage(message, hasSttInteraction, transcriptText)),
    [dispatch]
  );

  const setSearchText = useCallback(string => dispatch(changeTextSearch(string)), [dispatch]);

  const updatePicklistSearch = useCallback(string => dispatch(updateSearch(string)), [dispatch]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(internalSearchText);
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [internalSearchText, setSearchText]);

  const permissionsRequiredAlert = useCallback(() => {
    nativeAlert({
      message: 'Record audio permission required for Speech to Text services.',
      okLabel: 'Settings',
      onOkPress: () => {
        Linking.openSettings();
      },
      title: 'Permission Required'
    });
  }, []);

  const toolbarInputValue = useMemo(() => {
    if (toolbarType === messageTypes.SEARCH || isEditing) {
      return internalSearchText;
    }

    // Empties the internal search text if it has value
    if (internalSearchText !== '') {
      setInternalSearchText('');
    }

    if ([messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(toolbarType)) {
      return picklistSearch;
    }

    return inputValue;
  }, [inputValue, internalSearchText, picklistSearch, toolbarType, isEditing]);

  const updateInputValue = useCallback(
    value => {
      if (toolbarType === messageTypes.SEARCH || isEditing) {
        setInternalSearchText(value);
        return;
      }

      if ([messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(toolbarType)) {
        updatePicklistSearch(value);
        return;
      }

      setInputValue(value);
    },
    [setInternalSearchText, toolbarType, updatePicklistSearch, isEditing]
  );

  const stopSpeechToText = useCallback(() => {
    stopStt();
    focus();
  }, [stopStt]);

  const requestAndroidMicrophonePermissions = useCallback(async () => {
    let permissionGranted = false;
    try {
      const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO, {
        title: 'Rollio Speech to Text Permission',
        message: 'Rollio needs the recording audio permission ' + 'to use the Speech-To-Text functionality.',
        buttonNegative: 'Cancel',
        buttonPositive: 'OK'
      });
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        permissionGranted = true;
      }
    } catch (err) {
      permissionsRequiredAlert();
    }

    return permissionGranted;
  }, [permissionsRequiredAlert]);

  const startSpeechToText = useCallback(async () => {
    let microphonePermissionGranted = true;
    if (isAndroid) {
      microphonePermissionGranted = await requestAndroidMicrophonePermissions();
    }

    if (microphonePermissionGranted) {
      startStt(toolbarInputValue);
      setSttInteraction(true);
    } else {
      permissionsRequiredAlert();
    }
  }, [startStt, permissionsRequiredAlert, requestAndroidMicrophonePermissions, toolbarInputValue]);

  const toolbarInputProps = { ref: inputRef };

  const toolbarWrapStyles = {
    opacity: toolbarOpacity,
    transform: [
      {
        translateY: toolbarOffset.interpolate({
          inputRange: [0, 1],
          outputRange: [10, 0]
        })
      }
    ]
  };

  const onSendPress = useCallback(() => {
    stopAndResetStt();
    sendMessage(toolbarInputValue, sttInteraction, transcript);

    if (sttInteraction) {
      setSttInteraction(false);
    }
  }, [sendMessage, stopAndResetStt, isListening, toolbarInputValue, sttInteraction]);

  const onSearchPress = useCallback(() => {
    if (isListening) {
      stopSpeechToText();
    }
    runSearch();
  }, [isListening, runSearch, stopSpeechToText]);

  useEffect(() => {
    const isChatScreen = route.name === CHAT_SCREEN;

    if (isChatScreen && focusInput) {
      focus();
    }
  }, [route.name, focusInput]);

  useEffect(() => {
    if (isSending) {
      if (isListening) {
        stopSpeechToText();
      }

      if (!clearInput) {
        setInputValue('');
        if (!isWeb) {
          Keyboard.dismiss();
        } else {
          focus();
        }
        setClearInput(true);
      }
    } else {
      setClearInput(false);
    }
  }, [isSending, isListening, stopSpeechToText]);

  useEffect(() => {
    Animated.parallel([
      Animated.spring(toolbarOffset, {
        toValue: boolToNum(isVisible),
        // duration: 350,
        bounciness: 15,
        speed: 4,
        useNativeDriver: true
      }),
      Animated.timing(toolbarOpacity, {
        toValue: boolToNum(isVisible),
        duration: 350,
        useNativeDriver: true
      })
    ]).start();
  }, [isVisible, toolbarOffset, toolbarOpacity]);

  const spaceHeightStyles = useMemo(
    () => ({
      height: toolbarSpaceHeight.interpolate({
        inputRange: [0, 1],
        outputRange: [0, SPACE_HEIGHT]
      })
    }),
    [toolbarSpaceHeight]
  );

  useEffect(() => {
    Animated.timing(toolbarSpaceHeight, {
      toValue: 0,
      duration: isListening ? 160 : 120,
      useNativeDriver: false
    }).start();
  }, [isListening, toolbarSpaceHeight]);

  useEffect(() => {
    updateInputValue(transcript);
  }, [transcript]);

  return (
    <>
      <Animated.View style={spaceHeightStyles} />
      <ToolbarWrap as={Animated.View} style={toolbarWrapStyles} variant={variant} pointerEvents="box-none">
        <ActionToolbar
          {...props}
          inputValue={toolbarInputValue}
          inputProps={toolbarInputProps}
          onInputChange={updateInputValue}
          onMicPress={startSpeechToText}
          onSendPress={onSendPress}
          onSearchPress={onSearchPress}
          onStopPress={stopSpeechToText}
        />
      </ToolbarWrap>
    </>
  );
};

export default Toolbar;
