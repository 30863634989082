import { useMemo } from 'react';

const useGetParentDependency = (fieldName: string, dependantAllowedValues: DependantAllowedValues): { parentDependency: DependantAllowedValue | null } => {
  // A parentDependency will be returned when `fieldName` is controlled by another field
  const parentDependency = useMemo(() => dependantAllowedValues.find(d => d.childField === fieldName) || null, [dependantAllowedValues, fieldName]);

  return { parentDependency };
};

export default useGetParentDependency;
