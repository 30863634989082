import React, { ReactNode } from 'react';
import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';

import { colors } from '@common/theme';
import { isAndroid, statusBarHeight } from '@common/utils';

type HeaderBaseProps = {
  children: ReactNode;
} & StyledViewProps;

const HEADER_INTERNAL_HEIGHT = 46;

const HeaderOuter = styled.View`
  width: 100%;
  padding-top: ${statusBarHeight}px;
  background: ${colors.white};
  box-shadow: 0 1px 6px rgba(71, 71, 71, 0.2);
  z-index: 1;
  ${isAndroid &&
  css`
    elevation: 1;
    shadow-color: ${colors.black};
  `}
`;

const HeaderWrap = styled.View`
  height: ${HEADER_INTERNAL_HEIGHT}px;
  background: ${colors.white};
  flex-direction: row;
  align-items: stretch;
`;

const headerAccessibilityProps = Platform.select<StyledViewProps>({
  web: { accessibilityRole: 'banner' },
  default: {}
});

const HeaderBase = ({ children, ...props }: HeaderBaseProps) => (
  <HeaderOuter {...headerAccessibilityProps} {...props}>
    <HeaderWrap>{children}</HeaderWrap>
  </HeaderOuter>
);

export default HeaderBase;
