import { useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';

import { useKeyboard, useSelector as useCommonSelector } from '@common/hooks';
import { getLastBotMessageHeight } from '@common/store/reducers/ui';
import { isAndroid, statusBarHeight } from '@common/utils';

import { getPanelIsExpanded } from '@legacy/ducks/search/selectors';

import useTabBarHeight from './useTabBarHeight';

type UsePanelHeight = () => {
  heightDifference: number;
  panelHeight: number;
  maxPanelHeight: number;
  minPanelHeight: number;
};

const PANEL_PADDING = 50;
const MAIN_WRAP_PADDING = isAndroid ? 20 : 10;

const usePanelHeight: UsePanelHeight = () => {
  // Hooks
  const { height: windowHeight } = useWindowDimensions();
  const { tabBarHeight } = useTabBarHeight();
  const { keyboardHeight } = useKeyboard();
  // Selectors
  const lastBotMessageHeight = useCommonSelector(getLastBotMessageHeight);
  const isPanelExpanded = useSelector(getPanelIsExpanded);

  const mainWrapHeight = useMemo(
    () => windowHeight - statusBarHeight - (keyboardHeight || isAndroid ? 0 : tabBarHeight) - keyboardHeight - MAIN_WRAP_PADDING,
    [windowHeight, statusBarHeight, tabBarHeight, keyboardHeight]
  );
  const maxPanelHeight = useMemo(() => mainWrapHeight - lastBotMessageHeight, [mainWrapHeight, lastBotMessageHeight]);
  const minPanelHeight = useMemo(() => {
    let minHeight = mainWrapHeight - lastBotMessageHeight - PANEL_PADDING;
    if (minHeight >= maxPanelHeight - lastBotMessageHeight) {
      minHeight = maxPanelHeight - PANEL_PADDING;
    }
    return minHeight;
  }, [mainWrapHeight, lastBotMessageHeight, maxPanelHeight]);
  const panelHeight = useMemo(() => (isPanelExpanded ? maxPanelHeight : minPanelHeight), [minPanelHeight, maxPanelHeight, isPanelExpanded]);

  return { maxPanelHeight, minPanelHeight, panelHeight, heightDifference: maxPanelHeight - minPanelHeight };
};

export default usePanelHeight;
