import React, { useEffect, useMemo, useState } from 'react';
import { Animated } from 'react-native';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';

import { usePanelHeight } from '@common/hooks';
import { messageTypes } from '@common/utils';
import { getIsSending, getLatestMessageType } from '@legacy/ducks/messages/selectors';

import Picklist from './Picklist';
import Search from './Search';
import { getPanelIsExpanded } from '@legacy/ducks/search/selectors';

const PanelSpace = styled.View`
  background: transparent;
`;

const Panels = () => {
  const { minPanelHeight, maxPanelHeight } = usePanelHeight();
  const [panelSpace] = useState(new Animated.Value(0));
  const isSendingMessage = useSelector(getIsSending);
  const panelType = useSelector(getLatestMessageType);
  const isPanelExpanded = useSelector(getPanelIsExpanded);

  const showSearchPanel = useMemo(() => !isSendingMessage && panelType === messageTypes.SEARCH, [isSendingMessage, panelType]);
  const showPicklistPanel = useMemo(
    () => !isSendingMessage && [messageTypes.PICKLIST, messageTypes.MULTI_PICKLIST].includes(panelType),
    [isSendingMessage, panelType]
  );
  const isVisible = useMemo(() => showSearchPanel || showPicklistPanel, [showSearchPanel, showPicklistPanel]);

  useEffect(() => {
    let toValue = 0;
    if (isVisible) {
      toValue = (isPanelExpanded ? maxPanelHeight : minPanelHeight) - 40; // 40 to avoid padding from last message.
    }
    Animated.timing(panelSpace, { toValue, useNativeDriver: false }).start();
  }, [isPanelExpanded, minPanelHeight, isVisible, maxPanelHeight]);

  return (
    <>
      <PanelSpace as={Animated.View} style={{ height: panelSpace }} />
      <Picklist isVisible={showPicklistPanel} />
      <Search isVisible={showSearchPanel} />
    </>
  );
};

export default Panels;
