import { useMemo } from 'react';
import { Keyboard } from 'react-native';

import { cancelConfirmationAlert, isEmpty, pathOr } from '@common/utils';

type Flags = 'isCancel' | 'isDone' | 'isSkip';

const findButton = (buttons: Array<Button>, flag: Flags) => buttons.find(b => b[flag]) || ({} as Button);

type UseHeaderButtons = (
  messageType: MessageType,
  options: {
    disableDone?: boolean;
    isMultiSelect?: boolean;
    onCancelPress?: () => void;
    onDonePress?: () => void;
    onSkipPress?: () => void;
    sendMessage: (postback: Postback) => void;
  }
) => [ButtonsShape];

const useHeaderButtons: UseHeaderButtons = (
  latestMessage,
  { disableDone = false, isMultiSelect = false, onCancelPress = () => {}, onDonePress = () => {}, onSkipPress = () => {}, sendMessage }
) => {
  const messageButtons = useMemo(() => pathOr<Button[]>([], ['buttons'], latestMessage), [latestMessage]);

  const rawCancel = useMemo(() => findButton(messageButtons, 'isCancel'), [messageButtons]);
  const rawDone = useMemo(() => findButton(messageButtons, 'isDone'), [messageButtons]);
  const rawSkip = useMemo(() => findButton(messageButtons, 'isSkip'), [messageButtons]);

  const showCancel = useMemo(() => !isEmpty(rawCancel), [rawCancel]);
  const showDone = useMemo(() => !isEmpty(rawDone) && isMultiSelect, [rawDone, isMultiSelect]);
  const showSkip = useMemo(() => !isEmpty(rawSkip), [rawSkip]);

  const cancelButton = useMemo(
    () => ({
      label: rawCancel.text,
      onPress: () => {
        Keyboard.dismiss();
        cancelConfirmationAlert(() => {
          sendMessage(rawCancel.postback);
          onCancelPress();
        });
      },
      variant: 'grey' as ButtonVariant
    }),
    [onCancelPress, rawCancel, sendMessage]
  );

  const doneButton = useMemo(
    () => ({
      label: rawDone.text,
      disabled: disableDone,
      onPress: onDonePress
    }),
    [disableDone, onDonePress, rawDone]
  );

  const skipButton = useMemo(
    () => ({
      label: rawSkip.text,
      onPress: () => {
        sendMessage(rawSkip.postback);
        onSkipPress();
      },
      variant: 'grey' as ButtonVariant
    }),
    [onSkipPress, rawSkip, sendMessage]
  );

  const headerButtons = useMemo(
    () => [...(showCancel ? [cancelButton] : []), ...(showSkip ? [skipButton] : []), ...(showDone ? [doneButton] : [])],
    [cancelButton, doneButton, skipButton, showCancel, showDone, showSkip]
  );

  return [headerButtons];
};

export default useHeaderButtons;
