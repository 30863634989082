import { useState } from 'react';

import { useDispatch, useSelector } from '@common/hooks';
import { isWeb, nativeAlert, sttModel, sttUrl } from '@common/utils';
import { SpeechToText } from '@common/utils/watson';
import { getToken } from '@common/store/reducers/auth';
import {
  getSttListening,
  getSttLoading,
  getSttTranscribing,
  setSttListening,
  setSttLoading,
  setSttTranscribing,
  stopSttThunk
} from '@common/store/reducers/stt';
import store from '@common/store';

const useStt = () => {
  const [transcript, setTranscript] = useState('');
  // Selectors
  const token = useSelector(getToken);
  const isListening = useSelector(getSttListening);
  const isLoadingStt = useSelector(getSttLoading);
  const isTranscribingStt = useSelector(getSttTranscribing);
  // Dispatcher
  const dispatch = useDispatch();

  // FYI: This stop is only being used for the callback, since we have no access to the global state.
  const stopSttCallback = () => {
    const {
      stt: { isSttListening, isLoadingStt: loading, isSttTranscribing }
    } = store.getState();

    if (isSttListening) {
      SpeechToText.stopStreaming();
      dispatch(setSttListening(false));
    }

    if (loading) {
      dispatch(setSttLoading(false));
    }

    if (isSttTranscribing) {
      dispatch(setSttTranscribing(false));
    }
  };

  const stopStt = () => {
    dispatch(stopSttThunk());
  };

  const stopAndResetStt = () => {
    if (isListening) {
      stopStt();
    }

    setTranscript('');
  };

  const stopSttWeb = () => {
    if (isWeb) {
      window.addEventListener('blur', stopStt);

      return () => {
        window.removeEventListener('blur', stopStt);
      };
    }
  };

  const resetStt = () => {
    dispatch(setSttListening(false));
    dispatch(setSttLoading(false));
    dispatch(setSttTranscribing(false));
    setTranscript('');
  };

  const startStt = (inputValue?: string) => {
    let transcribing = 0;
    dispatch(setSttListening(true));
    dispatch(setSttLoading(true));

    SpeechToText.startStreaming({
      url: sttUrl,
      accessToken: token,
      model: sttModel,
      callback: (error?: any, text?: string, isLoading?: boolean) => {
        if (error) {
          stopSttCallback();
          const alertMessage = 'An error ocurred establishing a connection to the Speech-To-Text service.';

          nativeAlert({
            message: alertMessage,
            messageWeb: `${alertMessage} Retry connection?`,
            okLabel: 'Retry',
            onOkPress: () => startStt(inputValue),
            title: 'Connection Error'
          });

          return;
        }

        if (isLoading && text) {
          dispatch(setSttTranscribing(true));
          dispatch(setSttLoading(true));
        }

        if (!isLoading && !transcribing) {
          // after 1 seconds of inactivity enable the stop btn
          transcribing = +setTimeout(() => {
            dispatch(setSttTranscribing(false));
            dispatch(setSttLoading(false));
          }, 1000);
        }
        // If the user start talking again, then clean the timers
        if (isLoading && transcribing) {
          clearTimeout(transcribing);
          transcribing = 0;
        }

        const toTranscript = `${inputValue?.trim() ?? ''}${inputValue ? ' ' : ''}${text ?? ''}`;
        // Set transcript
        setTranscript(toTranscript);
      }
    });
  };

  return {
    isListening,
    isLoadingStt,
    isTranscribingStt,
    startStt,
    stopAndResetStt,
    stopStt,
    stopSttWeb,
    transcript,
    resetStt
  };
};

export default useStt;
