import { set, lensPath, pathOr } from '@common/utils';
import { Action } from '@legacy/ducks';

// State
export type PicklistState = {
  options: Array<Button>;
  searchText: string;
  selectedOptions: Array<string>;
};

const initialState: PicklistState = {
  options: [],
  searchText: '',
  selectedOptions: []
};

// Reducer
const picklist = (state: PicklistState = initialState, action: Action) => {
  const setSelectedOptions = set(lensPath(['selectedOptions']));

  switch (action.type) {
    case 'Picklist/PREPOPULATE_RESULTS': {
      const optionsArray = pathOr([], ['payload'], action);
      return set(lensPath(['options']), optionsArray, state);
    }

    case 'Picklist/UPDATE_SEARCH':
      return set(lensPath(['searchText']), action.payload, state);

    case 'Picklist/UPDATE_SELECTED_OPTIONS':
      return setSelectedOptions(action.payload, state);

    case 'Picklist/RESET':
      return initialState;

    default:
      return state;
  }
};

export default picklist;
