import React, { useCallback } from 'react';
import { ListRenderItemInfo } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledFlatListProps } from 'styled-components/types';

import { colors } from '@common/theme';

import FilterPill, { FilterPillProps } from './FilterPill';

export type FiltersProps = {
  filters?: PillShape;
  visible?: boolean;
} & Omit<FilterPillProps, 'segments'>;

const filtersWrapPadding = {
  paddingHorizontal: 8,
  paddingTop: 7,
  paddingBottom: 8
};

type FiltersWrapProps = {
  visible: boolean;
};

const FiltersWrap = styled.FlatList.attrs<Partial<StyledFlatListProps<any>>>({
  contentContainerStyle: filtersWrapPadding,
  horizontal: true
})<FiltersWrapProps>(
  ({ visible }) => css`
    background: ${colors.white};
    flex-grow: 0;
    height: ${visible ? '54px' : 0};
  `
);

const ListSeparator = styled.View`
  width: 16px;
`;

const Filters = ({ filters = [], visible = true, ...props }: FiltersProps) => {
  const renderItem = useCallback(({ item }: ListRenderItemInfo<any>) => <FilterPill segments={item} {...props} />, []);
  const keyExtractor = useCallback((item: any) => item.id, []);
  const itemSeparator = useCallback(() => <ListSeparator />, []);

  return (
    <FiltersWrap
      visible={visible}
      data={filters.length ? [filters] : undefined}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={itemSeparator}
    />
  );
};

export default Filters;
