import React from 'react';
import styled from 'styled-components/native';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';

type DateIndicatorProps = {
  label: string;
};

const DateIndicatorWrap = styled.View`
  margin-top: 30px;
  margin-bottom: 6px;
  align-items: center;
`;

const DividerLine = styled.View`
  height: 1px;
  border-radius: 0.5px;
  top: 4.5px;
  width: 200px;
  max-width: 90%;
  background: ${rgba(colors.grey7, 0.6)};
`;

const DateLabel = styled.Text`
  color: ${rgba(colors.grey9, 0.9)};
  font-family: ${fonts.medium};
  font-size: 12px;
  line-height: 12px;
  background: ${colors.white};
  padding: 0 5px;
  z-index: 1;
`;

const DateIndicator = ({ label }: DateIndicatorProps) => (
  <DateIndicatorWrap>
    <DividerLine />
    <DateLabel>{label}</DateLabel>
  </DateIndicatorWrap>
);

export default DateIndicator;
