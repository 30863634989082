import React from 'react';
import styled from 'styled-components/native';
import { rgba } from 'polished';

import { colors, fonts } from '@common/theme';
import { toDataTestIdFriendly } from '@common/utils';

type TableColumnsHeaderProps = {
  headers: Array<string>;
};

const ColumnHeadersWrap = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 6px 5px;
  background: ${colors.white};
  box-shadow: 0 6px 4px ${rgba(colors.grey4, 0.075)};
  z-index: 2;
`;

const ColumnHeader = styled.Text`
  flex: 1;
  text-align: center;
  font-family: ${fonts.medium};
  font-size: 12px;
  color: ${colors.grey4};
  opacity: 0.8;
  padding: 0 1px;
`;

const TableColumnsHeader = ({ headers = [] }: TableColumnsHeaderProps) => (
  <ColumnHeadersWrap>
    {headers.map(h => (
      <ColumnHeader key={h} testID={`table-header-${toDataTestIdFriendly(h)}`} numberOfLines={1}>
        {h}
      </ColumnHeader>
    ))}
  </ColumnHeadersWrap>
);

export default TableColumnsHeader;
