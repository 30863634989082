import React from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';

import { colors, fonts } from '@common/theme';
import { isWeb, toDataTestIdFriendly } from '@common/utils';
import { TouchableWithEvent } from '@common/components';

type TabProps = {
  active?: boolean;
  children: string;
  onPress: () => void;
};

const TabWrap = styled.TouchableOpacity.attrs({ activeOpacity: 0.75 })`
  justify-content: center;
  padding: 10px 26px;
  min-width: 50px;
`;

type TabLabelProps = {
  active: boolean;
};

const TabLabel = styled.Text<TabLabelProps>(
  ({ active }) => css`
    text-align: center;
    font-size: 13px;
    font-family: ${fonts.medium};
    color: ${colors[!active ? 'grey4' : 'primary']};
  `
);

const ActiveMarker = styled.View`
  height: 1px;
  width: 48px;
  background: ${colors.primary};
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-24px);
`;

const Tab = ({ active = false, children, onPress }: TabProps) => {
  // Use onPressOut to avoid conflict with pan responder
  const onPressProps = isWeb ? { onPress } : { onPressOut: onPress };
  const testID = `table-tab-${toDataTestIdFriendly(children)}`;

  return (
    <View>
      <TabWrap as={TouchableWithEvent} trackEventId="chat-searchtab-click" {...onPressProps}>
        <TabLabel active={active} testID={testID}>
          {children}
        </TabLabel>
      </TabWrap>
      {active && <ActiveMarker />}
    </View>
  );
};

export default Tab;
