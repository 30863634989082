import { messageTypes, pathOr } from '@common/utils';
import { State } from '@legacy/ducks';

export const getLatestMessage = (state: State): MessageType => pathOr({} as MessageType, ['messages', 'messages', 0], state);

export const getPreviousToLatestMessage = (state: State): MessageType => pathOr({} as MessageType, ['messages', 'messages', 1], state);

export const getLatestMessageType = (state: State): number => {
  const latestMessage = getLatestMessage(state);

  return pathOr(0, ['type'], latestMessage);
};

export const getLatestMessageTextType = (state: State): string | null => {
  const latestMessage = getLatestMessage(state);

  if (latestMessage.type === messageTypes.QUICK_REPLY) {
    const previousToLatest = getPreviousToLatestMessage(state);
    return pathOr('Text', ['params', 'type'], previousToLatest);
  }

  return latestMessage.type === messageTypes.TEXT ? pathOr('Text', ['params', 'type'], latestMessage) : null;
};

export const getIsSending = (state: State): boolean => pathOr(false, ['messages', 'isSending'], state);

type MessagesType = {
  areMore: boolean;
  isLoading: boolean;
  isSending: boolean;
  messages: Messages;
};

export const getMessages = (state: State): MessagesType => {
  const areMore = pathOr(true, ['messages', 'areMore'], state);
  const isLoading = pathOr(false, ['messages', 'isLoading'], state);
  const isSending = getIsSending(state);
  const messages = pathOr([], ['messages', 'messages'], state);

  return {
    areMore,
    isLoading: isLoading && messages.length === 0,
    isSending,
    messages
  };
};

export const getMessagesList = (state: State): Messages => state?.messages?.messages ?? [];
export const wasRehydrated = (state: State): boolean => pathOr(false, ['messages', 'rehydrated'], state);
export const getConfirmationFields = (state: State): FieldType[] => pathOr([], ['messages', 'confirmationFields'], state);
