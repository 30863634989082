import React, { useMemo } from 'react';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { useKeyboard } from '@react-native-community/hooks';

import { useDispatch, useSelector } from '@common/hooks';
import { scrollChatToBottom, toggleIsTabBarMinimized, getIsTabBarMinimized } from '@common/store/reducers/ui';
import { TabBar } from '@common/components';
import { getCurrentRouteName, isAndroid } from '@common/utils';
import { WALKTHROUGH_SCREEN } from '@common/navigation/routes';
import { CHAT_TAB, CHAT_SCREEN, NOTIFICATIONS_TAB, PROFILE_TAB, EDIT_SCREEN, STORYBOOK_SCREEN } from '@legacy/app/navigation/routes';

const tabIcons = {
  [CHAT_TAB]: 'chat-bubble',
  [NOTIFICATIONS_TAB]: 'notifications',
  [PROFILE_TAB]: 'account-circle'
};

const ConnectedTabBar = (props: BottomTabBarProps) => {
  const { state } = props;

  const dispatch = useDispatch();
  const onHandlePress = () => dispatch(toggleIsTabBarMinimized());

  const { keyboardShown } = useKeyboard();
  const isTabBarMinimized = useSelector(getIsTabBarMinimized);
  const currentRouteName = getCurrentRouteName(state as any);

  const shouldHideTabs = useMemo(
    () => !!currentRouteName && [EDIT_SCREEN, STORYBOOK_SCREEN, WALKTHROUGH_SCREEN].includes(currentRouteName),
    [currentRouteName]
  );

  const onHandleTabPress = (routeName: string) => {
    if (currentRouteName === CHAT_SCREEN && routeName === CHAT_TAB) {
      dispatch(scrollChatToBottom());
    }
  };

  return keyboardShown && isAndroid ? null : (
    <TabBar
      {...props}
      isMinimized={isTabBarMinimized}
      onHandlePress={onHandlePress}
      shouldHideTabs={shouldHideTabs}
      tabIcons={tabIcons}
      onHandleTabPress={onHandleTabPress}
    />
  );
};

export default ConnectedTabBar;
