import { isAndroid, isWeb } from '@common/utils';
import { rgba } from 'polished';
import { colors } from '@common/theme';

export const isDefault: VariantChecker = variant => variant === 'default';
export const isSearch: VariantChecker = variant => variant === 'search';

export const TOOLBAR_MIN_HEIGHT = 49;
export const TOOLBAR_MAX_HEIGHT = 86;
export const FONT_SIZE = !isWeb ? 15 : 16;
export const INPUT_PADDING = !isAndroid ? 10 : 14;
export const INPUT_LINE_HEIGHT = !isWeb ? 18 : 19;
export const MAX_HEIGHT = INPUT_LINE_HEIGHT * 16;
export const MAX_INPUT_HEIGHT = MAX_HEIGHT - 30;
export const MINIMIZED_HEIGHT = INPUT_LINE_HEIGHT + INPUT_PADDING;
export const PLACEHOLDER_COLOR = rgba(colors.grey4, 0.5);
