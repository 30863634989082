import React, { Suspense, useEffect, useState } from 'react';
import { Text } from 'react-native';
import LegacyNavigator from '@legacy/App';
import { isWeb } from '@common/utils';
import { ToastContainer } from 'react-toastify';
import { useNetInfo } from '@react-native-community/netinfo';
import Toast from '@common/utils/toast';

const MainStack = () => {
  const { isConnected, isInternetReachable } = useNetInfo();
  const [shouldShowConnectivityToast, setShouldShowConnectivityToast] = useState(false);

  useEffect(() => {
    setShouldShowConnectivityToast(isConnected === false || isInternetReachable === false);
  }, [isConnected, isInternetReachable]);

  useEffect(() => {
    if (shouldShowConnectivityToast) {
      Toast('No internet connection. Please check your network settings.');
    }
  }, [shouldShowConnectivityToast]);

  return (
    <Suspense fallback={<Text>Loading...</Text>}>
      <LegacyNavigator />
      {isWeb ? <ToastContainer /> : null}
    </Suspense>
  );
};

export default MainStack;
