import React from 'react';
import { View } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledTouchableOpacityProps } from 'styled-components/types';

import { colors } from '@common/theme';
import { isWeb } from '@common/utils';
import { Icon, TouchableWithEvent } from '@common/components';

type FiltersToggleProps = {
  active?: boolean;
  disabled?: boolean;
  onPress: () => void;
};

type ToggleWrapProps = {
  background: string;
};

const ToggleWrap = styled.TouchableOpacity.attrs<StyledTouchableOpacityProps>({
  activeOpacity: 0.75
})<ToggleWrapProps>(
  ({ background, disabled }) => css`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    align-items: center;
    justify-content: center;
    background: ${background};
    opacity: ${!disabled ? 1 : 0.6};
  `
);

const Badge = styled.View`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: ${colors.primary};
  position: absolute;
  right: 1px;
  top: 1px;
`;

const FiltersToggle = ({ active, disabled, onPress }: FiltersToggleProps) => {
  const isActive = active && !disabled;
  const background = isActive ? colors.primary : colors.grey6;
  const iconColor = isActive ? colors.white : colors.grey4;
  const iconStyle = isActive ? {} : { opacity: 0.6 };
  const showBadge = !disabled && !active;
  // Use onPressOut to avoid conflict with pan responder
  const onPressProps = isWeb ? { onPress } : { onPressOut: onPress };

  return (
    <View>
      <ToggleWrap background={background} disabled={disabled} as={TouchableWithEvent} trackEventId="chat-filter-click" {...onPressProps}>
        <Icon name="tune" color={iconColor} size={20} style={iconStyle} />
      </ToggleWrap>
      {showBadge && <Badge />}
    </View>
  );
};

export default FiltersToggle;
