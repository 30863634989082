import commonStore from '@common/store';
import { apiInterceptor } from '@common/store/interceptors';
import { getToken } from '@common/store/reducers/auth';
import { trackEvent } from '@common/utils';

import { Action } from '@legacy/ducks';
import { getDependentPicklistService, updateContext } from '@legacy/webService';

type EditingLookup = {
  type: 'Edition/LOOKUP';
  payload: boolean;
};

type EditingContextObject = {
  type: 'Edition/CONTEXTOBJECT';
  payload: string;
};

type ResetEdition = {
  type: 'Edition/RESET';
};

type SearchEdition = {
  type: 'Edition/SEARCH';
  payload: boolean;
};

type LookupField = {
  type: 'Edition/LOOKUP_FIELD';
  payload: FieldType | undefined;
};

type ContextUpdate = {
  type: 'Edition/UPDATING_CONTEXT';
  payload: boolean;
};

// Actions
export type EditionActions = EditingLookup | ResetEdition | EditingContextObject | SearchEdition | LookupField | ContextUpdate;
// Action Creators
export const editLookup: (payload: boolean) => Action = (editingLookup): EditingLookup => ({
  type: 'Edition/LOOKUP',
  payload: editingLookup
});

export const editContextObject: (payload: string) => Action = (editingContextObject): EditingContextObject => ({
  type: 'Edition/CONTEXTOBJECT',
  payload: editingContextObject
});

export const resetEdition: () => Action = (): ResetEdition => ({
  type: 'Edition/RESET'
});

export const searchEdition: (searching?: boolean) => Action = (searching = true): SearchEdition => ({
  type: 'Edition/SEARCH',
  payload: searching
});

export const setLookupField: (lookup?: FieldType) => Action = (lookup): LookupField => ({
  type: 'Edition/LOOKUP_FIELD',
  payload: lookup
});

export const setContextUpdated: (updating?: boolean) => Action = (updating = false): ContextUpdate => ({
  type: 'Edition/UPDATING_CONTEXT',
  payload: updating
});

export const refreshContext = async (message: Postback) => {
  try {
    const apiRequest = apiInterceptor(updateContext);
    const token = getToken(commonStore.getState());

    return apiRequest(message, token);
  } catch (error) {
    // handled by interceptor
  } finally {
    trackEvent('confirmation_updated_context', message);
  }
};

export const getDependentPicklists = async () => {
  const apiRequest = apiInterceptor(getDependentPicklistService);
  const token = getToken(commonStore.getState());

  return apiRequest(token);
};
