import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import DeepLinking from 'react-native-deep-linking';
import qs from 'query-string';
import { Buffer } from 'buffer';
// Common
import { isChromeExtension, isWeb, pathOr } from '@common/utils';
import { useDispatch as useCommonDispatch, useSelector as useCommonSelector, useStt } from '@common/hooks';
import { getToken, logout } from '@common/store/reducers/auth';
import { reloadNotifications } from '@common/store/reducers/notifications';
import { getUserProfile } from '@common/store/reducers/user';
import { apiInterceptor } from '@common/store/interceptors';
import { getIntegrationMessageActions } from '@common/webService';
// Legacy
import store from '@legacy/store';
import Navigator from '@legacy/app/navigation/Navigator';
import { loadMessages, prependMessages } from '@legacy/ducks/messages/actions';
import { wasRehydrated, getMessagesList } from '@legacy/ducks/messages/selectors';

const Launcher = () => {
  const dispatch = useDispatch();
  const commonDispatch = useCommonDispatch();
  const user = useCommonSelector(getUserProfile);
  const userToken = useCommonSelector(getToken);
  const rehydrated = useSelector(wasRehydrated);
  const messagesFromStore = useSelector(getMessagesList);

  const { stopSttWeb } = useStt();

  const createInfoMessage = (text: string): any[] => [
    {
      id: Date.now() + 2,
      type: 0,
      text,
      isBot: true
    },
    {
      buttons: [
        {
          isCancel: true,
          postback: {
            messageText: 'Cancel',
            reset: true
          },
          text: 'Cancel'
        }
      ],
      options: [],
      fields: [],
      type: 4,
      isBot: true,
      id: Date.now() + 3
    }
  ];

  const sameOrganization = (url1: string, url2?: string) => {
    if (!url1 || !url2) {
      return false;
    }

    const orgId1 = url1.split('.')[0];
    const orgId2 = url2.split('.')[0];
    return orgId1 === orgId2;
  };

  const getMessagesWithActions = async (integrationDetail: any) => {
    // Here check if integrate_sfdc is not activated for the specific deployment [Is checked in backend too]
    if (!user?.sfdc_integration) {
      return undefined;
    }

    const { user_id, intent, instance_url } = integrationDetail;
    // If different users (SF against Rollio) then cut the flow and add the no permission message
    if ((intent !== 'Create' && user_id !== user?.salesforce_id) || !sameOrganization(instance_url, user?.instance_url)) {
      return createInfoMessage('SFDC Integration: you have no permissions to execute this action');
    }
    // Create request
    const apiRequest = apiInterceptor(getIntegrationMessageActions);
    try {
      // Execute get integration messages and actions
      const { data: result } = await apiRequest(userToken, integrationDetail);
      if (result?.messages?.length) {
        return result.messages;
      }
    } catch (e) {
      // Should be handled by interceptor.
      console.log(e);
      return undefined;
    }
  };
  // Chrome ext listener to receive messages
  const handleWebMessageEvent = async ({ data = {} }) => {
    const type = pathOr<string>('', ['type'], data);

    if (type === 'ROLLIO/LOGOUT') {
      commonDispatch(logout());
    }

    if (type === 'ROLLIO/GET_NOTIFICATIONS') {
      commonDispatch(reloadNotifications());
    }

    if (type === 'ROLLIO/ADD_MESSAGES') {
      const messages = pathOr([], ['payload', 'messages'], data);
      const integrationDetail: any = pathOr(undefined, ['payload', 'integration'], data);
      if (messages && messages.length) {
        dispatch(prependMessages(messages, true));
      } else if (integrationDetail) {
        const messagesToAdd: any[] = await getMessagesWithActions(integrationDetail);
        messagesToAdd?.forEach((m: MessageType, index: number) => dispatch(prependMessages([m], index === 0)));
      }
    }
  };
  // Decrypt string
  const decryptString = (encryptedText: string) => (encryptedText ? JSON.parse(Buffer.from(encryptedText, 'base64').toString()) : undefined);
  // Function to add Payload from salesforce as rollio messages to the current conversation
  const addSFPayload = async (payload: string) => {
    // Decripting the information comming from SF
    const integrationDetail = decryptString(payload) ?? {};
    if (integrationDetail) {
      const messagesToAdd = await getMessagesWithActions(integrationDetail);
      if (messagesToAdd) {
        messagesToAdd?.forEach((m: MessageType, index: number) => dispatch(prependMessages([m], index === 0)));
      }
    }
  };
  // Chekcing just in case something went wrong, if 1 message rendered is from other user, then logout.
  const checkRehydration = () => {
    if (rehydrated && messagesFromStore.some(m => m.userId && m.userId !== user.id)) {
      commonDispatch(logout());
    }
  };

  const handleDeepLinking = async () => {
    if (user?.salesforce_id) {
      checkRehydration();
      DeepLinking.removeRoute('/sf-redirect/:token');
      DeepLinking.addRoute('/sf-redirect/:token', ({ token }) => addSFPayload(token));
      // Check init URL
      const initURL = await Linking.getInitialURL();
      if (initURL) {
        // Check if URL is supported
        const supported = await Linking.canOpenURL(initURL);
        if (supported) {
          // Execute function in linked route
          DeepLinking.evaluateUrl(initURL);
        }
      } else if (!rehydrated || (rehydrated && !messagesFromStore.length)) {
        // Just load messages
        dispatch(loadMessages());
      }
    }
  };

  const handleWebRender = async () => {
    checkRehydration();
    if (isChromeExtension) {
      // Add native `message` event listener
      window.addEventListener('message', handleWebMessageEvent, false);
    }
    // Get initial messages from URL parameter
    const parsedUrl = qs.parseUrl(window.location.href);
    const urlData = pathOr('{}', ['query', 'data'], parsedUrl);
    const parsedUrlData = JSON.parse(urlData);
    const integrationDetail = pathOr(undefined, ['integration'], parsedUrlData);
    const postedMessages = pathOr(undefined, ['messages'], parsedUrlData);
    // Get messages from backend if integration present
    const messagesToAdd = integrationDetail ? await getMessagesWithActions(integrationDetail) : undefined;
    const toAdd = messagesToAdd ?? postedMessages;

    if (!rehydrated || (rehydrated && !messagesFromStore.length)) {
      dispatch(loadMessages(toAdd));
    } else {
      toAdd?.forEach((m: MessageType, index: number) => dispatch(prependMessages([m], index === 0)));
    }
  };

  useEffect(() => {
    const renderMessages = async () => {
      if (!isWeb && !isChromeExtension) {
        await handleDeepLinking();
      }

      if (isWeb) {
        await handleWebRender();
      }

      return () => {
        if (isChromeExtension) {
          window.removeEventListener('message', handleWebMessageEvent, false);
        }
      };
    };

    renderMessages();
  }, []);

  // Web stop stt when unfocusing
  useEffect(stopSttWeb, [stopSttWeb]);

  return <Navigator />;
};

const persistor = persistStore(store);
const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Launcher />
    </PersistGate>
  </Provider>
);

export default App;
