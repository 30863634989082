import React, { useCallback, useEffect, useState } from 'react';
import { Animated, BackHandler, Keyboard } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import styled from 'styled-components/native';
import { useSelector, useDispatch } from 'react-redux';
// Common
import { boolToNum } from '@common/utils';
import { ScreenWrap, LoadingOverlay } from '@common/components';
import { useTabBarHeight, useDispatch as useCommonDispatch, useIsFirstRender } from '@common/hooks';
import { stopSttThunk } from '@common/store/reducers/stt';
// Legacy
import { getMessages } from '@legacy/ducks/messages/selectors';
import { ChatStackScreenProps } from '@legacy/app/navigation/types';
import { CHAT_SCREEN } from '@legacy/app/navigation/routes';
import { resetEdition } from '@legacy/ducks/edition/actions';
import { resetSearch } from '@legacy/ducks/search/actions';
import { getIsEdition } from '@legacy/ducks/edition/selectors';
// Components
import MessageList from './MessageList';
import Panels from './Panels';
import Toolbar from './Toolbar';
import { ToolbarProvider } from '@common/components/ChatScreen/ActionToolbar/ToolbarContext';

const InnerWrap = styled.View`
  flex: 1;
  justify-content: flex-end;
`;

type ChatScreenProps = ChatStackScreenProps<typeof CHAT_SCREEN>;

const ChatScreen = (_props: ChatScreenProps) => {
  const { isLoading } = useSelector(getMessages);
  const [innerVisibility] = useState(new Animated.Value(0));
  const [innerOffset] = useState(new Animated.Value(0));
  const [keyboardHeight] = useState(new Animated.Value(0));
  // Dispatch
  const dispatchCommon = useCommonDispatch();
  const dispatch = useDispatch();
  // Hooks
  const firstRender = useIsFirstRender();
  const { animatedTabBarHeight, tabBarHeight } = useTabBarHeight();
  // Selectors
  const isEditing = useSelector(getIsEdition);

  useEffect(() => {
    if (firstRender) {
      dispatch(resetEdition());
      if (isEditing) {
        dispatch(resetSearch());
      }
    }
  }, [firstRender]);

  useFocusEffect(
    useCallback(() => {
      const onBackPress = () => {
        return true;
      };

      const subscription = BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => {
        subscription.remove();
        // Stop Stt when unfocusing
        dispatchCommon(stopSttThunk());
      };
    }, [])
  );

  useEffect(() => {
    const toValue = boolToNum(!isLoading);
    Animated.parallel([
      Animated.timing(innerVisibility, {
        toValue,
        duration: 350,
        useNativeDriver: true
      }),
      Animated.spring(innerOffset, {
        toValue,
        // duration: 700,
        bounciness: 10,
        useNativeDriver: true
      })
    ]).start();
  }, [innerOffset, innerVisibility, isLoading]);

  useEffect(() => {
    const animateKeyboardOffset = (toValue: number, duration: number) => {
      Animated.timing(keyboardHeight, {
        toValue,
        duration,
        useNativeDriver: false
      }).start();
    };

    const kbWillShow = Keyboard.addListener('keyboardWillShow', ({ duration, endCoordinates }) => {
      const totalHeight = endCoordinates.height - tabBarHeight;

      if (totalHeight > 0) {
        animateKeyboardOffset(totalHeight, duration);
      }
    });

    const kbWillHide = Keyboard.addListener('keyboardWillHide', ({ duration }) => animateKeyboardOffset(0, duration));

    return () => {
      kbWillShow.remove();
      kbWillHide.remove();
    };
  }, [keyboardHeight, tabBarHeight]);

  return (
    <>
      <ScreenWrap
        as={Animated.View}
        style={{
          paddingBottom: keyboardHeight,
          marginBottom: animatedTabBarHeight
        }}
      >
        <ScreenWrap>
          <InnerWrap
            as={Animated.View}
            style={{
              opacity: innerVisibility,
              transform: [
                {
                  translateY: innerOffset.interpolate({
                    inputRange: [0, 1],
                    outputRange: [-30, 0]
                  })
                }
              ]
            }}
          >
            <MessageList />
            <Panels />
          </InnerWrap>

          <ToolbarProvider>
            <Toolbar isVisible={!isLoading} />
          </ToolbarProvider>
        </ScreenWrap>
      </ScreenWrap>

      <LoadingOverlay id="chat" visible={isLoading} showBackdrop={false} style={{ paddingBottom: tabBarHeight }} />
    </>
  );
};

export default ChatScreen;
