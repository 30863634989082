import React from 'react';

import PicklistButton from './PicklistButton';

type PicklistButtonsProps = {
  isOptionSelected: (option: Button) => boolean;
  onOptionPress: (option: Button) => void;
  options: Array<Button>;
};

const PicklistButtons = ({ isOptionSelected, onOptionPress, options }: PicklistButtonsProps) => (
  <>
    {options.map(option => {
      const isSelected = isOptionSelected(option);
      const onPress = () => onOptionPress(option);

      return (
        <PicklistButton key={option.text} isSelected={isSelected} onPress={onPress}>
          {option.text}
        </PicklistButton>
      );
    })}
  </>
);

export default PicklistButtons;
