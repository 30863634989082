import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AnyAction, combineReducers, configureStore, AsyncThunkAction } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';

import { appState, auth, firebase, notifications, ui, user, stt } from './reducers';
import {
  focusToolbarInputOnMessageAppend,
  scrollChatToBottomOnMessageAppend,
  getProfileOnTokenUpdate,
  refreshFCMToken,
  onBackgroundNotificationOpened,
  onReceiveNotification
} from './epics';
import { isWeb } from '@common/utils';

const persistConfig = {
  key: 'common',
  version: 1,
  storage: AsyncStorage,
  blacklist: ['auth', 'stt']
};

const authPersistConfig = {
  key: 'auth',
  storage: AsyncStorage,
  blacklist: ['isOAuthLoading']
};

const rootReducer = combineReducers({
  appState,
  auth: persistReducer(authPersistConfig, auth),
  firebase,
  notifications,
  ui,
  user,
  stt
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const epicMiddleware = createEpicMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).prepend(epicMiddleware),
  devTools: true
});

const platformEpics = isWeb ? [focusToolbarInputOnMessageAppend] : [onBackgroundNotificationOpened, refreshFCMToken];

const epics = combineEpics(
  focusToolbarInputOnMessageAppend,
  scrollChatToBottomOnMessageAppend,
  getProfileOnTokenUpdate,
  onReceiveNotification,
  ...platformEpics
);
epicMiddleware.run(epics);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const commonContext = createContext({} as ReactReduxContextValue<any, AnyAction>);

export type ThunkAction = AsyncThunkAction<any, any, { state: RootState }>;

export default store;
