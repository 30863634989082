import React from 'react';
import { TouchableWithoutFeedback, TouchableWithoutFeedbackProps } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '@common/theme';

export type HandleBaseProps = {
  disabled?: boolean;
  onPress?: () => void;
};

type HandleProps = HandleBaseProps & TouchableWithoutFeedbackProps;

const HANDLE_MARGIN = 12;
const HANDLE_HEIGHT = 4;

const HandleWrap = styled.View`
  padding: ${HANDLE_MARGIN / 2}px 0;
`;

const HandleBar = styled.View`
  height: ${HANDLE_HEIGHT}px;
  width: 44px;
  background: ${colors.grey4};
  opacity: 0.35;
  border-radius: 2px;
  margin: 0 auto;
`;

const Handle = ({ disabled, onPress, ...props }: HandleProps) => {
  return (
    <TouchableWithoutFeedback disabled={disabled} onPress={onPress} {...props}>
      <HandleWrap>
        <HandleBar />
      </HandleWrap>
    </TouchableWithoutFeedback>
  );
};

export default Handle;
