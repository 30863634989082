const intervals: any = {};

const start = (name: string, action: () => void, miliseconds: number) => {
  const id = setInterval(() => {
    action();
  }, miliseconds);
  intervals[name] = id;
};

const stop = (name: string) => {
  const interval = intervals[name];
  if (interval) {
    clearInterval(interval.id);
    delete intervals[name];
  }
};

export const Refresh = {
  start,
  stop
};
