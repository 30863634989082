import { Observable } from 'rxjs';
import { Epic } from 'redux-observable';

import { messaging } from '@common/utils/firebase';
import { ThunkAction } from '@common/store';
import { registerFCMToken } from '@common/store/reducers/firebase';
import { displayInAppNotification, notificationRedirect, reloadNotifications } from '@common/store/reducers/notifications';

export const refreshFCMToken: Epic<any, ThunkAction> = () =>
  new Observable(observer =>
    messaging().onTokenRefresh(async (fcmToken: string) => {
      observer.next(registerFCMToken(fcmToken));
    })
  );

export const onReceiveNotification: Epic<any, ThunkAction> = (action$, state$): Observable<ThunkAction> =>
  new Observable(observer =>
    messaging().onMessage(({ data }) => {
      if (data && state$.value.auth.token) {
        const { title, body } = data;
        if (!state$.value.notifications.isLoading) {
          observer.next(reloadNotifications());
        }
        observer.next(displayInAppNotification({ title, body }));
      }
    })
  );

export const onBackgroundNotificationOpened: Epic<any, ThunkAction> = (action$, state$): Observable<ThunkAction> =>
  new Observable(observer =>
    messaging().onNotificationOpenedApp(() => {
      if (state$.value.auth.token) {
        if (!state$.value.notifications.isLoading) {
          observer.next(reloadNotifications());
        }
        observer.next(notificationRedirect());
      }
    })
  );
