import React, { useCallback, useMemo } from 'react';
import { Animated, FlatList as RnFlatList, FlatListProps as RnFlatListProps, RefreshControl } from 'react-native';
import styled from 'styled-components/native';

import { colors } from '@common/theme';
import { useTabBarHeight } from '@common/hooks';

export type FlatListProps<T> = RnFlatListProps<FlatListItem<T>> & {
  areMoreItems?: boolean;
  isAnimated?: boolean;
  isLoading?: boolean;
  onRefresh?: () => void;
  onLoadMore?: () => void;
};

const ListDivider = styled.View`
  background: ${colors.grey};
  height: 1px;
`;

const AnimatedFlatList = Animated.createAnimatedComponent(RnFlatList);

const FlatList = <T extends unknown>({ data, areMoreItems, isAnimated = false, isLoading = false, onLoadMore, onRefresh, ...props }: FlatListProps<T>) => {
  const { tabBarHeight } = useTabBarHeight();

  const defaultProps = useMemo(
    () => ({
      keyExtractor: (item: FlatListItem<T>) => `${item.id}`,
      ItemSeparatorComponent: () => <ListDivider />,
      onEndReachedThreshold: 0.01,
      contentContainerStyle: {
        paddingBottom: tabBarHeight + 10
      },
      ...(isAnimated ? { as: AnimatedFlatList } : {})
    }),
    [isAnimated, tabBarHeight]
  );

  const isRefreshing = useMemo(() => isLoading && !!data && !data.length, [isLoading, data]);

  const refreshControl = useMemo(() => {
    if (onRefresh) {
      return <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} colors={[colors.primary]} tintColor={colors.primary} />;
    }
  }, [isRefreshing, onRefresh]);

  const onEndReached = useCallback(() => {
    if (!isLoading && areMoreItems && onLoadMore) {
      onLoadMore();
    }
  }, [areMoreItems, isLoading, onLoadMore]);

  return <RnFlatList {...defaultProps} data={data} onEndReached={onEndReached} refreshControl={refreshControl} {...props} />;
};

export default FlatList;
