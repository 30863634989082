import React, { useCallback, useMemo } from 'react';
import { Alert } from 'react-native';
import styled from 'styled-components/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useDispatch } from '@common/hooks';
import { colors } from '@common/theme';
import { isWeb } from '@common/utils';
import walkthroughStep1 from '@common/images/WalkthroughScreen/walkthroughStep1.png';
import walkthroughStep2 from '@common/images/WalkthroughScreen/walkthroughStep2.png';
import walkthroughStep3 from '@common/images/WalkthroughScreen/walkthroughStep3.png';
import walkthroughStep4 from '@common/images/WalkthroughScreen/walkthroughStep4.png';
import walkthroughStep5 from '@common/images/WalkthroughScreen/walkthroughStep5.png';
import { recoverSession } from '@common/store/reducers/auth';
import { RootStackScreenProps } from '@common/navigation';
import { WALKTHROUGH_SCREEN } from '@common/navigation/routes';
import { ScreenWrap, WalkthroughSlider } from '@common/components';

export const walkthroughStorageKey = 'HAS_VIEWED_WALKTHROUGH_V4.0.0';

const OuterWrap = styled(ScreenWrap)`
  background: ${colors.grey6};
`;

const SalesforceBlue = styled.Text`
  color: #00a1e0;
`;

const steps = [
  {
    title: 'Welcome to a new Rollio',
    image: walkthroughStep1,
    description: [
      'Say hello to your Rollio Dashboard.',
      <>
        {'Putting the latest '}
        <SalesforceBlue>Salesforce</SalesforceBlue>™ data at your fingertips.
      </>
    ]
  },
  {
    title: 'Enhanced Chat',
    image: walkthroughStep2,
    description: ['Immerse yourself in the conversation with Rollio via the Chat tab and the revised toolbar.']
  },
  {
    title: 'Talk to Rollio',
    image: walkthroughStep3,
    description: ["Just tap the toolbar's microphone button to begin speaking to Rollio."]
  },
  {
    title: 'Streamline Searching',
    image: walkthroughStep4,
    description: ['Finding your clients and products has never been easier. The search panel appears when you need it most.']
  },
  {
    title: "You're all set",
    image: walkthroughStep5,
    description: ["If you need to see this walkthrough again you'll find it on the Profile tab."],
    showContinueButton: true
  }
];

const alertMessage = 'If you need to view the walkthrough again you can access it via the Profile tab';

type WalkthroughScreenProps = RootStackScreenProps<typeof WALKTHROUGH_SCREEN>;

const WalkthroughScreen = ({ navigation, route }: WalkthroughScreenProps) => {
  const showSkipButton = route.params?.showSkipButton ?? true;
  const dispatch = useDispatch();

  const completeWalkthrough = async () => {
    await AsyncStorage.setItem(walkthroughStorageKey, 'true');

    dispatch(recoverSession());
  };

  const onContinuePress = useCallback(async () => {
    if (showSkipButton) {
      await completeWalkthrough();
    } else {
      navigation.goBack();
    }
  }, [showSkipButton, navigation]);

  const onSkipPress = useCallback(async () => {
    if (showSkipButton) {
      if (!isWeb) {
        Alert.alert(
          'Skip',
          alertMessage,
          [
            {
              text: 'Cancel',
              style: 'cancel'
            },
            {
              text: 'OK',
              onPress: () => completeWalkthrough()
            }
          ],
          { cancelable: false }
        );
      } else {
        const webAlert = confirm(alertMessage);

        if (webAlert) {
          await completeWalkthrough();
        }
      }
    } else {
      navigation.goBack();
    }
  }, [dispatch, showSkipButton, navigation]);

  const skipLabel = useMemo(() => (!showSkipButton ? 'Close' : undefined), [showSkipButton]);

  return (
    <OuterWrap>
      <WalkthroughSlider onContinuePress={onContinuePress} onSkipPress={onSkipPress} skipButtonLabel={skipLabel} steps={steps} />
    </OuterWrap>
  );
};

export default WalkthroughScreen;
