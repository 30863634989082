import React, { useCallback, useMemo } from 'react';

import { cancelConfirmationAlert } from '@common/utils';
import { TableTypeB } from '@common/components';

import store from '@legacy/store';
import { setConfirmationFields } from '@legacy/ducks/messages/actions';

type TableBMessageProps = {
  message: ConfirmationTableMessage;
  navigateToEditScreen: (tableData: any, dependantAllowedValues: DependantAllowedValues) => void;
  sendMessage: (
    postback: Postback & {
      edit?: boolean;
      fields?: any;
    }
  ) => void;
  showAvatar?: boolean;
  showFooterButtons?: boolean;
};

const TableBMessage = ({ message, navigateToEditScreen, sendMessage, showAvatar, showFooterButtons }: TableBMessageProps) => {
  const onFooterButtonPress = useCallback(
    (button: Button, tableData: any) => {
      if (button.openEditScreen) {
        navigateToEditScreen(tableData, message.dependantAllowedValues);
      } else if (button.isCancel) {
        cancelConfirmationAlert(() => {
          sendMessage(button.postback);
          store.dispatch(setConfirmationFields());
        });
      } else {
        sendMessage(button.postback);
        store.dispatch(setConfirmationFields());
      }
    },
    [navigateToEditScreen, sendMessage]
  );

  const footerButtons = useMemo(() => {
    const { buttons = [] } = message;
    if (buttons && buttons.length && showFooterButtons) {
      return buttons;
    }

    return [];
  }, [message, showFooterButtons]);

  return (
    <TableTypeB
      tableData={message.fields}
      tableTitle={message.title}
      tableCreationTime={message.createdAt}
      hideTime={message.hideTime}
      buttonsArray={footerButtons}
      buttonPress={onFooterButtonPress}
      showAvatar={showAvatar}
    />
  );
};

export default TableBMessage;
