import qs from 'query-string';

import { getTimezone, coreUrl } from '@common/utils';
import { authorizedApiRequest, type ApiResponse } from '@common/webService/requests';

type PaginationOptions = {
  count: number;
  page: number;
};

export const sendMessage = (message: string | Object, token: string, isPostback: boolean): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/message`, token, {
    method: 'POST',
    headers: {
      'rollio-timezone': getTimezone()
    },
    body: JSON.stringify(isPostback ? { payload: message } : { message })
  });

export const edition = (fields: FieldType[], token: string): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/edition`, token, {
    method: 'POST',
    headers: {
      'rollio-timezone': getTimezone()
    },
    body: JSON.stringify({ fields })
  });

export const lookup = (field: FieldType, token: string): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/lookup`, token, {
    method: 'POST',
    headers: {
      'rollio-timezone': getTimezone()
    },
    body: JSON.stringify({ field })
  });

export const getMessageHistory = (token: string, offset: number, perPage: number): ApiResponse =>
  authorizedApiRequest(
    `${coreUrl}/message?${qs.stringify({
      offset,
      per_page: perPage
    })}`,
    token
  );

export const updateContext = (message: string | Object, token: string): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/update-context`, token, {
    method: 'POST',
    headers: {
      'rollio-timezone': getTimezone()
    },
    body: JSON.stringify({ payload: message })
  });

export const getDataSearchPagination = (token: string, options: { object: string; name?: string } & PaginationOptions): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/search?${qs.stringify(options)}`, token);

export const getDependentPicklistService = (token: string): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/dependent-picklists`, token, {
    method: 'GET',
    headers: {
      'rollio-timezone': getTimezone()
    }
  });
