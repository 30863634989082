import React, { useMemo } from 'react';

import { BotBubble, UserBubble } from './Bubble';

type ChatBubbleProps = {
  text: string;
  createdAt: Date;
  isBot?: boolean;
  read?: boolean;
  showAvatar?: boolean;
  hideTime?: boolean;
};

const ChatBubble = ({ isBot, text, createdAt, read, showAvatar, hideTime }: ChatBubbleProps) => {
  const BubbleComponent = useMemo(() => (isBot ? BotBubble : UserBubble), [isBot]);
  const time = hideTime ? undefined : createdAt;
  return <BubbleComponent message={text} time={time} read={read} showAvatar={showAvatar} />;
};

export default ChatBubble;
