import styled from 'styled-components/native';

import { colors } from '@common/theme';

const ScreenWrap = styled.View`
  flex: 1;
  background: ${colors.white};
`;

export default ScreenWrap;
