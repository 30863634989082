import React from 'react';
import { GestureResponderEvent, TouchableOpacity, TouchableOpacityProps } from 'react-native';

import { trackEvent } from '@common/utils';

export type TouchableWithEventProps = TouchableOpacityProps & {
  trackEventId: string;
};

const TouchableWithEvent = ({ children, onPress, onPressOut, trackEventId, ...props }: TouchableWithEventProps) => {
  const onButtonPress = (e: GestureResponderEvent) => {
    if (onPress) {
      if (trackEventId) {
        trackEvent(trackEventId);
      }
      onPress(e);
    }
  };

  const onButtonPressOut = (e: GestureResponderEvent) => {
    if (onPressOut) {
      if (trackEventId) {
        trackEvent(trackEventId);
      }
      onPressOut(e);
    }
  };

  return (
    <TouchableOpacity {...props} onPress={onButtonPress} onPressOut={onButtonPressOut}>
      {children}
    </TouchableOpacity>
  );
};

export default TouchableWithEvent;
