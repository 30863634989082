import { Platform } from 'react-native';
import qs from 'query-string';

import { authUrl, notificationUrl, envPlatform, isChromeExtension, isWeb, sttUrl, coreUrl } from '@common/utils';

import { apiRequest, authorizedApiRequest, authorizedFileRequest, type ApiResponse } from './requests';

const getPlatform = () => {
  if (envPlatform) {
    return envPlatform;
  }

  return !isChromeExtension ? Platform.OS : 'chrome';
};

export const getRedirectUrl = (username: string, firebaseIdToken?: string): ApiResponse =>
  apiRequest(
    `${authUrl}/redirect-url?${qs.stringify({
      username,
      platform: getPlatform(),
      ...(isWeb && !isChromeExtension ? { callbackUrl: window.location.href } : {}),
      ...(isChromeExtension ? { chromeReferrer: document.referrer || '*' } : {}),
      fb: firebaseIdToken
    })}`
  );

export const logoutWith = (token: string): ApiResponse => authorizedApiRequest(`${authUrl}/logout`, token, { method: 'POST' });

export const getProfileImage = (token: string): ApiResponse => authorizedFileRequest(`${authUrl}/profile/image`, token);

export const registerDevice = (token: string, userId: number, fcmToken: string): ApiResponse =>
  authorizedApiRequest(`${notificationUrl}/register-device`, token, {
    method: 'POST',
    body: JSON.stringify({
      userId,
      token: fcmToken,
      platform: getPlatform()
    })
  });

export const deregisterDevice = (token: string, fcmToken: string): ApiResponse =>
  authorizedApiRequest(`${notificationUrl}/register-device`, token, {
    method: 'DELETE',
    body: JSON.stringify({
      token: fcmToken,
      platform: getPlatform()
    })
  });

export const getNotifications = (token: string, page: number, perPage: number): ApiResponse =>
  authorizedApiRequest(
    `${notificationUrl}/notifications?${qs.stringify({
      page,
      per_page: perPage
    })}`,
    token
  );

export const triggerNotification = (token: string, id: number): ApiResponse =>
  authorizedApiRequest(`${notificationUrl}/notifications/${id}/trigger`, token, {
    method: 'POST'
  });

export const sendSttReport = (message: string, token: string): ApiResponse =>
  authorizedApiRequest(`${sttUrl}/report/message`, token, {
    method: 'POST',
    body: JSON.stringify({ message })
  });

export const getIntegrationMessageActions = (token: string, integrationDetail: any): ApiResponse =>
  authorizedApiRequest(`${coreUrl}/message/integration?${qs.stringify(integrationDetail)}`, token);
