import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { ContentStyle, FlashList } from '@shopify/flash-list';
// Common
import { isWeb, lensPath, pathOr, prettyDate, view } from '@common/utils';
import { CHAT_HEADER_HEIGHT, Message, ChatHeader as Header } from '@common/components';
import { useSelector as useCommonSelector } from '@common/hooks';
import { getScrollChat } from '@common/store/reducers/ui';
import { getTimeFormat } from '@common/store/reducers/user';
import { colors } from '@common/theme';
// Legacy
import { loadMessages, postMessage, setConfirmationFields } from '@legacy/ducks/messages/actions';
import { getMessages } from '@legacy/ducks/messages/selectors';
import { EDIT_SCREEN } from '@legacy/app/navigation/routes';
import { ChatNavigationProp } from '@legacy/app/navigation/types';
import { getContextUpdated } from '@legacy/ducks/edition/selectors';
import { refreshContext, setContextUpdated } from '@legacy/ducks/edition/actions';
// Legacy Chat Utils
import { createLookupsFromParents } from '../utils';

// Vertical paddings are flipped due to `inverted` prop
const listContainerStyles: ContentStyle = {
  paddingBottom: CHAT_HEADER_HEIGHT,
  paddingTop: 20
};

const Container = styled.View`
  flex: 1;
`;

const MessageContainer = styled.View`
  padding-right: ${isWeb ? '15px' : '10px'};
  padding-left: ${isWeb ? '15px' : '10px'};
`;

const viewabilityConfig = {
  itemVisiblePercentThreshold: 5,
  minimumViewTime: 1
};

const botLoadingMessage = (): MessageType => ({
  id: 'loading...',
  isBot: true,
  type: 0,
  text: '',
  createdAt: new Date()
});

const MessageList = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<ChatNavigationProp>();
  const listRef = useRef<FlashList<MessageType>>(null);

  const { areMore, messages, isSending } = useSelector(getMessages);
  const contextUpdated = useSelector(getContextUpdated);

  const [isFetching, setFetching] = useState(false);
  const [focus, setFocus] = useState(true);
  const [headerDate, setHeaderDate] = useState('');
  // Common
  const timeFormat = useCommonSelector(getTimeFormat);
  const scrollChat = useCommonSelector(getScrollChat);

  const onViewableItemsChanged = useRef<any>(({ viewableItems = [] }: { viewableItems: Messages }) => {
    if (!viewableItems.length || isWeb) {
      return;
    }

    const visibleDatesAreEqual = viewableItems
      .map(item => prettyDate(item.createdAt, timeFormat))
      .every((messageDate, index, messageDates) => messageDate === messageDates[0]);

    if (visibleDatesAreEqual) {
      const firstMessageDate = view(lensPath([0, 'item', 'createdAt']), viewableItems);
      if (firstMessageDate) {
        const a = prettyDate(firstMessageDate, timeFormat);
        setHeaderDate(a);
        return;
      }
    }

    setHeaderDate('');
  });

  useFocusEffect(
    useCallback(() => {
      setFocus(true);

      return () => {
        setFocus(false);
      };
    }, [])
  );

  const getNewMessages = useCallback(() => dispatch(loadMessages()), [dispatch]);
  const sendMessage = useCallback(message => dispatch(postMessage(message)), [dispatch]);
  const navigateToEditScreen = useCallback(
    (initialValues: Array<FieldType>, dependantAllowedValues: DependantAllowedValues) => {
      navigation.navigate(EDIT_SCREEN, {
        fields: initialValues,
        dependantAllowedValues,
        callback: fields => {
          // Need to Change
          sendMessage({
            fields,
            messageText: 'Edited',
            edit: true
          });
        }
      });
    },
    [navigation, sendMessage]
  );

  const chatMessages = useMemo(() => {
    if (!isSending) {
      return messages;
    }

    return [botLoadingMessage(), ...messages];
  }, [messages, isSending]);

  const latestBotMessageId = useMemo(() => {
    setFetching(false);
    const lastMessage = messages.filter(({ isBot, type }) => isBot && [0, 2].includes(type))[0];
    return lastMessage?.id;
  }, [messages, isSending]);

  useEffect(() => {
    const lastMessage = messages.filter(({ isBot, type }) => isBot && [0, 2].includes(type))[0];
    // Confirmation message
    if (lastMessage?.type === 2 && latestBotMessageId && !isSending) {
      const fields = createLookupsFromParents(lastMessage.fields ?? []);
      dispatch(setConfirmationFields(fields));

      if (contextUpdated) {
        refreshContext({
          fields: fields,
          messageText: 'Edited',
          edit: true,
          back: true
        });

        dispatch(setContextUpdated(false));
      }
    }
  }, [latestBotMessageId, isSending]);

  const onEndReached = useCallback(async () => {
    if (areMore && focus) {
      setFetching(true);
      await getNewMessages();
    }

    setFetching(false);
  }, [areMore, getNewMessages, focus]);

  useEffect(() => {
    if (scrollChat && listRef && listRef.current) {
      listRef.current.scrollToOffset({
        offset: 0,
        animated: true
      });
    }
  }, [scrollChat]);

  useEffect(() => {
    if (!isWeb) {
      return;
    }

    const scrollNode: any = listRef.current?.getScrollableNode();
    if (!scrollNode) {
      return;
    }

    const listener = scrollNode.addEventListener('wheel', (e: any) => {
      scrollNode.scrollTop -= e.deltaY;
      e.preventDefault();
    });

    return () => scrollNode.removeEventListener('wheel', listener);
  }, [isWeb, focus]);

  const renderItem = useCallback(
    ({ item, index }: any) => {
      const isLatestMessage = index === 0;
      const isLatestBotMessage = latestBotMessageId === item.id || item.id === 'loading...';
      const nextMessage = pathOr(undefined, [index + 1], chatMessages);
      const MemoMessage: any = memo(Message);

      return (
        <MessageContainer>
          <MemoMessage
            areMoreMessages={areMore}
            message={item}
            nextMessage={nextMessage}
            isLatestMessage={isLatestMessage}
            sendMessage={sendMessage}
            isLatestBotMessage={isLatestBotMessage}
            navigateToEditScreen={navigateToEditScreen}
          />
        </MessageContainer>
      );
    },
    [chatMessages, areMore, latestBotMessageId, sendMessage, navigateToEditScreen]
  );

  const extractor = (_message: MessageType, index: number) => `${index}`;
  const getItemType = (item: MessageType) => item.type;
  const activityIndicator = useCallback(
    () =>
      isFetching ? (
        <View style={{ alignItems: 'center' }}>
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      ) : null,
    [isFetching]
  );

  return (
    <Container>
      {!isFetching && !isWeb && <Header dateLabel={headerDate} />}
      {/* handling focused event since flashlist rendering calculation is expensive in comparative with the rerender, and this is animated */}
      {/* In the future we can save the offset and scroll to there if needed using focused/no-focused state and scroll ui reducer */}
      {focus && (
        <FlashList
          inverted
          estimatedItemSize={60}
          contentContainerStyle={listContainerStyles}
          viewabilityConfig={viewabilityConfig}
          onViewableItemsChanged={onViewableItemsChanged.current}
          onEndReached={onEndReached}
          onEndReachedThreshold={0.5}
          ref={listRef}
          data={chatMessages}
          extraData={latestBotMessageId}
          scrollEventThrottle={1}
          keyExtractor={extractor}
          renderItem={renderItem}
          getItemType={getItemType}
          ListFooterComponent={activityIndicator}
        />
      )}
    </Container>
  );
};

export default MessageList;
