import React, { ReactNode, useMemo } from 'react';
import { Animated } from 'react-native';
import styled, { css } from 'styled-components/native';
import { StyledViewProps } from 'styled-components/types';

import { colors } from '@common/theme';
import { isAndroid } from '@common/utils';

import { isDefault, isSearch, TOOLBAR_MIN_HEIGHT } from './shared';

export type WrapProps = {
  animatedHeight: Animated.AnimatedValue;
  children: ReactNode;
  variant: ToolbarVariants;
} & StyledViewProps;

const ToolbarWrap = styled.View`
  width: 100%;
`;

const ToolbarInner = styled.View`
  flex-direction: row;
  align-items: flex-end;
  border-radius: ${TOOLBAR_MIN_HEIGHT / 2}px;
  flex: 1;
  min-height: ${TOOLBAR_MIN_HEIGHT}px;
  background: ${colors.white};
  box-shadow: 0 2px 6px rgba(110, 110, 110, 0.2);
  ${isAndroid &&
  css`
    elevation: 5;
    shadow-color: ${colors.grey2};
  `}
`;

const Wrap = ({ animatedHeight, children, variant }: WrapProps) => {
  const props = useMemo(() => {
    if (isDefault(variant) || isSearch(variant)) {
      return {
        as: Animated.View,
        style: { height: animatedHeight }
      };
    }

    return {};
  }, [animatedHeight, variant]);

  return (
    <ToolbarWrap {...props}>
      <ToolbarInner>{children}</ToolbarInner>
    </ToolbarWrap>
  );
};

export default Wrap;
