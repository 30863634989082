import { applyMiddleware, createStore, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';

import epics from '@legacy/ducks/epics';

import reducer from './reducer';

const epicMiddleware = createEpicMiddleware();

const middlewares = [thunkMiddleware, epicMiddleware];

const composer = __DEV__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || ((f: any) => f) : compose;

const configureStore = (initialState = {}) => {
  const persistConfig = {
    key: 'legacy',
    version: 1,
    storage: AsyncStorage,
    blacklist: []
  };
  const enhancer = composer(applyMiddleware(...middlewares));
  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = createStore(persistedReducer, initialState, enhancer);

  if (module.hot) {
    // Enable hot module replacement for reducers
    module.hot.accept(() => {
      store.replaceReducer(require('./reducer').default);
    });
  }

  return store;
};

const store = configureStore();
epicMiddleware.run(epics);

export default store;
