import { Action } from '@reduxjs/toolkit';
import { type Observable, of } from 'rxjs';
import { delay, filter, mergeMap } from 'rxjs/operators';
import { Epic } from 'redux-observable';

import { scrollChatToBottom, focusToolbarInput } from '@common/store/reducers/ui';

export const scrollChatToBottomOnMessageAppend: Epic = action$ =>
  action$.pipe(
    filter(action => action.type === 'Messages/PREPEND'),
    delay(25),
    mergeMap(() => of(scrollChatToBottom()))
  );

export const focusToolbarInputOnMessageAppend: Epic = (action$: Observable<Action>) =>
  action$.pipe(
    filter(action => action.type === 'Messages/PREPEND'),
    mergeMap(() => of(focusToolbarInput()))
  );
